/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react';
import { FormHelperText, makeStyles, Avatar } from '@material-ui/core';
import { AttachFile as AttachmentIcon } from '@material-ui/icons';
import { useField, useFormikContext } from 'formik';
import { DropzoneArea } from 'material-ui-dropzone';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  dropzone: {
    minHeight: 0,
  },
  dropzoneBody: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'min-content max-content',
    gridTemplateAreas: `
      "icon label"
    `,
    justifyItems: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dropzoneBodyWithFile: {
    margin: theme.spacing(0, 0, 0, 2),
  },
  dropzoneParragraph: {
    fontSize: theme.typography.caption.fontSize,
    gridArea: 'label',
  },
  dropzoneIcon: {
    height: 24,
    width: 24,
    margin: theme.spacing(0, 1, 0, 0),
    gridArea: 'icon',
  },
  dropzonePreview: {
    margin: theme.spacing(1),
  },
  dropzoneImage: {
    margin: theme.spacing(1),
  },
  avatar: {
    position: 'absolute',
    top: '50%',
    left: theme.spacing(1),
    transform: 'translateY(-50%)',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  dropzoneItemPreviewContainer: {
    position: 'static',
    '& > button': {
      top: '50%',
      left: theme.spacing(1),
      transform: 'translateY(-50%)',
    },
  },
}));

const FormDropzoneField = ({ label, name, showAlerts = false, className, ...props }) => {
  const classes = useStyles();
  const { submitCount } = useFormikContext();
  const [inputProps, meta, helpers] = useField(name);

  useEffect(() => {
    if (submitCount > 0 && !meta.touched) helpers.setTouched(true);
  }, [submitCount, meta, helpers]);

  return (
    <div className={classes.root}>
      <span onBlur={() => !meta.touched && helpers.setTouched(true)}>
        <DropzoneArea
          onChange={(files) => helpers.setValue(files[0])}
          showAlerts={showAlerts}
          dropzoneText={label}
          getPreviewIcon={({ file, data }) => {
            if (file.type.startsWith('image/'))
              return <Avatar className={classes.avatar} src={data} />;
            return (
              <Avatar className={classes.avatar}>
                <AttachmentIcon />
              </Avatar>
            );
          }}
          previewGridClasses={{
            item: classes.dropzoneItemPreviewContainer,
          }}
          classes={{
            root: classes.dropzone,
            textContainer: clsx(
              classes.dropzoneBody,
              inputProps.value && classes.dropzoneBodyWithFile
            ),
            text: classes.dropzoneParragraph,
            icon: classes.dropzoneIcon,
          }}
          {...props}
        />
      </span>
      {meta.touched && <FormHelperText error>{meta.error}</FormHelperText>}
    </div>
  );
};

export default FormDropzoneField;
