import React, { useState } from 'react';
import {
  Typography,
  makeStyles,
  Box,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Divider,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import { useCan } from '../../utils/can';
import { rules } from '../../utils/rbac-rules';
import { offeringStatus, organStatus } from '../../constants/status';
import MedicalCenterName from './medical-center-name';

const useStyles = makeStyles((theme) => ({
  summary: { display: 'flex', width: '100%', alignItems: 'center' },
  details: { display: 'flex', flexDirection: 'column', flex: 1 },
  title: { marginRight: theme.spacing(2), flex: 1 },
  offering: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  offeringContent: {
    padding: theme.spacing(2, 1),
    // color: theme.palette.text.secondary,
  },
  organStatus: {
    flex: 4,
    fontWeight: theme.typography.h6.fontWeight,
    color: ({ status }) => {
      switch (status) {
        case organStatus.assigned:
          return theme.palette.success.main;
        case organStatus.beingAllocated:
          return theme.palette.warning.main;
        default:
          return theme.palette.text.secondary;
      }
    },
  },
  offeringStatus: {
    fontWeight: theme.typography.h6.fontWeight,
    color: ({ status }) => {
      switch (status) {
        case offeringStatus.accepted:
          return theme.palette.success.main;
        case offeringStatus.pending:
          return theme.palette.warning.main;
        case offeringStatus.dismissed:
          return theme.palette.error.main;
        case offeringStatus.expired:
          return theme.palette.text.hint;
        default:
          return theme.palette.text.secondary;
      }
    },
  },
  createAllocationButton: {},
  columnroot: { 
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'right',
  },
  disabled: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.text.hint
  }
}));

const OfferingStatus = ({ status }) => {
  const classes = useStyles({ status });

  return (
    <Typography variant="caption" className={classes.offeringStatus}>
      {status}
    </Typography>
  );
};

const OrganStatus = ({ status }) => {
  const classes = useStyles({ status });

  return (
    <Typography variant="caption" color="textSecondary" className={classes.organStatus}>
      {status}
    </Typography>
  );
};

const InProgressOrganItem = ({ organ }) => {
  const classes = useStyles();
  const canAllocate = useCan({ perform: rules.organs.allocate });
  const [expanded, setExpanded] = useState(false);

  return (
    <ExpansionPanel elevation={3} expanded={expanded}>
      <ExpansionPanelSummary
        onClick={() => canAllocate && setExpanded(!expanded)}
        expandIcon={
          canAllocate ? (
            <ExpandMoreIcon
              label="Ver detalles órgano"
              data-testid="details-organ-button"
            />
          ) : null
        }
      >
        <Box className={classes.summary}>
          <Typography variant="h6" className={classes.title}>
            {organ.organType.name}
          </Typography>
          <OrganStatus status={organ.status} />
        </Box>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Box className={classes.details}>
          <Divider />
          {canAllocate &&
            organ.offerings.map(({ id, priority, status, medicalCenterId, recieverDocumentId, createdAt }) => (
              <React.Fragment key={id} className={status === "Expirada" ? classes.disabled : null}>
                <Box className={status === "Expirada" ? classes.disabled : classes.offering}>
                  <Box className={classes.offeringContent}>
                    <Typography>Para: {recieverDocumentId}</Typography>
                    <MedicalCenterName id={medicalCenterId} />
                    <OfferingStatus status={status} />
                  </Box>
                  <Box className={classes.columnroot}>
                    <Typography variant="caption">{priority}° prioridad</Typography>
                    <Typography variant="caption">{new Date(createdAt).toLocaleString()}</Typography>
                  </Box>
                </Box>
                <Divider />
              </React.Fragment>
            ))}
        </Box>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default InProgressOrganItem;
