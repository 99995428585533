import React from 'react';
import { makeStyles } from '@material-ui/core';
import UnauthenticatedAppRoutes from './unauthenticated-routes';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 0,
    padding: theme.spacing(1),
  },
  page: {
    background: theme.palette.primary.main,
    height: '100vh',
    color: 'white',
  },
}));

const UnauthenticatedApp = () => {
  const classes = useStyles();
  return (
    <div className={classes.page}>
      <main className={classes.content}>
        <UnauthenticatedAppRoutes />
      </main>
    </div>
  );
};

UnauthenticatedApp.propTypes = {};

export default UnauthenticatedApp;
