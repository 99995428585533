import React, { useMemo } from 'react';
import {
  Paper,
  Grid,
  Typography,
  Box,
  Divider,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import { Person, Notifications } from '@material-ui/icons';
import ModifyUserButton from './modify-user-button';
import { useMyTeams } from '../teams/use-my-teams';
import ActivateNotifications from './activate-notifications-switch';
import FullPageSpinner from '../../components/ui/full-page-spinner';

// const gridSpacing = 4;
const useStyles = makeStyles((theme) => ({
  root: { flexwrap: 'nowrap' },
  title: { padding: theme.spacing(1) },
  rowroot: {
    padding: theme.spacing(1),
    display: 'flex',
    flexwrap: 'nowrap',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  columnroot: { padding: theme.spacing(1), display: 'flex', flexDirection: 'column' },
  subtitle: { color: theme.palette.text.hint },
  date: { color: theme.palette.text.hint },
  dismissed: { color: theme.palette.error.main },
  accepted: { color: theme.palette.success.main },
  userinfo1: { padding: theme.spacing(2), backgroundColor: '#ECF0F1' },
  userinfo2: { padding: theme.spacing(2), backgroundColor: 'lightgrey' },
  switch: { color: theme.palette.primary },
  paper: {
    // top and bottom 24px padding + toolbar height + grid spacing + tabHeight
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    margin: '0 5%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}));

const UserProfile = ({ currentUser }) => {
  const data = useMemo(() => currentUser, [currentUser]);
  const { userGroups, loading: loadingUserGroups } = useMyTeams();
  const classes = useStyles();

  if (loadingUserGroups) {
    return <FullPageSpinner />;
  }

  return (
    <Grid className={classes.rowroot} spacing={2}>
      <Grid xs={12} sm={6} item>
        <Paper className={classes.paper} elevation={3}>
          <Box display="flex" alignItems="center" className={classes.title}>
            <Person />
            <Typography variant="h6">Perfil de usuario</Typography>
            <ModifyUserButton userInfo={data} />
          </Box>
          <Divider />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={classes.userinfo1}
          >
            <Typography variant="subtitle2"> Nombre:</Typography>
            <Typography variant="caption"> {data.firstName}</Typography>
          </Box>
          <Divider />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={classes.userinfo1}
          >
            <Typography variant="subtitle2"> Apellido:</Typography>
            <Typography variant="caption"> {data.lastName}</Typography>
          </Box>
          <Divider />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={classes.userinfo1}
          >
            <Typography variant="subtitle2"> Correo:</Typography>
            <Typography variant="caption"> {data.email}</Typography>
          </Box>
          <Divider />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={classes.userinfo1}
          >
            <Typography variant="subtitle2"> Rol/Cargo:</Typography>
            <Typography variant="caption"> {data.role}</Typography>
          </Box>
          <Divider />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={classes.userinfo1}
          >
            <Typography variant="subtitle2"> Contraseña:</Typography>
            <Typography variant="caption"> *********</Typography>
          </Box>
        </Paper>
      </Grid>
      {userGroups.length > 0 &&
        <Paper className={classes.paper} elevation={3}>
          <Box display="flex" alignItems="center" className={classes.title}>
            <Notifications />
            <Typography variant="h6">Notificaciones</Typography>
          </Box>
          <Divider />
          {userGroups.map((group) => (
            <Tooltip title="Desactivar todas las notificaciones de la aplicación">
              <ActivateNotifications user={currentUser} group={group} />
            </Tooltip>
          ))}
        </Paper>
      }
    </Grid>
  );
};

export default UserProfile;
