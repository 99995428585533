import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

export const GET_MEDICAL_CENTER = gql`
  query getMedicalCenter($medicalCenterId: ID!) {
    getMedicalCenter(id: $medicalCenterId) {
      id
      name
    }
  }
`;

export const useMedicalCenter = (medicalCenterId) => {
  const { data, loading, error } = useQuery(GET_MEDICAL_CENTER, {
    variables: { medicalCenterId },
  });

  return { medicalCenter: data?.getMedicalCenter, loading, error };
};
