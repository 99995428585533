import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

export const CURRENT_USER_QUERY = gql`
  query GetAuthenticatedUser {
    getAuthenticatedUser {
      id
      firstName
      lastName
      email
      role
      medicalCenters {
        id
        name
      }
    }
  }
`;

export function useCurrentUser() {
  const { data, loading, error, refetch } = useQuery(CURRENT_USER_QUERY);

  const user = data?.getAuthenticatedUser;

  return { user, loading, error, refetch };
}
