import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

export const CONFIRMED_SUSPICIONS_QUERY = gql`
  query ConfirmedSuspicions {
    getConfirmedSuspicions {
      id
      rut
      status
      updatedAt
      medicalCenter {
        id
        name
      }
      surgery {
        id
      }
      organs {
        id
        organType {
          id
          name
          description
          ischemiaTime
        }
        crossClamp
        ischemia
        status
        originMedicalCenter {
          id
          name
        }
        offerings {
          id
          status
          priority
          medicalCenterId
          createdAt
          recieverDocumentId
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export function useConfirmedSuspicions() {
  const { data, loading, error } = useQuery(CONFIRMED_SUSPICIONS_QUERY);

  return { suspicions: data?.getConfirmedSuspicions, loading, error };
}
