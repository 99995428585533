import { useMutation } from '@apollo/react-hooks';
import {
  Tooltip,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import DeleteIcon from '@material-ui/icons/Delete';
import { gql } from 'apollo-boost';
import { useSnackbar } from 'notistack';
import React from 'react';
import FullPageSpinner from '../../components/ui/full-page-spinner';
import { fireSentryEvent, setContextTag } from '../../services/sentry';
import UserCentersSelect from './user-centers-select';
import ResponsiveDialog from '../../components/ui/responsive-dialog';
import useModal from '../../hooks/useModal';
import { useAllMedicalCenters } from '../medical-centers/use-all-medical-centers';
import useUserMedicalCenters, {
  USER_MEDICAL_CENTERS_QUERY,
} from './use-user-medical-centers';

const useStyles = makeStyles((theme) => ({
  warning: {
    color: theme.palette.error.light,
  },
  field: {
    marginTop: theme.spacing(1),
  },
  medicalCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    marginInline: '80px',
    marginBottom: '5px',
    alignItems: 'center',
    paddingLeft: '20px',
  },
}));

export const DELETE_USER_OF_MEDICAL_CENTER_MUTATION = gql`
  mutation deleteUserOfMedicalCenter(
    $deleteUserOfMedicalCenterInput: deleteUserOfMedicalCenterInput!
  ) {
    deleteUserOfMedicalCenter(
      deleteUserOfMedicalCenterInput: $deleteUserOfMedicalCenterInput
    )
  }
`;

const ModifyUserDialog = ({ userId }) => {
  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  const {
    isModalOpen: deleteIsModalOpen,
    open: deleteOpenModal,
    close: deleteCloseModal,
  } = useModal();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { allMedicalCenters, loading } = useAllMedicalCenters();
  const { userMedicalCenters } = useUserMedicalCenters(userId);
  const [deleteMedicalCenter] = useMutation(DELETE_USER_OF_MEDICAL_CENTER_MUTATION, {
    refetchQueries: [{ query: USER_MEDICAL_CENTERS_QUERY, variables: { id: userId } }],
  });

  const handleSubmit = async (medicalCenterId) => {
    try {
      await deleteMedicalCenter({
        variables: {
          deleteUserOfMedicalCenterInput: {
            userId,
            medicalCenterId,
          },
        },
      });
      deleteCloseModal();
      enqueueSnackbar('Centro médico eliminado satisfactoriamente', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Ha ocurrido un error, el centro no fue eliminado del usuario', {
        variant: 'error',
      });
      setContextTag('error al eliminar centro del usuario', error.message);
      fireSentryEvent(error);
    }
  };

  if (loading) {
    return <FullPageSpinner />;
  }

  return (
    <>
      <Tooltip title="Ver Centros Médicos">
        <IconButton
          aria-label="ver centros medicos"
          color="primary"
          size="small"
          onClick={openModal}
          id="modify-user-btn"
        >
          <LocalHospitalIcon />
        </IconButton>
      </Tooltip>
      <ResponsiveDialog
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Centros Médicos del Usuario
        </DialogTitle>
        <DialogContent>
          {userMedicalCenters &&
            userMedicalCenters.map((center) => {
              return (
                <Paper className={classes.medicalCenter}>
                  <Typography variant="subtitle2">{center.name}</Typography>
                  <Tooltip title="Eliminar centro médico">
                    <IconButton
                      aria-label="Eliminar centro médico"
                      size="small"
                      color="primary"
                      onClick={deleteOpenModal}
                      id="delete-medical-center-btn"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  <ResponsiveDialog
                    align="center"
                    open={deleteIsModalOpen}
                    onClose={deleteCloseModal}
                    aria-labelledby="responsive-dialog-title"
                  >
                    <DialogTitle id="responsive-dialog-title">
                      Confirmar eliminación de centro médico
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        ¿Estás seguro de querer eliminar el centro médico para este usuario?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={deleteCloseModal} color="secondary">
                        Cancelar
                      </Button>
                      <Button
                        onClick={() => handleSubmit(center.id)}
                        disabled={loading}
                        color="primary"
                        variant="contained"
                        type="submit"
                      >
                        Eliminar
                      </Button>
                    </DialogActions>
                  </ResponsiveDialog>
                </Paper>
              );
            })}
          <UserCentersSelect
            userMedicalCenters={userMedicalCenters}
            userId={userId}
            allMedicalCenters={allMedicalCenters}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="secondary">
            Volver
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    </>
  );
};

export default ModifyUserDialog;
