import { Grid } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import TablePreparation from '../features/teams/table-preparation';
import TeamsInfo from '../features/teams/team-info';


const TeamDetails = ({ groupId }) => {
  //groupId en caso de ser admin, teamId en caso de ser otro
  const { id: teamId } = useParams();

  return (
    <Grid>
      <Grid container >
        <TeamsInfo groupId={groupId || teamId} />
      </Grid>
      <Grid container >
        <TablePreparation groupId={groupId || teamId} />
      </Grid>
    </Grid>
  );
};

export default TeamDetails;
