import React, { useState } from 'react';
import { Box, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Can from '../../utils/can';
import { useCurrentUser } from '../authentication/use-current-user';
import ConfirmReperfusionTimeButton from './Transplant/confirm-reperfusion-time-button';
import { rules } from '../../utils/rbac-rules';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select'

const useStyles = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(3),
    margin: '0 auto',
    textAlign: 'center',
  },
  image: {
    width: '400px',
    height: '400px',
  },
  disabled: {
    color: theme.palette.primary.main,
    marginBottom: '10px',
  },
  coordinationPaper: {
    marginTop: '10px',
  },
  select: {
    marginTop: '10px',
  },
  inputLabel: {
    marginBottom: '30px',
    fontSize: '20px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
}));


const ReperfusionTimeInput = ({ text, hour }) => {
  const classes = useStyles();
  return (
    <Box className={classes.coordination}>
      <Typography variant="h6">{text}</Typography>
      <Paper className={classes.coordinationPaper}>
        <Typography variant="h6">{hour}</Typography>
      </Paper>
    </Box>
  );
};

const SurgeryTransplantTab = ({ surgery }) => {
  const options = surgery.assignedOrgans;
  const [organ, setOrgan] = useState(surgery.assignedOrgans[0]);
  const classes = useStyles();
  const { user } = useCurrentUser();
  const dateFormat = require('dateformat');
  const [showTime, setShowTime] = useState(null);

  const handleDropdownChange = (event) => {
    // setValue(event.target.value);
    setOrgan(options[event.target.value]);
  };

  return (
    <>
      <Box className={classes.disabled}>
        <FormControl className={classes.formControl}>
          <InputLabel className={classes.inputLabel} id="demo-simple-select-autowidth-label">Selecciona un órgano</InputLabel>
          <Select
            className={classes.select}
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={organ.organType.name}
            onChange={handleDropdownChange}
            autoWidth
          >
            {surgery.assignedOrgans.map((option, index) => (
              <MenuItem value={index}>{surgery.assignedOrgans[index].organType.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography variant="h4">
          Tiempo de Reperfusión de {organ.organType.name}
        </Typography>
      </Box>
      <Box>
        <Can
          role={user.role}
          perform={rules.surgery.confirm}
          yes={() =>
            organ.reperfusionTime || showTime ? (
              <ReperfusionTimeInput
                text="Tiempo de Reperfusión Confirmado"
                hour={dateFormat(
                  organ.reperfusionTime ? organ.reperfusionTime : showTime,
                  'h:MM TT'
                )}
              />
            ) : (
              <ConfirmReperfusionTimeButton
                organ={organ}
                setShowTime={setShowTime}
                surgery={surgery}
                text="Tiempo de reperfusión"
                hour="No Asignado"
              />
            )
          }
          no={() =>
            organ.reperfusionTime ? (
              <ReperfusionTimeInput
                text="Tiempo de Reperfusión Confirmado"
                hour={dateFormat(organ.reperfusionTime, 'h:MM TT')}
              />
            ) : (
              <ReperfusionTimeInput text="Tiempo de Reperfusión" hour="No Disponible" />
            )
          }
        />
      </Box>
    </>
  );
};
export default SurgeryTransplantTab;
