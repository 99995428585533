import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useOrganGroup } from '../../teams/use-organ-group';




export default function IdentifierOrganGroup({schedule, surgery}) {

  const { organGroup, loading } = useOrganGroup(schedule.organGroupId);

  if (loading) {
      return null;
  }

  return (
    <Typography variant="subtitle1">Equipo de {organGroup.organType.name} del {organGroup.medicalCenter.name}</Typography>
  );
}

