import { fireSentryEvent, setContextTag } from '../../../services/sentry';
import React, { useState } from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import {
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import ResponsiveDialog from '../../../components/ui/responsive-dialog';
import useModal from '../../../hooks/useModal';
import { TimePicker } from "@material-ui/pickers";


export const SET_SURGERY_SCHEDULE_MUTATION = gql`
  mutation setSurgerySchedule($surgeryId: ID!, $scheduledAt: Date!) {
    setSurgerySchedule(surgeryId: $surgeryId, scheduledAt: $scheduledAt) {
      id
      scheduledAt
    }
  }
`;

const ConfirmSurgerySchedule = ({ surgery, setShowSchedule }) => {

  let disableSurgerySchedule = false;
  let finalSchedule = new Date();

  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  const [selectedDate, handleDateChange] = useState(finalSchedule);

  const { enqueueSnackbar } = useSnackbar();

  const [setSurgerySchedule, { loading }] = useMutation(SET_SURGERY_SCHEDULE_MUTATION);
  // , {
  //  refetchQueries: [
  //    {
  //      query: SUSPICION_QUERY,
  //      variables: {
  //        suspicionId,
  //      },
  //    },
  //  ],
  // });

  const handleSubmit = async () => {
    closeModal();
    try {
      const { data } = await setSurgerySchedule({ variables: { surgeryId: surgery.id, scheduledAt: selectedDate} });
      setShowSchedule(data.scheduledAt);
      enqueueSnackbar('Hora de cirugía confirmada correctamente', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Hubo un error al confirmar la hora de cirugía', {
        variant: 'error',
      });
      setContextTag('error al confirmar hora de cirugia', error.message);
      fireSentryEvent(error);
    }
  };

  return (
    <>
      {/* <Tooltip title="Esta funcionalidad aun no esta lista"> */}
        <Button
          aria-label="Confirmar hora de cirugia"
          size='small'
          disable={disableSurgerySchedule}
          variant="contained"
          color="primary"
          onClick={openModal}
          id="confirm-team-schedule"
        >
          Confirmar hora de cirugía
        </Button>
      {/* </Tooltip> */}
      <ResponsiveDialog
        align="center"
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Selecciona una hora de cirugía
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TimePicker
              label="Salida"
              variant="inline"
              placeholder="08:00 AM"
              mask="__:__ _M"
              value={selectedDate}
              onChange={date => handleDateChange(date)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="secondary">
            Cancelar
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={loading}
            color="primary"
            variant="contained"
            type="submit"
          >
            Confirmar
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    </>
  );
};

export default ConfirmSurgerySchedule;
