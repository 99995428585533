import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';


export const GET_MESSAGES_QUERY = gql`
  query GetMessagesQuery($chatId: ID!) {
    getMessages(chatId: $chatId) {
      id
      sender {
        id
        firstName
        lastName
        medicalCenters {
          id
          name
        }
      }
      content
      chatId
      createdAt
      updatedAt
    }
  }
`;

export const useGetMessages = (chatId) => {
  const { data, loading, error } = useQuery(GET_MESSAGES_QUERY, {
    variables: chatId,
    fetchPolicy: 'cache-and-network',
    // pollInterval: 3000,
  });

  return { messages: data?.getMessages, loading, error };
};