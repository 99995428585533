import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { SelectFilter, TextFilter } from '../../components/table/filters';
import EnhancedTable from '../../components/ui/enhanced-table';
import { changeDateFormatShort } from '../../utils/date-format';
import { FormatSurgeryStatus } from '../../constants/status';

const SurgeriesTable = ({ surgeries }) => {
  const data = useMemo(() => surgeries, [surgeries]);

  const columns = useMemo(
    () => [
      {
        label: 'RUT',
        accessor: 'suspicion.rut',
        disableSortBy: true,
        Filter: TextFilter,
      },
      {
        label: 'Centro médico',
        accessor: 'medicalCenter.name',
        Filter: SelectFilter,
        disableSortBy: true,
      },
      {
        Header: 'Crossclamp',
        accessor: 'crossClamp',
        disableFilters: true,
        disableSortBy: false,
        Cell: ({ value }) => (value ? changeDateFormatShort(value) : '--:---:--'),
      },
      {
        label: 'Estado actual',
        accessor: 'status',
        disableSortBy: true,
        Filter: SelectFilter,
        Cell: ({ value }) => (FormatSurgeryStatus(value)),
      },
    ],
    []
  );

  const history = useHistory();

  return (
    <EnhancedTable
      data={data}
      columns={columns}
      clickableRow
      onClickRow={(row) => history.push(`/surgeries/${row.original.id}`)}
    />
  );
};

export default SurgeriesTable;
