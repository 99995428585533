import { Paper, Grid, Box } from '@material-ui/core/';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { TextFilter } from '../../components/table/filters';
import EnhancedTable from '../../components/ui/enhanced-table';
import { changeDateFormatShort } from '../../utils/date-format';
import { useTeamMembers } from './use-team-members'


const TeamsListTable = ({ teams }) => {
  const data = useMemo(() => teams, [teams]);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => [
    {
      label: 'Centro Médico',
      accessor: 'medicalCenter.name',
      disableSortBy: false,
      Filter: TextFilter,
    },
    {
      label: 'Órgano',
      accessor: 'organType.name',
      Filter: TextFilter,
    },
    {
      id: 'boss',
      label: 'Jefe',
      accessor: 'id',
      Filter: TextFilter,
      Cell: ({ row, value }) => {
        const { teamMembers, loading } = useTeamMembers(value);
        if (loading) {
          return <></>;
        }
        else{
          const boss = teamMembers.filter((u) => u.boss.toString() === 'true');
          return <>{boss[0] ? `${boss[0].user.firstName + " " + boss[0].user.lastName}` : 'Sin jefe'}</>;
        }
      },
    },
    {
      id: 'fecha',
      Header: 'Fecha creación',
      accessor: 'createdAt',
      disableFilters: true,
      sortDescFirst: true,
      Cell: ({ value }) => (value ? changeDateFormatShort(value) : '--:---:--'),
    },
  ]);

  const history = useHistory();

  return (
    <Grid spacing={2}>
      <Box xs={12} sm={12} item>
        <Paper >
          <EnhancedTable
            data={data}
            columns={columns}
            initialState={{
              sortBy: [
                {
                  id: 'fecha',
                  desc: true
                }
              ]
            }}
            clickableRow
            onClickRow={(row) => history.push(`/teams/${row.original.id}`)}
          />
        </Paper>
      </Box>
    </Grid>
  );
};

export default TeamsListTable;
