import {
  Button,
  makeStyles,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import React from 'react';
import { gql } from 'apollo-boost';
import { useSnackbar } from 'notistack';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';

import { Formik, Form } from 'formik';

import { validate } from 'rut.js';
import FormTextField from '../../components/forms/form-text-field';
import { fireSentryEvent, setContextTag } from '../../services/sentry';

import Yup from '../../utils/validations';

import { useCan } from '../../utils/can';
import { rules } from '../../utils/rbac-rules';
import ResponsiveDialog from '../../components/ui/responsive-dialog';
import useModal from '../../hooks/useModal';
import { SURGERY_QUERY } from './use-surgery';
import { ALL_SURGERIES_QUERY } from './use-all-surgeries';
import { organStatus } from '../../constants/status';

const useStyles = makeStyles((theme) => ({
  dismissOrganButton: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    backgroundColor: theme.palette.info.main,
    color: theme.palette.getContrastText(theme.palette.warning.dark),
    '&:hover': {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.getContrastText(theme.palette.warning.dark),
    },
  },
}));

const CHANGE_RECEIVER_MUTATION = gql`
  mutation changeReceiver($id: ID!, $newRut: String!, $comment: String!) {
    changeReceiver(id: $id, newRut: $newRut, comment: $comment) {
      id
    }
  }
`;

const suspicionSchema = Yup.object({
  suspicionInput: Yup.object().shape({
    sex: Yup.string(),
    rut: Yup.string().required().test('rut-format', 'Rut es inválido.', validate),
    description: Yup.string(),
  }),
});

const ChangeReceiverButton = ({ offering, surgery, organ }) => {
  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  const canRejectOrgan = useCan({ perform: rules.surgery.reject });
  const [changeReceiver, { loading }] = useMutation(CHANGE_RECEIVER_MUTATION, {
    refetchQueries: [{ query: SURGERY_QUERY, variables: { surgeryId: surgery.id } }],
  });


  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleSubmit = async (values, { resetForm }) => {
    try {
      await changeReceiver({
        variables: {
          id: offering.id,
          newRut: values.suspicionInput.rut,
          comment: values.suspicionInput.description,
        },
      });
      resetForm();
      enqueueSnackbar('Receptor Cambiado Exitosamente', { variant: 'success' });
      closeModal();
    } catch (error) {
      enqueueSnackbar('Hubo un error al procesar su solicitud.', {
        variant: 'error',
      });
      setContextTag('error al crear sospecha', error.message);
      fireSentryEvent(error);
    }
  };

  if (!canRejectOrgan || organ?.status === organStatus.rejected) return null;

  return (
    <>
      <Button
        className={classes.dismissOrganButton}
        onClick={() => openModal()}
        variant="contained"
        size="small"
        data-testid="reject-organ-button"
        startIcon={<AllInclusiveIcon />}
      >
        Cambiar de receptor
      </Button>
      <Formik
        initialValues={{
          suspicionInput: {
            rut: '',
            description: '',
          },
        }}
        onSubmit={handleSubmit}
        validationSchema={suspicionSchema}
      >
        {({ submitForm }) => (
          <Form>
            <ResponsiveDialog
              align="center"
              open={isModalOpen}
              onClose={closeModal}
              aria-labelledby="reject-organ-dialog"
            >
              <DialogTitle id="reject-organ-dialog">
                Cambiar receptor de {organ.organType.name.toLowerCase()}, RUT: {surgery.suspicion.rut}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  ¿Estás seguro de querer cambiar el receptor este órgano?
                </DialogContentText>

                <FormTextField
                  label="Rut Nuevo"
                  required
                  name="suspicionInput.rut"
                  style={{ flexBasis: '60%' }}
                />

                <FormTextField
                  fullWidth
                  label="Comentario"
                  name="suspicionInput.description"
                  className={classes.margin}
                  multiline
                  rows={4}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={closeModal} color="secondary">
                  Volver
                </Button>
                <Button
                  onClick={submitForm}
                  disabled={loading}
                  color="primary"
                  type="submit"
                >
                  Cambiar de receptor
                </Button>
              </DialogActions>
            </ResponsiveDialog>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ChangeReceiverButton;
