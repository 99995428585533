import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

export const ALL_ORGAN_GROUPS_QUERY = gql`
  query AllTeams {
    allTeams: getOrganGroups {
      id
      organTypeId
      medicalCenterId
      organType {
        name
      }
      medicalCenter {
        name
      }
      createdAt
    }
  }
`;

export function useAllTeams() {
  const { data, loading, error } = useQuery(ALL_ORGAN_GROUPS_QUERY);

  return { allTeams: data?.allTeams, loading, error };
}
