import React, { useEffect } from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useSnackbar } from 'notistack';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import Yup from '../../utils/validations';
import FormTextField from '../../components/forms/form-text-field';
import { AUTHENTICATION_TOKEN_KEY } from '../../constants/index';
import * as authActions from './auth-slice';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 2, 2),
    width: '90%',
  },
  field: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    width: '90%',
  },
  checkbox: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));

export const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password)
  }
`;

const loginFormSchema = Yup.object({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
});

const LoginForm = () => {
  const [login, { loading, error, client }] = useMutation(LOGIN_MUTATION);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSubmit = async (values, { resetForm }) => {
    const { data } = await login({
      variables: values,
    });
    resetForm({});

    window.localStorage.setItem(AUTHENTICATION_TOKEN_KEY, data.login);
    await client.resetStore();
    dispatch(authActions.login({ token: data.login }));
  };

  useEffect(() => {
    if (error) {
      enqueueSnackbar('Correo o contraseña incorrecta', { variant: 'error' });
    }
  }, [error, enqueueSnackbar]);

  return (
    <>
      <Typography variant="h6" align="center" className={classes.title}>
        Inicio de sesión
      </Typography>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={loginFormSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <FormTextField
              className={classes.field}
              margin="normal"
              label="Correo electrónico"
              name="email"
              required
            />
            <FormTextField
              className={classes.field}
              margin="normal"
              label="Contraseña"
              name="password"
              type="password"
              required
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="secondary" />}
              label="Recordarme"
              className={classes.checkbox}
            />
            <Button
              variant="contained"
              type="submit"
              color="secondary"
              disabled={loading}
              className={classes.submit}
            >
              Iniciar sesión
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default LoginForm;
