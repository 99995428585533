import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Box,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/react-hooks';
import React from 'react';
import FormCheckboxField from '../../components/forms/form-checkbox-field';
import FormDropzoneArea from '../../components/forms/form-dropzone-area';
import FormTextField from '../../components/forms/form-text-field';
import ResponsiveDialog from '../../components/ui/responsive-dialog';
import Yup from '../../utils/validations';
import { SUSPICION_QUERY } from '../suspicions/use-suspicion';
import { CREATE_INCLUSION_CRITERIA_MUTATION } from './use-create-inclusion-criteria';
import { fireSentryEvent, setContextTag } from '../../services/sentry';

const SUPPORTED_FORMATS = [
  'image/jpeg',
  'image/png',
  'application/pdf',
  '.xls',
  '.xlsx',
  '.doc',
  '.docx',
];

const inclusionCriteriaSchema = Yup.object({
  vih: Yup.mixed().required('Archivo requerido'),
  vhb: Yup.mixed().required('Archivo requerido'),
  vhc: Yup.mixed().required('Archivo requerido'),
  
  //TODO: Cambiar nombre de atributo de hasSepsis y hasCancer a sepsisFree y cancerFree (refactor)
  hasSepsis: Yup.boolean().oneOf([true], 'El paciente no puede tener sepsis'),
  hasCancer: Yup.boolean().oneOf([true], 'El paciente no puede tener cáncer'),
  comments: Yup.string().optional(),
  suspicionId: Yup.number().required(),
});

const useStyles = makeStyles((theme) => ({
  filesContainer: {
    display: 'flex',
    '& > *:not(:last-child)': { margin: theme.spacing(0, 1, 0, 0) },
  },
  extensions: {
    display: 'block',
    textAlign: 'right',
    color: theme.palette.text.disabled,
  },
  loading: {
    color: theme.palette.text.disabled,
  }
}));

const CreateInclusionCriteriaDialog = ({ suspicion, open, onClose }) => {
  const [createInclusionCriteria, { loading }] = useMutation(
    CREATE_INCLUSION_CRITERIA_MUTATION,
    {
      refetchQueries: [
        {
          query: SUSPICION_QUERY,
          variables: {
            suspicionId: suspicion.id,
          },
        },
      ],
    }
  );
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleSubmit = async (values, { resetForm }) => {
    resetForm({});
    try {
      await createInclusionCriteria({
        variables: { inclusionCriteriaInput: values },
      });
      enqueueSnackbar('Criterio de inclusión creado exitosamente', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Hubo un error al procesar su solicitud.', { variant: 'error' });
      setContextTag('error de solicitud de criterio de inclusion', error.message);
      fireSentryEvent(error);
    }
    onClose();
  };

  return (
    <Formik
      initialValues={{
        vhb: null,
        vhc: null,
        vih: null,
        hasCancer: false,
        hasSepsis: false,
        comments: '',
        suspicionId: suspicion.id,
      }}
      onSubmit={handleSubmit}
      validationSchema={inclusionCriteriaSchema}
    >
      {({ submitForm }) => (
        <Form>
          <ResponsiveDialog
            open={open}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              Agregar criterio de inclusión
            </DialogTitle>
            <DialogContent>
              <DialogContentText>Completa el formulario con los datos.</DialogContentText>
              <Box className={classes.filesContainer}>
                <FormDropzoneArea
                  name="vhb"
                  label="VHB"
                  filesLimit={1}
                  acceptedFiles={SUPPORTED_FORMATS}
                />
                <FormDropzoneArea
                  name="vhc"
                  label="VHC"
                  filesLimit={1}
                  acceptedFiles={SUPPORTED_FORMATS}
                />
                <FormDropzoneArea
                  name="vih"
                  label="VIH"
                  filesLimit={1}
                  acceptedFiles={SUPPORTED_FORMATS}
                />
              </Box>
              <Typography className={classes.extensions} variant="caption">
                Extensiones permitidas: pdf, jpg, png, xls, doc, xlsx, docx
              </Typography>
              <FormCheckboxField label="No tiene cáncer" name="hasCancer" />
              <FormCheckboxField label="No tiene sepsis" name="hasSepsis" />
              <FormTextField
                name="comments"
                label="Comentarios"
                multiline
                rows={4}
                fullWidth
                onFocus={(event) => event.stopPropagation()}
              />
            </DialogContent>
            <DialogActions>
              {loading && <CircularProgress color="inherit" data-testid="loading-spinner" />}
              <Button onClick={onClose} color="secondary">
                Cancelar
              </Button>
              <Button
                onClick={submitForm}
                disabled={loading}
                color="primary"
                type="submit"
                variant="contained"
              >
                Ingresar
              </Button>
            </DialogActions>
          </ResponsiveDialog>
        </Form>
      )}
    </Formik>
  );
};

export default CreateInclusionCriteriaDialog;
