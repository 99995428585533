import React from 'react';
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from '@material-ui/core';

import ResponsiveDialog from '../../../components/ui/responsive-dialog';
import useModal from '../../../hooks/useModal';

const oldRecieverButton = ({ offering, ...buttonProps }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isModalOpen, open, close } = useModal();

  return (
    <>
      <Button
        onClick={open}
        variant="contained"
        color="white"
        data-testid="accept-offering-button"
        {...buttonProps}
      >
        Receptor Original
      </Button>
      <ResponsiveDialog align="left" open={isModalOpen} onClose={close}>
        <DialogTitle id="confirm-suspicion-dialog">
          Rut de Receptor Original: {offering.recieverDocumentId}
        </DialogTitle>
        <DialogContent>
          <Typography style={{ marginBottom: '14px' }} variant="h6">
            Comentario
          </Typography>
          <DialogContentText>
            {offering.newReceiverPost && offering.newReceiverPost.comment}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="secondary">
            Volver
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    </>
  );
};

export default oldRecieverButton;
