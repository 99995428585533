import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import GoogleMapReact from 'google-map-react';
import RoomIcon from '@material-ui/icons/Room';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select'


const useStyles = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(3),
    margin: '0 auto',
    textAlign: 'center',
  },
  text: {
    color: 'blue',
    backgroundColor: 'pink',
    padding: '5px',
  },
  title: {
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 0',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  marker: {
    color: 'red',
    fontSize: '50px',
  },
  select: {
    marginTop: '10px',
  },
  inputLabel: {
    marginBottom: '30px',
    fontSize: '20px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
}));

export const COORDINATES_UPDATED_SUBSCRIPTION = gql`
  subscription coordinatesUpdated($organId: Int!) {
    coordinatesUpdated(organId: $organId) {
      id
      latitude
      longitude
    }
  }
`;

const MarkerComponent = ({ text }) => {
  const classes = useStyles();
  return <RoomIcon className={classes.marker} />;
};

function SimpleMap({ centerLat, centerLon, markerLat, markerLon }) {
  const defaultProps = {
    center: {
      lat: centerLat,
      lng: centerLon,
    },
    zoom: 15,
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '500px', width: '800px' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBt_vDS8tIaccJN05O2ZWDbp5P6DfOO-Zw" }}
        defaultCenter={{ lat: centerLat, lng: centerLon }}
        center={{ lat: centerLat, lng: centerLon }}
        defaultZoom={defaultProps.zoom}
      >
        <MarkerComponent lat={markerLat} lng={markerLon} text="My Marker" />
      </GoogleMapReact>
    </div>
  );
}

const SurgeryMap = ({ surgery }) => {
  const options = surgery.assignedOrgans;
  // const organ = surgery.assignedOrgans[0];
  const [organ, setOrgan] = useState(surgery.assignedOrgans[0]);
  const classes = useStyles();
  const [centerLat, setCenterLat] = useState(-33.468107);
  const [centerLon, setCenterLon] = useState(-70.647583);
  const [markerLat, setMarkerLat] = useState(-33.468107);
  const [markerLon, setMarkerLon] = useState(-70.647583);

  const handleDropdownChange = (event) => {
    // setValue(event.target.value);
    setOrgan(options[event.target.value]);
  };

  const {
    data: subscriptionData,
    loading: subscriptionLoading,
    error: subscriptionError,
  } = useSubscription(COORDINATES_UPDATED_SUBSCRIPTION, {
    // eslint-disable-next-line radix
    variables: { organId: organ.id },
  });

  function centerCoords(lat, lon) {
    setCenterLat(lat);
    setCenterLon(lon);
    setMarkerLat(lat);
    setMarkerLon(lon);
  }

  const handleRecieve = (data) => {
    if (!subscriptionLoading) {
      const { latitude, longitude } = data.coordinatesUpdated;
      centerCoords(latitude, longitude);
    }
  };

  useEffect(() => {
    handleRecieve(subscriptionData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionData]);

  useEffect(() => { }, [subscriptionError]);

  return (
    <>
      <FormControl className={classes.formControl}>
        <InputLabel className={classes.inputLabel} id="demo-simple-select-autowidth-label">Selecciona un órgano</InputLabel>
        <Select
          className={classes.select}
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={organ.organType.name}
          onChange={handleDropdownChange}
          autoWidth
        >
          {surgery.assignedOrgans.map((option, index) => (
            <MenuItem value={index}>{surgery.assignedOrgans[index].organType.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box className={classes.title}>
        <Typography variant="h4"> Traslado de {organ.organType.name}</Typography>
      </Box>
      <Box className={classes.content}>
        <SimpleMap
          centerLat={centerLat}
          centerLon={centerLon}
          markerLat={markerLat}
          markerLon={markerLon}
        />
      </Box>
      {/* <Box className={classes.centerButton}>
        <Button onClick={() => centerCoords()} variant="contained">
          Enviar
        </Button>
      </Box> */}
    </>
  );
};

export default SurgeryMap;
