import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

const POSTS_QUERY = gql`
  query getPosts($getPostsInput: getPostsInput!) {
    getPosts(getPostsInput: $getPostsInput) {
      id
      organGroupId
      surgeryId
      userId
      comment
      type
      createdAt
      organGroup{
        id
      }
      user{
        id
        firstName
        lastName
      }
    }
  }
`;

const usePosts = (organId, type) => {
  const { data, loading, error } = useQuery(POSTS_QUERY, {
    variables: { 
      getPostsInput: {
        organId: organId,
        type: type,
      },
    },
  });
  const posts = data?.getPosts; 
  return { posts, loading, error };
};

export {POSTS_QUERY, usePosts };
