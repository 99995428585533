import React from 'react';
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link, 
  Button
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import { changeDateFormat } from '../../utils/date-format';

import CustomStepper from '../../components/ui/custom-stepper';
import { surgeryStatus, FormatSurgeryStatus } from '../../constants/status';
import ScheduleCrossClampButton from './schedule-cross-clamp-button';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.primary.main,
    fontWeight: theme.typography.h6.fontWeight,
    textTransform: theme.typography.overline.textTransform,
  },
  body: { fontWeight: theme.typography.body1.fontWeight },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  details: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    lineHeight: '24px',
    letterSpacing: '0.15px',
    opacity: '0.6',
  },
  description: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    lineHeight: '20px',
    letterSpacing: '0.25px',
    opacity: '0.6',
  },
  inactive: { color: theme.palette.text.disabled },
}));

const SurgeryBar = ({ surgery }) => {
  const classes = useStyles();

  return (
    <Paper elevation={3} className={classes.paper}>
      <Table>
        <TableHead className={classes.header}>
          <TableRow>
            <StyledTableCell style={{ width: '20%' }}>Paciente</StyledTableCell>
            <StyledTableCell style={{ width: '20%' }}>
              Fecha de cross-clamp
            </StyledTableCell>
            <StyledTableCell style={{ width: '40%' }}>Estado</StyledTableCell>
            <StyledTableCell style={{ width: '20%' }}>
              Centro médico de origen
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <StyledTableCell><Link
          className={classes.link}
          component={RouterLink}
          to={`/suspicions/${surgery.suspicion.id}`}
        >
          <Button
            abel="Más información de oferta"
            size="small"
            color="secondary"
            variant="contained"
            id="more-info-donor-button"
            data-testid="more-info-donor-button"
          >
            DONANTE: {surgery.suspicion.rut}
          </Button>
        </Link></StyledTableCell>
        <StyledTableCell>
              {surgery.crossClamp ? (
                changeDateFormat(surgery.crossClamp)
              ) : (
                <ScheduleCrossClampButton surgery={surgery} />
              )}
            </StyledTableCell>
            <StyledTableCell>
              <CustomStepper
                steps={Object.values(surgeryStatus).map((status) => FormatSurgeryStatus(status))}
                activeStep={FormatSurgeryStatus(surgery.status)}
              />
            </StyledTableCell>
            <StyledTableCell>{surgery.medicalCenter.name}</StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

export default SurgeryBar;
