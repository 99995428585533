import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import PatientsList from '../features/organs/patients-list';

const OrgansPage = () => {
  return (
    <>
      <Grid container justify="flex-start" spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div">
            Órganos
          </Typography>
        </Grid>
        <Grid item md={12} lg={9}>
          <PatientsList />
        </Grid>
      </Grid>
    </>
  );
};

export default OrgansPage;
