import React, { useState, useMemo } from 'react';
import {
  Grid,
  Typography,
  Paper,
  withStyles,
  Tab,
  Tabs,
  makeStyles,
} from '@material-ui/core';
import { useAllOfferings } from '../features/offerings/use-all-offerings';
import OfferingsPanel from '../features/offerings/offerings-panel';
import FullPageSpinner from '../components/ui/full-page-spinner';
// import { useCan } from '../utils/can';
// import { rules } from '../utils/rbac-rules';

const StyledTab = withStyles((theme) => ({
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
}))(Tab);

const gridSpacing = 3;
const useStyles = makeStyles((theme) => ({
  paper: {
    // top and bottom 24px padding + toolbar height + grid spacing + tabHeight
    height: `calc(100vh - ${theme.spacing(3 * 2 + 8 + gridSpacing + 4 + 6)}px)`,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}));

const OfferingsPage = () => {
  const classes = useStyles();
  const [tab, setTab] = useState(0);
  // const canManageOfferings = useCan({ perform: rules.offerings.manage });

  const { offerings: allCurrentOfferings, loading: currentLoading } = useAllOfferings();
  const { offerings: acceptedAndExpiredAndRejectedOfferings, loading: expiredLoading } = useAllOfferings({
    expired: true,
  });

  // const medicalCenterIds = useMemo(() => user?.medicalCenters.map(({ id }) => id), [
  //   user,
  // ]);

  const currentOfferings = useMemo(() => allCurrentOfferings, [
    // canManageOfferings,
    allCurrentOfferings,
    // medicalCenterIds,
  ]);

  const successfulOfferings = useMemo(
    () =>
      acceptedAndExpiredAndRejectedOfferings?.filter(
        (offering) => offering.accepted === true
      ),
    [acceptedAndExpiredAndRejectedOfferings,
      // canManageOfferings, 
    // medicalCenterIds,
  ]);

  const unsuccessfulOfferings = useMemo(
    () =>
      acceptedAndExpiredAndRejectedOfferings?.filter(
        (offering) => offering.accepted !== true
      ),
    [acceptedAndExpiredAndRejectedOfferings,
      // canManageOfferings,
      // medicalCenterIds
    ]);

  if (currentLoading || expiredLoading) return <FullPageSpinner />;

  return (
    <>
      <Grid className={classes.root} container justify="flex-start" spacing={gridSpacing}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div">
            Ofertas
          </Typography>
        </Grid>
        <Grid style={{ height: '100%' }} item lg={12} xl={10}>
          <Tabs value={tab}>
            <StyledTab onClick={() => setTab(0)} label="En curso" />
            <StyledTab onClick={() => setTab(1)} label="Aceptadas" />
            <StyledTab onClick={() => setTab(2)} label="Expiradas / Rechazadas" />
          </Tabs>
          <Paper className={classes.paper} elevation={3}>
            {tab === 0 && <OfferingsPanel offerings={currentOfferings} />}
            {tab === 1 && <OfferingsPanel expired offerings={successfulOfferings} />}
            {tab === 2 && <OfferingsPanel expired offerings={unsuccessfulOfferings} />}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default OfferingsPage;
