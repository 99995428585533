import React from 'react';
import { Grid, Paper, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { changeDateFormatShort } from '../../utils/date-format';

const useStyles = makeStyles((theme) => ({
  title: {
    padding: theme.spacing(1),
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  detailTitle: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  detailData: {
    paddingLeft: theme.spacing(3),
    color: 'gray',
  },
  detailDataTime: {
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    color: 'gray',
    fontSize: '12px',
  },
}));

const SuspicionHistory = ({ suspicion }) => {
  const classes = useStyles();
  return (
    <Paper elevation={3}>
      <Grid item xs={6} align="left">
        <Typography className={classes.title}>Actividad</Typography>
      </Grid>
      {suspicion.history.map((event) => (
        <Grid key={event.date}>
          <Grid container>
            <Grid item xs={9} align="left">
              <Typography className={classes.detailTitle}>{event.message}</Typography>
            </Grid>
            <Grid item xs={3} align="right">
              <Typography className={classes.detailDataTime}>
                {changeDateFormatShort(event.date)}
              </Typography>
            </Grid>
          </Grid>
          <Typography className={classes.detailData}>
            {event.author.firstName} {event.author.lastName}
          </Typography>
          <br />
          <Divider variant="middle" />
        </Grid>
      ))}
    </Paper>
  );
};

export default SuspicionHistory;
