import {
  Checkbox,
  MenuItem,
  Select,
  TextField,
  InputAdornment,
  makeStyles,
} from '@material-ui/core/';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  input: {
    '&::placeholder': {
      color: '#828282',
      opacity: 1,
      fontWeight: 500,
      lineHeight: '28px',
    },
    marginLeft: theme.spacing(1),
  },
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
  textFilter: {
    backgroundColor: '#ECF0F1',
    borderBottomColor: 'transparent',
  },
  select: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
    color: theme.palette.primary.main,
    opacity: 1,
    fontWeight: 500,
    lineHeight: '28px',
    textTransform: 'uppercase',
    width: 180,
  },
}));

export function SelectFilter({
  column: { filterValue, setFilter, preFilteredRows, id, label },
}) {
  const classes = useStyles();
  const options = React.useMemo(() => {
    const differentOptions = new Set();
    preFilteredRows.forEach((row) => {
      differentOptions.add(row.values[id]);
    });
    return [...differentOptions.values()];
  }, [id, preFilteredRows]);

  return (
    <Select
      value={filterValue || ''}
      onChange={(event) => {
        setFilter(event.target.value || undefined);
      }}
      displayEmpty
      className={classes.select}
    >
      <MenuItem className={classes.select} value="" disabled>
        {label}
      </MenuItem>
      {options.map((option) => (
        <MenuItem key={option} value={option} className={classes.select}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
}

export const TextFilter = ({ column: { filterValue, setFilter, label } }) => {
  const classes = useStyles();
  return (
    <TextField
      className={classes.textFilter}
      type="text"
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`${label}...`}
      InputProps={{
        classes: { input: classes.input, underline: classes.underline },
        endAdornment: (
          <InputAdornment>
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export const CheckboxFilter = ({ column: { filterValue, setFilter } }) => {
  return (
    <Checkbox
      checked={filterValue || false}
      onChange={(e) => {
        setFilter(e.target.checked);
      }}
    />
  );
};
