import { Paper, Grid } from '@material-ui/core/';
import React, { useMemo } from 'react';
import { SelectFilter, TextFilter } from '../../components/table/filters';
import EnhancedTable from '../../components/ui/enhanced-table';
import DeleteUserButton from './delete-user-button';
import ModifyUserButton from './modify-user-button';
import ModifyUserCentersButton from './modify-user-centers-button';

const UsersTable = ({ users }) => {
  const data = useMemo(() => users, [users]);
  const columns = useMemo(
    () => [
      {
        label: 'NOMBRE',
        accessor: 'firstName',
        Filter: TextFilter,
      },
      {
        label: 'APELLIDO',
        accessor: 'lastName',
        Filter: TextFilter,
      },
      {
        label: 'CORREO',
        accessor: 'email',
        Filter: TextFilter,
      },
      {
        label: 'ROL',
        accessor: 'role',
        Filter: SelectFilter,
        disableSortBy: true,
      },
      {
        Header: 'ACCIONES',
        id: 'actions',
        accessor: 'id',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ row, value }) => {
          return (
            <Grid container justify="space-evenly">
              <Grid item xs={3} align="left">
                <ModifyUserButton userInfo={row.original} />
              </Grid>
              <Grid item xs={3} align="left">
                <ModifyUserCentersButton userId={value} userInfo={row.original} />
              </Grid>
              <Grid item xs={3} align="right">
                <DeleteUserButton userId={value} />
              </Grid>
            </Grid>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <Paper elevation={0}>
        <EnhancedTable data={data} columns={columns} />
      </Paper>
    </>
  );
};

export default UsersTable;
