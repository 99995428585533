import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

export const MY_TEAMS_QUERY = gql`
  query getUserGroupMembers {
    getUserGroupMembers {
      groupId
      userId
    }
  }
`;

export const useMyTeams = () => {
  const { data, loading, error } = useQuery(MY_TEAMS_QUERY);

  return { userGroups: data?.getUserGroupMembers, loading, error };
};
