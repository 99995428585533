import { Box, Paper, Typography } from '@material-ui/core';
import React from 'react';
import FullPageSpinner from '../components/ui/full-page-spinner';
import { useCurrentUser } from '../features/authentication/use-current-user';
import SurgeriesTable from '../features/surgeries/surgeries-table';
import { useAllSurgeries } from '../features/surgeries/use-all-surgeries';
import { useMyTeams } from '../features/teams/use-my-teams';
import Can from '../utils/can';
import { rules } from '../utils/rbac-rules';

const SurgeriesPage = () => {
  const { user } = useCurrentUser();
  const { surgeries, loading } = useAllSurgeries();
  const { userGroups, loading: loadingUserGroups } = useMyTeams();

  if (loading || loadingUserGroups) {
    return <FullPageSpinner />;
  }

  return (
    <>
      <Can
        role={user.role}
        perform={rules.surgery.get}
        yes={() => (
          <>
            <Box>
              <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography variant="h6" component="div">
                  Cirugías
                </Typography>
              </Box>
              <Paper elevation={0}>
                <SurgeriesTable surgeries={surgeries} />
              </Paper>
            </Box>
          </>
        )}
        no={() => (
          <>
            {
              userGroups.length === 0 ? 
              <Typography variant="h6">
                No eres miembro de un equipo de procura, no puedes ver cirugías agendadas
              </Typography>
              :
              <Box>
                <Box display="flex" justifyContent="space-between" mb={2}>
                  <Typography variant="h6" component="div">
                    Cirugías
                  </Typography>
                </Box>
                <Paper elevation={0}>
                  <SurgeriesTable surgeries={surgeries} />
                </Paper>
              </Box>
            }
          </>
        )}
        />
    </>
  );
};

export default SurgeriesPage;
