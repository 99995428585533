import { fireSentryEvent, setContextTag } from '../../services/sentry';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import { Formik, Form } from 'formik';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {
  Tooltip,
  Button,
  DialogContent,
  DialogTitle,
  DialogActions,
  Container,
  makeStyles,
  Grid,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import Yup from '../../utils/validations';
import ResponsiveDialog from '../../components/ui/responsive-dialog';
import useModal from '../../hooks/useModal';
import FormSingleAutocomplete from '../../components/forms/form-single-autocomplete';
import { ALL_ORGAN_GROUPS_QUERY } from './use-all-teams';
import { useAllMedicalCenters } from '../medical-centers/use-all-medical-centers';
import { useAllOrganTypes } from '../organs/useAllOrganTypes';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  formControl: {
    margin: theme.spacing(2, 0),
    minWidth: 275,
  },
}));

export const CREATE_ORGAN_GROUP_MUTATION = gql`
  mutation CreateOrganGroupMutation($organGroupInput: organGroupInput!) {
    createOrganGroup(organGroupInput: $organGroupInput) {
      id
      medicalCenterId
      organTypeId
    }
  }
`;

// const createOrganGroupSchema = Yup.object({
//   name: Yup.string().required(),
// });

const validationSchema = Yup.object({
  medicalCenter: Yup.string().required(),
  organType: Yup.string().required(),
});

const CreateTeamButton = () => {
  const history = useHistory();

  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  const [createOrganGroup, { loading }] = useMutation(CREATE_ORGAN_GROUP_MUTATION, {
    update: (cache, { data: { createOrganGroup } }) => {
      const { allTeams: existingTeams } = cache.readQuery({
        query: ALL_ORGAN_GROUPS_QUERY,
      });
      cache.writeQuery({
        query: ALL_ORGAN_GROUPS_QUERY,
        data: {
          allTeams: [...existingTeams, createOrganGroup],
        },
      });
    },
  });
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { allMedicalCenters } = useAllMedicalCenters();
  const { allOrganTypes } = useAllOrganTypes();

  const handleSubmit = async (values, { resetForm }) => {
    try {
      await createOrganGroup({
        variables: {
          organGroupInput: {
            medicalCenterId: values.medicalCenter,
            organTypeId: values.organType,
          },
        },
      });
      resetForm({});
      enqueueSnackbar('Equipo hospital órgano creado exitosamente', {
        variant: 'success',
      });
      closeModal();
      history.push('/teams');
      window.location.reload();
    } catch (error) {
      enqueueSnackbar(
        'Ha ocurrido un error, equipo hospital órgano no fue creado exitosamente',
        {
          variant: 'error',
        }
      );
      setContextTag('error al crear equipo hospital organo', error.message);
      fireSentryEvent(error);
    }
  };

  return (
    <>
      <Tooltip title="Crear equipo">
        <Button
          aria-label="Crear equipo"
          color="primary"
          onClick={openModal}
          id="create-team-btn"
        >
          <AddCircleIcon />
        </Button>
      </Tooltip>
      <Formik
        initialValues={{
          medicalCenter: undefined,
          organType: undefined,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm }) => (
          <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
              <Form className={classes.form}>
                <ResponsiveDialog
                  open={isModalOpen}
                  onClose={closeModal}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    Crear nuevo equipo
                  </DialogTitle>
                  <DialogContent>
                    <Grid item xs={12} sm={12}>
                      <FormSingleAutocomplete
                        className={classes.field}
                        name="medicalCenter"
                        label="Centro Médico"
                        options={allMedicalCenters}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => option.name}
                        getValueFromOption={(option) => option.id}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormSingleAutocomplete
                        className={classes.field}
                        name="organType"
                        label="Órgano"
                        options={allOrganTypes}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => option.name}
                        getValueFromOption={(option) => option.id}
                      />
                    </Grid>
                  </DialogContent>
                  <br />
                  <DialogActions>
                    <Button onClick={closeModal} color="secondary">
                      Cancelar
                    </Button>
                    <Button
                      onClick={submitForm}
                      disabled={loading}
                      color="primary"
                      type="submit"
                    >
                      Registrar
                    </Button>
                  </DialogActions>
                </ResponsiveDialog>
              </Form>
            </div>
          </Container>
        )}
      </Formik>
    </>
  );
};

export default CreateTeamButton;
