import React from 'react';
import { Grid, Typography, Box, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import Divider from '@material-ui/core/Divider';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Button from '@material-ui/core/Button';
import DescriptionIcon from '@material-ui/icons/Description';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { changeDateFormat } from '../../utils/date-format';
import CreateInclusionCriteriaButton from './create-inclusion-criteria-button';
import ConfirmInclusionCriteriaButton from './confirm-inclusion-criteria-dialog';
import { useCurrentUser } from '../authentication/use-current-user';
import Can from '../../utils/can';
import { rules } from '../../utils/rbac-rules';
import SuspicionExpansionPanelSummary from '../suspicions/suspicion-expansion-panel-summary';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
  },
  expanded: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  notExpanded: {
    backgroundColor: theme.palette.secondary.main,
  },
  buttonExpanded: {
    color: 'white',
  },
  disclaimer: {
    paddingLeft: theme.spacing(2),
    fontSize: '13px',
    color: theme.palette.text.secondary,
    fontStyle: 'italic'
  },
  checkboxLabel: {
    fontSize: 14,
    marginLeft: theme.spacing(-7),
    marginTop: theme.spacing(0.75),
  },
  detailTitle: {
    paddingLeft: theme.spacing(2),
    fontSize: '16px',
    color: theme.palette.text.secondary,
  },
  detailData: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  detailDataTime: {
    paddingLeft: theme.spacing(2),
    color: 'gray',
  },
  detailUser: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.text.secondary,
    fontSize: '16px',
  },
  detailNotConfirmed: {
    paddingLeft: theme.spacing(24),
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    fontSize: '16px',
  },
  detailDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  detailCheckbox: {
    marginTop: theme.spacing(0.5),
  },
  download: {
    color: theme.palette.primary.main,
  },
  disableDownload: {
    color: 'gray',
  },
  heading: {
    fontSize: '16px',
  },
}));

const InclusionCriteriaDetails = ({ suspicion, isExpanded, onClick }) => {
  const classes = useStyles();
  const { user } = useCurrentUser();

  return (
    <>
      <div className={classes.root}>
        <ExpansionPanel expanded={isExpanded}>
          <SuspicionExpansionPanelSummary
            label="Criterios de inclusión"
            onClick={onClick}
            isExpanded={isExpanded}
            suspicion={suspicion}
            stepName="inclusionCriteria"
            testId="details-inclusion-criteria-btn"
          >
            {!suspicion.inclusionCriteria && (
              <Can
                role={user.role}
                perform={rules.inclusionCriteria.create}
                yes={() =>
                  suspicion.inclusionCriteria ? null : (
                    <CreateInclusionCriteriaButton suspicion={suspicion} />
                  )
                }
              />
            )}
          </SuspicionExpansionPanelSummary>
          {suspicion.inclusionCriteria && (
            <ExpansionPanelDetails>
              <Grid container>
                <Grid item xs={6} align="left">
                  <Typography className={classes.detailDataTime}>
                    {changeDateFormat(suspicion.inclusionCriteria.createdAt)}
                  </Typography>
                  <Typography className={classes.detailData}>
                    Criterios de Inclusión ingresados por:{' '}
                  </Typography>
                  <Typography className={classes.detailUser}>
                    {suspicion.inclusionCriteria.creator.firstName}{' '}
                    {suspicion.inclusionCriteria.creator.lastName}
                  </Typography>
                </Grid>
                <Grid item xs={6} align="left">
                  {suspicion.inclusionCriteria.confirmation.status ? (
                    <>
                      <Typography className={classes.detailDataTime}>
                        {changeDateFormat(
                          suspicion.inclusionCriteria.confirmation.updatedAt
                        )}
                      </Typography>
                      <Typography className={classes.detailData}>
                        Confirmados por:{' '}
                      </Typography>
                      <Typography className={classes.detailUser}>
                        {suspicion.inclusionCriteria.confirmation.user.firstName}{' '}
                        {suspicion.inclusionCriteria.confirmation.user.lastName}
                      </Typography>
                    </>
                  ) : (
                    <Typography className={classes.detailNotConfirmed}>
                      Por confirmar
                    </Typography>
                  )}
                </Grid>
                <Grid container>
                  <Grid item xs={12} align="center">
                    <Divider className={classes.detailDivider} />
                  </Grid>
                </Grid>
                <Grid container justify="flex-start">
                  <Grid item xs={2} align="center">
                    <Link
                      style={{ textDecoration: 'none' }}
                      href={suspicion.inclusionCriteria.vih}
                    >
                      <Button disabled={!suspicion.inclusionCriteria.vih}>
                        <DescriptionIcon
                          className={
                            suspicion.inclusionCriteria.vih
                              ? classes.download
                              : classes.disableDownload
                          }
                        />
                        <Typography>VIH</Typography>
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item xs={2} align="center">
                    <Link
                      style={{ textDecoration: 'none' }}
                      href={suspicion.inclusionCriteria.vhb}
                    >
                      <Button disabled={!suspicion.inclusionCriteria.vhb}>
                        <DescriptionIcon
                          className={
                            suspicion.inclusionCriteria.vhb
                              ? classes.download
                              : classes.disableDownload
                          }
                        />
                        <Typography>VHB</Typography>
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item xs={2} align="center">
                    <Link
                      style={{ textDecoration: 'none' }}
                      href={suspicion.inclusionCriteria.vhc}
                    >
                      <Button disabled={!suspicion.inclusionCriteria.vhc}>
                        <DescriptionIcon
                          className={
                            suspicion.inclusionCriteria.vhc
                              ? classes.download
                              : classes.disableDownload
                          }
                        />
                        <Typography>VHC</Typography>
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item xs={1} className={classes.detailCheckbox} align="center">
                    {suspicion.inclusionCriteria.hasCancer ? (
                      <CheckBoxIcon />
                    ) : (
                      <CheckBoxOutlineBlankIcon />
                    )}
                  </Grid>
                  <Grid item xs={2} align="center">
                    <Typography className={classes.checkboxLabel}>
                      No tiene Cáncer
                    </Typography>
                  </Grid>
                  <Grid item xs={1} className={classes.detailCheckbox} align="center">
                    {suspicion.inclusionCriteria.hasSepsis ? (
                      <CheckBoxIcon />
                    ) : (
                      <CheckBoxOutlineBlankIcon />
                    )}
                  </Grid>
                  <Grid item xs={2} align="center">
                    <Typography className={classes.checkboxLabel}>
                      No tiene Sepsis
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} align="center">
                    <Divider className={classes.detailDivider} />
                  </Grid>
                </Grid>
                <Grid item xs={12} align="left">
                  <Typography className={classes.detailTitle}>
                    Comentarios adicionales
                  </Typography>
                </Grid>
                <Grid item xs={12} align="left" widht="90%">
                  <Typography className={classes.detailData}>
                    {suspicion.inclusionCriteria.comments === ''
                      ? 'Sin comentarios'
                      : suspicion.inclusionCriteria.comments}
                  </Typography>
                </Grid>
                <Grid item xs={12} align="left">
                  <Typography className={classes.disclaimer}>
                    Las imágenes y/o documentos de esta sección se consideran datos sensibles y de carácter confiencial. 
                  </Typography>
                </Grid>
                <Grid container justify="flex-end">
                  <Box>
                    <Can
                      role={user.role}
                      perform={rules.inclusionCriteria.confirm}
                      yes={() =>
                        suspicion.inclusionCriteria &&
                        !suspicion.inclusionCriteria.confirmation.status && (
                          <ConfirmInclusionCriteriaButton
                            inclusionCriteria={suspicion.inclusionCriteria}
                            suspicion={suspicion}
                          >
                            Confirmar
                          </ConfirmInclusionCriteriaButton>
                        )
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          )}
        </ExpansionPanel>
      </div>
    </>
  );
};

export default InclusionCriteriaDetails;
