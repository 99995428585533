import { Paper, Grid } from '@material-ui/core/';
import React, { useMemo } from 'react';
import { TextFilter } from '../../components/table/filters';
import EnhancedTable from '../../components/ui/enhanced-table';
import DeleteUserButton from './delete-medical-center-button';
import ModifyUserButton from './modify-medical-center-button';

const MedicalCentersTable = ({ medicalCenters }) => {
  const data = useMemo(() => medicalCenters, [medicalCenters]);
  const columns = useMemo(
    () => [
      {
        label: 'CENTRO MÉDICO',
        accessor: 'name',
        Filter: TextFilter,
      },
      {
        Header: 'ACCIONES',
        id: 'actions',
        accessor: 'id',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ row, value }) => {
          return (
            <Grid container>
              <Grid item xs={3} align="left">
                <ModifyUserButton medicalCenterInfo={row.original} />
              </Grid>
              <Grid item xs={3} align="right">
                <DeleteUserButton medicalCenterId={value} />
              </Grid>
            </Grid>
          );
        },
      },
    ],
    []
  );

  return (
    <Paper elevation={0}>
      <EnhancedTable data={data} columns={columns} />
    </Paper>
  );
};

export default MedicalCentersTable;
