import React from 'react';
import { useField } from 'formik';
import { FormControl, Select, InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';

const FormSelectField = ({ label, children, name, style, ...props }) => {
  const [fieldProps] = useField(name);

  return (
    <FormControl style={style}>
      <InputLabel id={label}>{label}</InputLabel>
      <Select labelId={label} {...fieldProps} {...props}>
        {children}
      </Select>
    </FormControl>
  );
};

FormSelectField.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
};

export default FormSelectField;
