import React from 'react';
import FullPageSpinner from '../../../components/ui/full-page-spinner';
import { gql } from 'apollo-boost';
import {
  Button,
  DialogActions,
  Grid,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/react-hooks';
import FormTextField from '../../../components/forms/form-text-field';
import { POSTS_QUERY } from '../use-posts';


export const CREATE_POST_MUTATION = gql`
  mutation createPost($createPostInput: createPostInput!) {
    createPost(createPostInput: $createPostInput) {
      id
      organGroupId
      comment
    }
  }
`;

const SurgeryCommentField = ({ organ, type }) => {


  const [createPost, { loading }] = useMutation(
    CREATE_POST_MUTATION,
    {
      refetchQueries: [
        {
          query: POSTS_QUERY,
          variables: {
            getPostsInput: {
              organId: organ.id,
              type: type,
            },
          },
        },
      ],
    }
  );

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (values, { resetForm }) => {
    try {
      await createPost({
        variables: {
          createPostInput: {
            organId: organ.id,
            comment: values.comments,
            type: type,
          },
        },
      });
      enqueueSnackbar('Comentario publicado', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Hubo un error al ingresar su comentario.', { variant: 'error' });
    }
  };


  if (loading) {
    return <FullPageSpinner />;
  }

  return (
    <Formik
    initialValues={{
      comments: '',
    }}
    onSubmit={handleSubmit}
    >
      {({ submitForm }) => (
        <Form>
          <Grid>
            <FormTextField
              name="comments"
              label="Escriba aquí"
              multiline
              fullWidth
              rows={4}
            />
          </Grid>
          <DialogActions>
            <Button
              onClick={submitForm}
              color="secondary"
              type="submit"
              variant="contained"
            >
              Comentar
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default SurgeryCommentField;
