import { fireSentryEvent, setContextTag } from '../../services/sentry';
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Box,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React from 'react';
import ResponsiveDialog from '../../components/ui/responsive-dialog';
import useModal from '../../hooks/useModal';
import Yup from '../../utils/validations';
import { useAllOrganTypes } from '../organs/useAllOrganTypes';
import { useConfirmSuspicion } from './use-confirm-suspicion';

const schema = Yup.object().shape({
  organTypeIds: Yup.array()
    .of(Yup.string())
    .required('Debes seleccionar al menos un órgano')
    .min(1),
});

const ConfirmSuspicionButton = ({ suspicion, children }) => {
  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { allOrganTypes, loading: organTypesLoading } = useAllOrganTypes();
  const [confirmSuspicion, { loading }] = useConfirmSuspicion({
    suspicionId: suspicion.id,
  });

  const handleSubmit = async (values) => {
    closeModal();
    try {
      await confirmSuspicion({ variables: { suspicionId: suspicion.id, ...values } });
      enqueueSnackbar('Sospecha confirmada exitosamente', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Hubo un error al confirmar la sospecha', {
        variant: 'error',
      });
      setContextTag('error al confirmar sospecha', error.message);
      fireSentryEvent(error);
    }
  };

  return (
    <>
      <Button
        label="Confirmar"
        size="small"
        color="primary"
        variant="contained"
        onClick={openModal}
        id="confirm-suspicion-btn"
        disabled={suspicion.confirmation.status}
      >
        {children}
      </Button>
      <Formik
        initialValues={{
          organTypeIds: [],
        }}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, values, errors, setFieldValue, dirty }) => (
          <Form>
            <ResponsiveDialog
              align="center"
              open={isModalOpen}
              onClose={closeModal}
              aria-labelledby="confirm-suspicion-dialog"
            >
              <DialogTitle id="confirm-suspicion-dialog">
                Confirmar a paciente {suspicion.rut} como donante
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Seleccione los órganos aptos para la donación
                </DialogContentText>
                <FormHelperText error>{errors.organTypeIds ?? ''}</FormHelperText>
                <Box display="flex" flexDirection="column">
                  {!organTypesLoading &&
                    allOrganTypes.map((organType) => (
                      <FormControlLabel
                        key={organType.id}
                        control={
                          <Checkbox
                            checked={values.organTypeIds.includes(organType.id)}
                            onChange={(event) => {
                              if (event.target.checked) {
                                setFieldValue('organTypeIds', [
                                  ...values.organTypeIds,
                                  organType.id,
                                ]);
                              } else {
                                setFieldValue(
                                  'organTypeIds',
                                  values.organTypeIds.filter((id) => id !== organType.id)
                                );
                              }
                            }}
                            name={organType.name}
                          />
                        }
                        label={organType.name}
                      />
                    ))}
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeModal} color="secondary">
                  Volver
                </Button>
                <Button
                  onClick={submitForm}
                  disabled={!dirty || loading}
                  color="primary"
                  type="submit"
                >
                  Confirmar
                </Button>
              </DialogActions>
            </ResponsiveDialog>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ConfirmSuspicionButton;
