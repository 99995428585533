import { fireSentryEvent, setContextTag } from '../../services/sentry';
import React from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import { Formik, Form } from 'formik';
import { Button, Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import * as Yup from 'yup';
import { validate } from 'rut.js';

import ResponsiveDialog from '../../components/ui/responsive-dialog';
import { CONFIRMED_SUSPICIONS_QUERY } from './use-confirmed-suspicions';
import { OFFERINGS_QUERY } from '../offerings/use-all-offerings';
import { useAllMedicalCenters } from '../medical-centers/use-all-medical-centers';
import FormSingleAutocomplete from '../../components/forms/form-single-autocomplete';
import FormTextField from '../../components/forms/form-text-field';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '70ch',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  buttonExpanded: {
    color: 'white',
  },
  button: {
    color: theme.palette.primary.main,
  },
  rowgrid: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
  },
  field: {
    padding: theme.spacing(1),
    width: '35ch',
  },
}));

export const CREATE_ALLOCATION_MUTATION = gql`
  mutation CreateOfferingMutation($offeringInput: OfferingInput!) {
    createOffering(offeringInput: $offeringInput) {
      id
    }
  }
`;

const crossRequirementMessage = 'Este campo es requerido si tienes prioridades menores';

const validationSchema = Yup.object({
  medicalCenter1: Yup.string().required(),
  medicalCenter2: Yup.string().when('medicalCenter3', {
    is: (medicalCenter) => !!medicalCenter,
    then: Yup.string().required(crossRequirementMessage),
  }),
  medicalCenter3: Yup.string().when('medicalCenter4', {
    is: (medicalCenter) => !!medicalCenter,
    then: Yup.string().required(crossRequirementMessage),
  }),
  medicalCenter4: Yup.string().when('medicalCenter5', {
    is: (medicalCenter) => !!medicalCenter,
    then: Yup.string().required(crossRequirementMessage),
  }),
  medicalCenter5: Yup.string(),
  document1: Yup.string().test('rut-format', 'Rut es inválido.', validate),
});

const CreateAllocationDialog = ({ organ, open, onClose }) => {
  const [allocateOrgan, { loading }] = useMutation(CREATE_ALLOCATION_MUTATION, {
    refetchQueries: [
      { query: CONFIRMED_SUSPICIONS_QUERY },
      { query: OFFERINGS_QUERY },
      { query: OFFERINGS_QUERY, variables: { expired: true } },
    ],
  });
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { allMedicalCenters } = useAllMedicalCenters();

  const handleSubmit = async (values) => {
    try {
      const preMedicalCenterIds = [
        values.medicalCenter1,
        values.medicalCenter2,
        values.medicalCenter3,
        values.medicalCenter4,
        values.medicalCenter5,
      ];
      const preDocumentIds = [
        values.document1,
        values.document2,
        values.document3,
        values.document4,
        values.document5,
      ];
      await allocateOrgan({
        variables: {
          offeringInput: {
            organId: organ.id,
            medicalCenterIds: preMedicalCenterIds.filter((id) => !!id),
            // TODO: CREAR OFERTA CON EL OfferingInput CORRECTAMENTE, ESPERAR SOLUCION ERROR 5 BACKEND
            medicalCenterId: organ.originMedicalCenter.id,
            recieverDocumentIds: preDocumentIds.filter((rut) => !!rut),
          },
        },
      });
      enqueueSnackbar('Órgano ofrecido correctamente', {
        variant: 'success',
      });
      onClose();
    } catch (error) {
      enqueueSnackbar('Hubo un error al ofrecer el órgano', {
        variant: 'error',
      });
      setContextTag('error al ofrecer organo', error.message);
      fireSentryEvent(error);
    }
    
  };

  return (
    <>
      <ResponsiveDialog
        align="center"
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Formik
          initialValues={{
            medicalCenter1: undefined,
            medicalCenter2: undefined,
            medicalCenter3: undefined,
            medicalCenter4: undefined,
            medicalCenter5: undefined,
            document1: undefined,
            document2: undefined,
            document3: undefined,
            document4: undefined,
            document5: undefined,
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ submitForm }) => (
            <Container component="main" maxWidth="xs">
              <div className={classes.paper}>
                <DialogTitle id="responsive-dialog-title">
                  Crear oferta de {organ.organType.name}
                </DialogTitle>
                <Form className={classes.form}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box className={classes.rowgrid}>
                        <FormSingleAutocomplete
                          className={classes.field}
                          name="medicalCenter1"
                          label="Primera prioridad"
                          options={allMedicalCenters}
                          getOptionLabel={(option) => option.name}
                          renderOption={(option) => option.name}
                          getValueFromOption={(option) => option.id}
                        />
                        <FormTextField
                          className={classes.field}
                          label="RUT receptor"
                          required
                          name="document1"
                          fullWidth
                          style={{ flexBasis: '60%' }}
                        />
                      </Box>

                      <br />
                      <Box className={classes.rowgrid}>
                        <FormSingleAutocomplete
                          className={classes.field}
                          name="medicalCenter2"
                          label="Segunda prioridad"
                          options={allMedicalCenters}
                          getOptionLabel={(option) => option.name}
                          renderOption={(option) => option.name}
                          getValueFromOption={(option) => option.id}
                        />
                        <FormTextField
                          className={classes.field}
                          label="RUT receptor"
                          required
                          name="document2"
                          style={{ flexBasis: '60%' }}
                          fullWidth
                        />
                      </Box>

                      <br />
                      <Box className={classes.rowgrid}>
                        <FormSingleAutocomplete
                          className={classes.field}
                          name="medicalCenter3"
                          label="Tercera prioridad"
                          options={allMedicalCenters}
                          getOptionLabel={(option) => option.name}
                          renderOption={(option) => option.name}
                          getValueFromOption={(option) => option.id}
                        />
                        <FormTextField
                          className={classes.field}
                          label="RUT receptor"
                          required
                          name="document3"
                          style={{ flexBasis: '60%' }}
                          fullWidth
                        />
                      </Box>

                      <br />
                      <Box className={classes.rowgrid}>
                        <FormSingleAutocomplete
                          className={classes.field}
                          name="medicalCenter4"
                          label="Cuarta prioridad"
                          options={allMedicalCenters}
                          getOptionLabel={(option) => option.name}
                          renderOption={(option) => option.name}
                          getValueFromOption={(option) => option.id}
                        />
                        <FormTextField
                          className={classes.field}
                          label="RUT receptor"
                          required
                          name="document4"
                          style={{ flexBasis: '60%' }}
                          fullWidth
                        />
                      </Box>

                      <br />
                      <Box className={classes.rowgrid}>
                        <FormSingleAutocomplete
                          className={classes.field}
                          name="medicalCenter5"
                          label="Quinta prioridad"
                          options={allMedicalCenters}
                          getOptionLabel={(option) => option.name}
                          renderOption={(option) => option.name}
                          getValueFromOption={(option) => option.id}
                        />
                        <FormTextField
                          className={classes.field}
                          label="RUT receptor"
                          required
                          name="document5"
                          style={{ flexBasis: '60%' }}
                          fullWidth
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              </div>
              <DialogActions>
                <Button onClick={onClose} color="secondary">
                  Cancelar
                </Button>
                <Button
                  data-testid="enter-medical-record-button"
                  onClick={submitForm}
                  disabled={loading}
                  color="primary"
                  type="submit"
                  variant="contained"
                >
                  Alocar
                </Button>
              </DialogActions>
            </Container>
          )}
        </Formik>
      </ResponsiveDialog>
    </>
  );
};

export default CreateAllocationDialog;
