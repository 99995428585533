import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const USERS_LIST_QUERY = gql`
  query {
    getUsers {
      id
      firstName
      lastName
      email
      role
      medicalCenters{
        id
      }
    }
  }
`;

function useAllUsers() {
  const { data, loading, error } = useQuery(USERS_LIST_QUERY);

  const users = data?.getUsers;
  return { users, loading, error };
}

export { USERS_LIST_QUERY, useAllUsers };
