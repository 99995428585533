import { fireSentryEvent, setContextTag } from '../../../services/sentry';
import React, { useState } from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import {
  Tooltip,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  makeStyles,
  Box,
  Typography,
  Grid,
  Paper,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import ResponsiveDialog from '../../../components/ui/responsive-dialog';
import useModal from '../../../hooks/useModal';
import FullPageSpinner from '../../../components/ui/full-page-spinner';
import { TimePicker } from "@material-ui/pickers";
import { SURGERY_QUERY } from '../use-surgery';

export const FINISH_SURGERY_MUTATION = gql`
  mutation finishSurgery($surgeryId: ID!, $time: Date!) {
    finishSurgery(surgeryId: $surgeryId, time: $time) {
      id
      status
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  confirm: {
    gridArea: 'confirm',
    justifySelf: 'right'
  },
  columnroot: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column'
  },
  rowroot: {
    padding: theme.spacing(1),
    display: 'flex',
    flexwrap: 'nowrap',
    flexDirection: 'row',
    width: '100%',
  },
  inputHora: {
    display: 'flex',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: '60px',
    marginTop: '60px',
    maxWidth: '500px',
    width: '100%',
    maxHeight: '68px',
    height: '100%',
  },
  hourPaper: {
    paddingInline: '3px',
    paddingBlock: '3px',
  },
  dateDetail: {
    alignSelf: 'right',
    justifySelf: 'right',
    color: 'gray',
    fontSize: '12px',
  },
  margin: {
    marginTop: '60px',
    marginBottom: '20px',
  }
}));

const DatetimeInputDialog = ({ surgery, next_status, SURGERY_TIME_MUTATION, variables, messages, step_status, display_datetime, handleStep, completed, activeStep, currentOrganStatus=null, setCompleted=null, currentOrgan=null, setOrgan=null }) => {
  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  const dateFormat = require('dateformat');
  let init = true;
  let finalSchedule = display_datetime;

  if (init) {
    if (!finalSchedule) {
      finalSchedule = new Date()

    } else {
      finalSchedule = new Date(finalSchedule)
    }
    init = false
  }

  const [selectedDate, handleDateChange] = useState(finalSchedule);

  const [surgeryTimeMutation, { loading }] = useMutation(
    SURGERY_TIME_MUTATION,
    {
      refetchQueries: [
        {
          query: SURGERY_QUERY,
          variables: {
            surgeryId: surgery.id,
          },
        },
      ],
    }
  );
  const [finishSurgery, { loading1 }] = useMutation(
    FINISH_SURGERY_MUTATION,
    {
      refetchQueries: [
        {
          query: SURGERY_QUERY,
          variables: {
            surgeryId: surgery.id,
          },
        },
      ],
    }
  );

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleFinishedSurgery = async () => {
    try {
      await finishSurgery({
        variables: {
          surgeryId: surgery.id,
          time: finalSchedule,
        },
      });
      enqueueSnackbar('Cirugía finalizada', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Hubo un error al finalizar la cirugía.', { variant: 'error' });
      setContextTag('error al ingresar hora', error.message);
      fireSentryEvent(error);
    }
  };

  const handleSubmit = async () => {
    if (currentOrganStatus){
      handleStep(currentOrganStatus)
    } else{
      handleStep()
    };
    try {
      await surgeryTimeMutation({
        variables: 
          step_status == "enIsquemia" ? {
            organId: variables.id,
            time: finalSchedule,
        } : {
          surgeryId: variables.id,
          time: finalSchedule,
        }
      });
      closeModal()
      if (currentOrgan){
        setOrgan(currentOrgan)
      }
      enqueueSnackbar(messages.success, {
        variant: 'success',
      });
      if (step_status == "enIsquemia"){
        const allOrgansExtracted = surgery.organs.map((organ) => organ.status === 'Extraido');
        let count_false = 0
        let count_true = 0
        allOrgansExtracted.forEach(is_organ_extracted => {
          if (is_organ_extracted){
            count_true += 1
          } else{
            count_false += 1
          }
        });
        if (count_false == 1){
          handleFinishedSurgery()
          setCompleted({0: true, 1: true, 2: true, 3: true});
        }
        
      };
      
    } catch (error) {
      enqueueSnackbar(`Hubo un error al ${messages.time_description}.`, { variant: 'error' });
      setContextTag('error al ingresar hora', error.message);
      fireSentryEvent(error);
    }
  };

  if (loading || loading1) {
    return <FullPageSpinner />;
  }
  return (
    <>
      {!next_status.includes(surgery.status) && !display_datetime ?
      <>
      <Grid className={classes.margin}>
      </Grid>
      <Paper className={classes.inputHora} elevation={4}>
        <Box className={classes.rowroot} alignItems='center' justifyContent="space-between">
          <Typography variant="subtitle1">Hora de {messages.time_description}</Typography>
          <DialogActions>
            <Tooltip title={messages.tooltip}>
              <Button
                disabled={(completed[activeStep] && activeStep == 1 ) || surgery.status !== step_status || currentOrganStatus == 'Extraido'}
                color="primary"
                type="submit"
                variant="contained"
                onClick={openModal}
                id="input-datetime"
              >
                Ingresar
              </Button>
            </Tooltip>
          </DialogActions>
        </Box>
      </Paper>
      <ResponsiveDialog
        align="center"
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          ¿Seguro que quieres seleccionar la hora actual como la hora de {messages.time_description}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TimePicker
              label="Salida"
              variant="inline"
              placeholder="08:00 AM"
              mask="__:__ _M"
              value={selectedDate}
              onChange={date => handleDateChange(date)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="secondary">
            Cancelar
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={loading}
            color="primary"
            variant="contained"
            type="submit"
          >
            Confirmar
          </Button>
        </DialogActions>
      </ResponsiveDialog>
      </>
      :
      <>
        <Paper className={classes.inputHora} elevation={4}>
          <Box className={classes.rowroot} alignItems='center' justifyContent="space-between">
            <Typography variant="subtitle1">Hora de {messages.time_description}</Typography>
            <DialogActions>
              <Grid className={classes.columnroot} alignItems='right'>
                <Typography className={classes.dateDetail}>
                  {dateFormat(display_datetime, 'dd-mm-yyyy')}
                </Typography>
                <Tooltip title="Hora de Chile">
                  <Typography variant="h6">
                    {dateFormat(display_datetime, 'hh:MM TT')}
                  </Typography>
                </Tooltip>
              </Grid>
            </DialogActions>
          </Box>
        </Paper>
      </>
    }
    </>
  );
};

export default DatetimeInputDialog;
