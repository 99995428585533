import React, { useState } from 'react';
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  Paper,
  withStyles,
  Tab,
  Tabs,
} from '@material-ui/core';
import { Redirect, useParams } from 'react-router-dom';

import FullPageSpinner from '../components/ui/full-page-spinner';
import { useCurrentUser } from '../features/authentication/use-current-user';
import { useSurgery } from '../features/surgeries/use-surgery';
import { useProcureSchedule } from '../features/surgeries/use-procure-schedule';
import Can from '../utils/can';
import { rules } from '../utils/rbac-rules';
import SurgeryBar from '../features/surgeries/surgery-bar';
import OrgansPanel from '../features/surgeries/organs-panel';

import SurgeryMap from '../features/surgeries/surgery-map';

import SurgeryCoordinationTab from '../features/surgeries/surgery-coordination-tab';

import SurgeryExtractionTab from '../features/surgeries/surgery-extraction-tab';

import SurgeryTransplantTab from '../features/surgeries/surgery-transplant-tab';
import { useMyTeams } from '../features/teams/use-my-teams';

const gridSpacing = 3;

const StyledTab = withStyles((theme) => ({
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
}))(Tab);

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'min-content min-content 1fr',
    gridGap: theme.spacing(3),
    gridTemplateAreas: `
      "title"
      "surgery"
      "panels"
    `,
  },
  surgery: { gridArea: 'surgery' },
  panels: { gridArea: 'panels' },
  title: {
    gridArea: 'title',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  surgeryInfo: { paddingRight: theme.spacing(3) },
  crossClampButton: {
    marginTop: theme.spacing(5),
  },
  paper: {
    // top and bottom 24px padding + toolbar height + grid spacing + tabHeight
    // height: `calc(100vh - ${theme.spacing(3 * 2 + 8 + gridSpacing + 4 + 6)}px)`,
    // overflowY: 'auto',
    // overflowX: 'hidden',
    padding: theme.spacing(3),
  },
}));

const SurgeryDetailsPage = () => {
  const { id: surgeryId } = useParams();
  const classes = useStyles();
  const { surgery, loading } = useSurgery(surgeryId);
  const { user } = useCurrentUser();
  const [tab, setTab] = useState(0);
  const { userGroups, loading: loadingUserGroups } = useMyTeams();
  const { procureSchedule, loading: loadingProcureSchedule } = useProcureSchedule(
    surgeryId
  );

  if (loading || loadingUserGroups || loadingProcureSchedule) return <FullPageSpinner />;

  return (
    <Can
      role={user.role}
      perform={rules.surgery.get}
      no={() => <Redirect to="/surgeries/" />}
      yes={() => (
        <Box className={classes.main}>
          <Typography className={classes.title}>Cirugía en curso</Typography>
          <Box className={classes.surgery}>
            <SurgeryBar surgery={surgery} />
          </Box>

          <Grid
            className={classes.root}
            container
            justify="flex-start"
            spacing={gridSpacing}
          >
            <Grid style={{ height: '100%' }} item lg={12} xl={10}>
              <Tabs value={tab}>
                <StyledTab onClick={() => setTab(0)} label="Información General" />
                <StyledTab onClick={() => setTab(1)} label="Coordinación" />
                <StyledTab onClick={() => setTab(2)} label="Extracción" />
                <StyledTab onClick={() => setTab(3)} label="Traslado" />
                <StyledTab onClick={() => setTab(4)} label="Trasplante" />
              </Tabs>
              <Paper className={classes.paper} elevation={3}>
                {tab === 0 &&
                  <OrgansPanel
                    surgery={surgery}
                    procureSchedule={procureSchedule}
                  />}
                {tab === 1 && (
                  <SurgeryCoordinationTab
                    surgery={surgery}
                    userGroups={userGroups}
                    procureSchedule={procureSchedule}
                    chatId={surgery.chat.id}
                  />)}
                {tab === 2 &&
                  <SurgeryExtractionTab
                    surgery={surgery}
                    user={user}
                  />}
                {tab === 3 &&
                  <SurgeryMap
                    surgery={surgery}
                  />}
                {tab === 4 &&
                  <SurgeryTransplantTab
                    surgery={surgery}
                  />}
              </Paper>
            </Grid>
          </Grid>
        </Box>
      )}
    />
  );
};

export default SurgeryDetailsPage;
