import React from 'react';

import FullPageSpinner from '../../../components/ui/full-page-spinner';
import {
  makeStyles,
  Typography,
  Grid,
  Paper,
  Divider,
} from '@material-ui/core';
import { usePosts } from '../use-posts';

const useStyles = makeStyles((theme) => ({
  margin: {
    marginLeft: '30px',
  },
  title: {
    padding: theme.spacing(1),
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  detailTitle: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  detailData: {
    paddingLeft: theme.spacing(3),
    color: 'gray',
  },
  detailDataTime: {
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    color: 'gray',
    fontSize: '12px',
  },
}));

const SurgeryComments= ({ organ, type }) => {
  const {posts, loading } = usePosts(organ.id, type)

  const classes = useStyles();

  if (loading) {
    return <FullPageSpinner />;
  }

  return (
    <Paper elevation={3} className={classes.margin}>
      <Grid item xs={6} align="left">
        <Typography className={classes.title}>Comentarios</Typography>
      </Grid>
      {posts.length > 0 ? posts.map((donorPost) => (
        <Grid key={donorPost.createdAt}>
          <Grid container>
            <Grid item xs={9} align="left">
              <Typography className={classes.detailTitle}>{donorPost.comment}</Typography>
            </Grid>
            <Grid item xs={3} align="right">
              <Typography className={classes.detailDataTime}>
                {new Date(donorPost.createdAt).toLocaleString()}
              </Typography>
            </Grid>
          </Grid>
          <Typography className={classes.detailData}>
            {donorPost.user.firstName} {donorPost.user.lastName}
          </Typography>
          <br />
          <Divider variant="middle" />
        </Grid>
      )) : <Typography className={classes.detailData}>No hay comentarios</Typography>}
    </Paper>    
  );
};

export default SurgeryComments;
