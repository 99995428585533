import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

export const PROCURE_SCHEDULE_QUERY = gql`
  query getProcureSchedulesBySurgery($surgeryId: ID!){
    getProcureSchedulesBySurgery(surgeryId: $surgeryId){
      id
      startAt
      endAt
      scheduledAt
      organGroupId
      surgery {
        id
        createdAt
      }
    }
  }
`;

export const useProcureSchedule = (surgeryId) => {
  const { data, loading, error } = useQuery(PROCURE_SCHEDULE_QUERY, {
    variables: { surgeryId },
  });
  return { procureSchedule: data?.getProcureSchedulesBySurgery, loading, error };
};
