import { fireSentryEvent, setContextTag } from '../../../services/sentry';

import React, { useState } from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import {
  Tooltip,
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import ResponsiveDialog from '../../../components/ui/responsive-dialog';
import useModal from '../../../hooks/useModal';
import { TimePicker } from "@material-ui/pickers";
import { PROCURE_SCHEDULE_QUERY } from '../use-procure-schedule';

export const SET_PROCURE_SCHEDULE_MUTATION = gql`
mutation setProcureSchedule(
    $setProcureScheduleInput: setProcureScheduleInput!
  ) {
    setProcureSchedule(
        setProcureScheduleInput: $setProcureScheduleInput
    ) {
      id
      surgeryId
    }
  }
`;

const ConfirmTeamSchedule = ({ schedule, surgery }) => {

  let init = true;
  let finalSchedule = schedule.scheduledAt;
  let surgeryId = surgery.id

  if (init) {
    if (!finalSchedule) {
      finalSchedule = new Date()

    } else {
      finalSchedule = new Date(finalSchedule)
    }
    init = false
  }

  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  const [selectedDate, handleDateChange] = useState(finalSchedule);

  const { enqueueSnackbar } = useSnackbar();

  const [setProcureSchedule, { loading }] = useMutation(SET_PROCURE_SCHEDULE_MUTATION
    , {
      refetchQueries: [
        {
          query: PROCURE_SCHEDULE_QUERY,
          variables: {
            surgeryId,
          },
        },
      ],
    });

  const handleSubmit = async () => {

    closeModal();


    let SetProcureScheduleInput = {
      id: schedule.id,
      scheduledAt: selectedDate,
    }

    try {
      await setProcureSchedule({
        variables: { setProcureScheduleInput: SetProcureScheduleInput },
      });
      enqueueSnackbar('Se ha agendado la salida correctamente', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Hubo un error al agendar la salida del equipo', {
        variant: 'error',
      });
      setContextTag('Error al agendar la salida del equipo', error.message);
      fireSentryEvent(error);
      console.log(error)
    }
  };

  return (
    <>
      <Tooltip title="Confirmar hora de salida de este equipo">
        <Button
          aria-label="Confirmar hora de salida"
          size='small'
          variant="contained"
          color="secondary"
          onClick={openModal}
          id="confirm-team-schedule"
        >
          Confirmar hora de salida
        </Button>
      </Tooltip>
      <ResponsiveDialog
        align="center"
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Selecciona una hora de salida para éste grupo
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TimePicker
              label="Salida"
              variant="inline"
              placeholder="08:00 AM"
              mask="__:__ _M"
              value={selectedDate}
              onChange={date => handleDateChange(date)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="secondary">
            Cancelar
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={loading}
            color="primary"
            variant="contained"
            type="submit"
          >
            Confirmar
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    </>
  );
};

export default ConfirmTeamSchedule;
