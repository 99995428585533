import { fireSentryEvent, setContextTag } from '../../../services/sentry';
import 'react-chat-elements/dist/main.css';
import { gql } from 'apollo-boost';
import { useMutation, useSubscription } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import React, { useState, useEffect } from 'react';
import { Paper, Grid, makeStyles, Typography, TextField } from '@material-ui/core';
import { useGetMessages } from './use-get-messages';
import { useCurrentUser } from '../../authentication/use-current-user';
import ScrollToBottom from 'react-scroll-to-bottom';




const useStyles = makeStyles((theme) => ({
  title: {
    padding: theme.spacing(1),
  },
  rowroot: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  navBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    textAlign: 'center',
    height: "45px",
  },
  navBarTitle: {
    color: "white",
  },
  paper: {
    width: '400px',
    minHeight: '500px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: '#1f223c22'
  },
  input: {
    padding: '10px',
    backgroundColor: 'white',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: "9px",
    backgroundColor: 'white',
  },
  messageContainer: {
    padding: '0px 10px',
  },
  messagesContainer: {
    maxHeight: '500px',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  message: {
  },
  inputButton: {
    marginLeft: '5px',
    backgroundColor: "white",
    color: "#3B97F5",
  },
  sentDetails: {
    display: "flex",
    justifyContent: "space-between",
    color: "white",
  },
  receivedDetails: {
    display: "flex",
    justifyContent: "space-between",
    color: "gray",
  },
  sentBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  receivedBox: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  sentBlob: {
    minWidth: "100px",
    maxWidth: "330px",
    backgroundColor: "#3b97f5",
    borderRadius: "8px",
    paddingInline: "8px",
    paddingBottom: "0px",
    paddingTop: "4px",
    marginBottom: "5px",
    wordWrap: "break-word",
  },
  receivedBlob: {
    minWidth: "250px",
    maxWidth: "330px",
    backgroundColor: "white",
    borderRadius: "8px",
    paddingInline: "8px",
    paddingBottom: "0px",
    paddingTop: "4px",
    marginBottom: "5px",
    wordWrap: "break-word",

  },
  sentContent: {
    color: "white",
    fontSize: "16px",

  },
  receivedContent: {
    color: "black",
    fontSize: "16px",
  },
  sentUser: {
    display: "none",
    fontSize: "8px"
  },
  receivedUser: {
    fontSize: "12px",
  },
  time: {
    fontSize: "12px",
  },
  dateChangeText: {
    color: "gray",
    fontSize: "15px",
  },
  dateChange: {
    display: "flex",
    justifyContent: "center",
    paddingBlock: "15px",
  },
}));


// const GET_MESSAGES_QUERY = gql`
//   query GetMessagesQuery($chatId: ID!) {
//     getMessages(chatId: $chatId) {
//       id
//       senderId
//       content
//       chatId
//       createdAt
//       updatedAt
//     }
//   }
// `;

export const CREATE_MESSAGE_MUTATION = gql`
  mutation createMessage($input: createMessageInput!) {
    createMessage(createMessageInput: $input) {
      id
      chatId
      sender {
        id
        firstName
        lastName
        medicalCenters {
          id
          name
        }
      }
      content
      createdAt
      updatedAt
    }
  }
`;

export const NEW_CHAT_MESSAGE_SUBSCRIPTION = gql`
  subscription newChatMessage($chatId: Int!) {
    newChatMessage(chatId: $chatId) {
      id
      sender {
        id
        firstName
        lastName
        medicalCenters {
          id
          name
        }
      }
      content
      chatId
      createdAt
      updatedAt
    }
  }
`;



function formatDate(newDate) {
  const t = new Date(newDate);
  const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
  const date = t.getDate();
  const month = t.getMonth();
  // const date = ('0' + t.getDate()).slice(-2);
  // const month = ('0' + (t.getMonth() + 1)).slice(-2);
  const year = t.getFullYear();
  // const hours = ('0' + t.getHours()).slice(-2);
  // const minutes = ('0' + t.getMinutes()).slice(-2);
  // const seconds = ('0' + t.getSeconds()).slice(-2);
  // const time = `${date}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
  const formattedDate = `${date} de ${months[month].toLowerCase()} del ${year}`;
  return formattedDate;
}

function formatAMPM(inputDate) {
  var date = new Date(inputDate);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

function getDay(inputDate) {
  var date = new Date(inputDate);
  return date.getDate();
}


const SurgeryChat = (props) => {
  const { user } = useCurrentUser();
  const { chatId } = props;
  const { messages: allMessages, loading: allMessagesLoading } = useGetMessages({ chatId });
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [createMessage] = useMutation(CREATE_MESSAGE_MUTATION);

  const { data: subscriptionData, loading: subscriptionLoading, error: subscriptionError } = useSubscription(NEW_CHAT_MESSAGE_SUBSCRIPTION, {
    variables: { chatId: parseInt(chatId) },
  });
  const classes = useStyles();




  const handleRecieve = (data) => {
    if (!subscriptionLoading) {
      appendMessage(data.newChatMessage);
    }
  };

  useEffect(() => {
    handleRecieve(subscriptionData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionData]);

  useEffect(() => {
  }, [subscriptionError]);

  const appendMessage = (newMessagedata) => {
    let updatedMessages = [...messages];
    let newMessage = {};
    newMessage.sender = newMessagedata.sender;
    newMessage.content = newMessagedata.content;
    newMessage.createdAt = newMessagedata.createdAt;
    newMessage.updatedAt = newMessagedata.updatedAt;
    newMessage.chatId = chatId;
    updatedMessages.push(newMessage)
    setMessages(updatedMessages);
  };

  const handleSend = async (text) => {
    if (text === '') { return }
    try {
      const input = {
        content: text,
        chatId,
      };
      await createMessage({
        variables: { input },
      });
      // appendMessage(data.createMessage);
      setInputValue('');
    } catch (error) {
      setContextTag('error al enviar mensaje', error.message);
      fireSentryEvent(error);
    }
  };


  useEffect(() => {
    if (!allMessagesLoading) {
      // formattedMessages.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
      // setMessages(formattedMessages);
      const messagesCopy = [...allMessages];
      messagesCopy.sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
      });
      setMessages(messagesCopy);

    }
  }, [allMessages, allMessagesLoading]);


  if (allMessagesLoading) {
    return <p>Cargando...</p>;
  }

  return (
    <>
      <Grid className={classes.rowroot}>
        <Paper className={classes.paper} elevation={12}>
          <div className={classes.contentContainer}>
            <div className={classes.navBar} >
              <Typography className={classes.navBarTitle} variant="subtitle1">Chat de Coordinación</Typography>
            </div>
            <ScrollToBottom className={classes.messagesContainer}>
              {messages.map((message, index) => (
                <>
                  {index === 0 &&
                    <div key={index} className={classes.dateChange}>
                      <Typography className={classes.dateChangeText}>{formatDate(message.createdAt)}</Typography>
                    </div>
                  }
                  {index > 0 && (getDay(messages[index].createdAt) > getDay(messages[index - 1].createdAt)) ?
                    <div key={index} className={classes.dateChange}>
                      <Typography className={classes.dateChangeText}>{formatDate(message.createdAt)}</Typography>
                    </div>
                    : null}
                  <div key={message.id} className={classes.messageContainer}>
                    <div className={user.id === message.sender.id ? classes.sentBox : classes.receivedBox}>
                      <div className={user.id === message.sender.id ? classes.sentBlob : classes.receivedBlob}>
                        <Typography className={user.id === message.sender.id ? classes.sentContent : classes.receivedContent} >{message.content}</Typography>
                        <div className={user.id === message.sender.id ? classes.sentDetails : classes.receivedDetails}>
                          <Typography className={user.id === message.sender.id ? classes.sentUser : classes.receivedUser}>
                            {user.id === message.sender.id ? "" : "~ "}{message.sender.firstName.charAt(0) + ". " + message.sender.lastName}
                            {" - " + message.sender.medicalCenters[0].name}
                          </Typography>
                          <div></div>
                          <Typography className={classes.time}>{formatAMPM(message.createdAt)}</Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </ScrollToBottom>
          </div>
          <div className={classes.inputContainer}>
            <TextField
              id="filled-textarea"
              placeholder="Escribe aquí..."
              multiline
              fullWidth
              InputProps={{ disableUnderline: true }}
              // variant="outlined"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
            <Button
              // startIcon={<CheckCircleIcon />}
              className={classes.inputButton}
              onClick={() => handleSend(inputValue)}
              variant="contained"
            >
              Enviar
            </Button>
          </div>
        </Paper>
      </Grid>
    </>
  );
};
export default SurgeryChat;
