import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { SelectFilter, TextFilter } from '../../components/table/filters';
import EnhancedTable from '../../components/ui/enhanced-table';
import { changeDateFormatShort } from '../../utils/date-format';

const SuspicionsTable = ({ suspicions }) => {
  const data = useMemo(() => suspicions, [suspicions]);

  const columns = useMemo(
    () => [
      {
        label: 'RUT',
        accessor: 'rut',
        disableSortBy: true,
        Filter: TextFilter,
      },
      {
        label: 'ESTADO',
        accessor: 'status',
        Filter: SelectFilter,
        disableSortBy: true,
      },
      {
        Header: 'ÚLTIMA ACTUALIZACIÓN',
        accessor: 'updatedAt',
        disableFilters: true,
        disableSortBy: false,
        Cell: ({ value }) => changeDateFormatShort(value),
      },
      {
        label: 'ESTABLECIMIENTO',
        accessor: 'medicalCenter.name',
        disableSortBy: true,
        Filter: TextFilter,
      },
    ],
    []
  );

  const initialState = useMemo(
    () => ({
      sortBy: [
        {
          id: 'updatedAt',
          desc: true,
        },
      ],
    }),
    []
  );

  const history = useHistory();

  return (
    <EnhancedTable
      data={data}
      columns={columns}
      initialState={initialState}
      clickableRow
      onClickRow={(row) => history.push(`/suspicions/${row.original.id}`)}
    />
  );
};

export default SuspicionsTable;
