import React from 'react';
import { Button, DialogTitle, DialogActions } from '@material-ui/core';
import ResponsiveDialog from '../../components/ui/responsive-dialog';
import useModal from '../../hooks/useModal';
import SurgeryMap from './surgery-map';

const ViewRouteButton = ({ organ }) => {
  const { close, open, isModalOpen } = useModal();

  return (
    <>
      <Button onClick={open} color="primary" size="small" data-testid="view-route-button">
        Ver ruta
      </Button>
      <ResponsiveDialog
        align="left"
        open={isModalOpen}
        onClose={close}
        aria-labelledby="view-route-dialog"
        maxWidth="lg"
      >
        <DialogTitle id="view-route-dialog">
          Ruta de {organ.organType.name.toLowerCase()}
        </DialogTitle>
        <SurgeryMap />
        <DialogActions>
          <Button onClick={close} color="secondary">
            Volver
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    </>
  );
};

export default ViewRouteButton;
