import React from 'react';
import { Paper, Typography, Box, makeStyles } from '@material-ui/core';
import { addHours } from 'date-fns';
import clsx from 'clsx';
import { changeDateFormat } from '../../utils/date-format';

import AcceptOfferingButton from './accept-offering-button';
import DismissOfferingButton from './dismiss-offering-button';
import useCountdown from '../../hooks/useCountdown';
import { formatCountdown } from '../../utils/date-format';
import { offeringStatus } from '../../constants/status';
import { useCan } from '../../utils/can';
import { rules } from '../../utils/rbac-rules';
import MoreInfoDonorButton from './more-info-donor-button';

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(2), display: 'flex', flexDirection: 'column' },
  subtitle: { color: theme.palette.text.hint },
  timerSubtitle: {
    color: theme.palette.text.hint,
    marginTop: 0,
    lineHeight: 1,
  },
  footer: { display: 'flex', alignItems: 'center', minHeight: theme.spacing(5) },
  link: { flexGrow: 1 },
  dismissed: { color: theme.palette.error.main },
  accepted: { color: theme.palette.success.main },
  date: { color: theme.palette.text.hint },
}));

const ActiveOfferingItem = ({ offering }) => {
  const classes = useStyles();
  const canManage = useCan({ perform: rules.offerings.manage });
  const { timeLeft } = useCountdown({
    targetDate: offering.expired ? new Date() : addHours(new Date(offering.createdAt), 1),
  });

  return (
    <Paper elevation={1} className={classes.root}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h6">{offering.organ.organType.name}</Typography>
        <Typography variant="caption" className={classes.date}>
          {changeDateFormat(offering.createdAt)}
        </Typography>
      </Box>
      <Typography variant="caption" className={classes.subtitle}>
        {offering.priority}° prioridad
      </Typography>
      {canManage && (
        <Typography variant="overline">
          Receptor: { offering.recieverDocumentId }
        </Typography>
      )}
      <Typography variant="h2">{formatCountdown(timeLeft)}</Typography>
      <Typography variant="overline" className={classes.timerSubtitle}>
        Tiempo restante
      </Typography>
      <Box className={classes.footer}>
        <MoreInfoDonorButton offering={offering} />

        {offering.status === offeringStatus.pending ? (
          <>
            <DismissOfferingButton
              offering={offering}
              disabled={timeLeft < 1000}
              align="right"
            />
            <AcceptOfferingButton
              offering={offering}
              disabled={timeLeft < 1000}
              align="right"
            />
          </>
        ) : (
          <Typography
            variant="overline"
            className={clsx({
              [classes.dismissed]: offering.status === offeringStatus.dismissed,
              [classes.accepted]: offering.status === offeringStatus.accepted,
            })}
          >
            {offering.status}
          </Typography>
        )}
      </Box>
    </Paper>
  );
};

export default ActiveOfferingItem;
