import React, { useState } from 'react';
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  Paper,
  withStyles,
  Tab,
  Tabs,
} from '@material-ui/core';
import { Redirect, useParams } from 'react-router-dom';

import FullPageSpinner from '../../components/ui/full-page-spinner';
import { useCurrentUser } from '../../features/authentication/use-current-user';
import Can from '../../utils/can';
import { rules } from '../../utils/rbac-rules';

import SuspicionTab from './suspicion-tab';
import OfferingsAndProcureTab from './offerings-procure-tab';
import TimingStatsTab from './timing-stats-tab';

import { useAllMedicalCenters } from '../../features/medical-centers/use-all-medical-centers';


const gridSpacing = 3;

const StyledTab = withStyles((theme) => ({
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
}))(Tab);

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'min-content min-content 1fr',
    gridGap: theme.spacing(3),
    gridTemplateAreas: `
      "title"
      "surgery"
      "panels"
    `,
  },
  surgery: { gridArea: 'surgery' },
  panels: { gridArea: 'panels' },
  title: {
    gridArea: 'title',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  surgeryInfo: { paddingRight: theme.spacing(3) },
  crossClampButton: {
    marginTop: theme.spacing(5),
  },
  paper: {
    // top and bottom 24px padding + toolbar height + grid spacing + tabHeight
    // height: `calc(100vh - ${theme.spacing(3 * 2 + 8 + gridSpacing + 4 + 6)}px)`,
    // overflowY: 'auto',
    // overflowX: 'hidden',
    padding: theme.spacing(3),
  },
}));

const DashboardMainComponent = () => {

  const classes = useStyles();
  const { user } = useCurrentUser();
  const { allMedicalCenters, loading } = useAllMedicalCenters();
  const [tab, setTab] = useState(0);



  if ( loading ) return <FullPageSpinner />;


  return (
    <Can
      role={user.role}
      perform={rules.dashboard.get}
      no={() => <Redirect to="/" />}
      yes={() => (
        <Box className={classes.main}>
          <Typography className={classes.title}>Resumen de principales stats de la aplicación</Typography>


          <Grid
            className={classes.root}
            container
            justify="flex-start"
            spacing={gridSpacing}
          >
            <Grid style={{ height: '100%' }} item lg={12} xl={10}>
              <Tabs value={tab}>
                <StyledTab onClick={() => setTab(0)} label="Sospechas" />
                <StyledTab onClick={() => setTab(1)} label="Oferta y procuramiento" />
                <StyledTab onClick={() => setTab(2)} label="Tiempos promedio" />
              </Tabs>
              <Paper className={classes.paper} elevation={3}>
                {tab === 0 && <SuspicionTab medicalCenters={allMedicalCenters}></SuspicionTab>}
                {tab === 1 && <OfferingsAndProcureTab medicalCenters={allMedicalCenters}></OfferingsAndProcureTab>}
                {tab === 2 && <TimingStatsTab medicalCenters={allMedicalCenters}></TimingStatsTab>}
                {tab === 3 }
                {tab === 4 }
              </Paper>
            </Grid>
          </Grid>
        </Box>
      )}
    />
  );
};

export default DashboardMainComponent;
