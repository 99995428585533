import { fireSentryEvent, setContextTag } from '../../services/sentry';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import {
  Grid,
  Typography,
  Tooltip,
  Button,
  IconButton,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@material-ui/icons/Delete';
import ResponsiveDialog from '../../components/ui/responsive-dialog';
import useModal from '../../hooks/useModal';
import { ALL_ORGAN_GROUPS_QUERY } from './use-all-teams';

export const DELETE_ORGAN_GROUP_MUTATION = gql`
  mutation deleteOrganGroup($deleteOrganGroupInput: deleteOrganGroupInput!) {
    deleteOrganGroup(deleteOrganGroupInput: $deleteOrganGroupInput)
  }
`;

const DeleteOrganGroupDialog = ({ organGroupId }) => {
  const history = useHistory();

  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const [deleteOrganGroup, { loading }] = useMutation(DELETE_ORGAN_GROUP_MUTATION, {
    optimisticResponse: null,
    update: (cache) => {
      const teamsQuery = cache.readQuery({
        query: ALL_ORGAN_GROUPS_QUERY,
      });
      const newTeams = teamsQuery.allTeams.filter((h) => h.id !== organGroupId);
      cache.writeQuery({
        query: ALL_ORGAN_GROUPS_QUERY,
        data: { allTeams: newTeams },
      });
    },
  });

  const handleSubmit = async () => {
    closeModal();
    history.push('/teams');
    try {
      await deleteOrganGroup({
        variables: {
          deleteOrganGroupInput: {
            id: organGroupId,
          },
        },
      });
      enqueueSnackbar('Equipo eliminado exitosamente', { variant: 'success' });
      // window.location.reload();
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Hubo un error.', {
        variant: 'error',
      });
      setContextTag('error al eliminar equipo', error.message);
      fireSentryEvent(error);
    }
  };

  return (
    <Grid>
      <>
        <Tooltip title="Eliminar equipo">
          <IconButton
            aria-label="Eliminar equipo"
            size="small"
            color="primary"
            onClick={openModal}
            id="delete-user-btn"
          >
            <Typography>Eliminar equipo</Typography>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <ResponsiveDialog
          align="center"
          open={isModalOpen}
          onClose={closeModal}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            Confirmar eliminación de equipo {organGroupId}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              ¿Estás seguro de querer eliminar el equipo de manera permanente?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal} color="secondary">
              Cancelar
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={loading}
              color="primary"
              variant="contained"
              type="submit"
            >
              Eliminar
            </Button>
          </DialogActions>
        </ResponsiveDialog>
      </>
    </Grid>
  );
};

export default DeleteOrganGroupDialog;
