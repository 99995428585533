import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { useField } from 'formik';
import React from 'react';

const FormSingleAutocomplete = ({
  label,
  name,
  options = [],
  getOptionLabel,
  renderOption,
  getValueFromOption,
  ...props
}) => {
  const [, meta, helpers] = useField(name);

  const handleChange = (event, newOption) => {
    if (!newOption) {
      helpers.setValue(meta.initialValue);
    } else {
      helpers.setValue(getValueFromOption(newOption));
    }
  };

  return (
    <Autocomplete
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionDisabled={typeof(options[0]) == 'string' ? (option) => option.includes('No hay') :null}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField
          label={label}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error}
          {...params}
        />
      )}
      autoHighlight
      onChange={handleChange}
      {...props}
    />
  );
};

export default FormSingleAutocomplete;
