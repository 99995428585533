import { useState } from 'react';

function useModal({ initialOpen = false } = {}) {
  const [isModalOpen, setIsModalOpen] = useState(initialOpen);
  const close = () => setIsModalOpen(false);
  const open = () => setIsModalOpen(true);

  return { isModalOpen, close, open };
}

export default useModal;
