import { fireSentryEvent, setContextTag } from '../../services/sentry';
import React from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import {
  Tooltip,
  Button,
  IconButton,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@material-ui/icons/Delete';
import ResponsiveDialog from '../../components/ui/responsive-dialog';
import useModal from '../../hooks/useModal';
import { USERS_LIST_QUERY } from './use-all-users';

export const DELETE_MUTATION = gql`
  mutation DeleteUserMutation($id: ID!) {
    deleteUser(id: $id)
  }
`;

const DeleteUserDialog = ({ userId }) => {
  const { isModalOpen, open: openModal, close: closeModal } = useModal();

  const { enqueueSnackbar } = useSnackbar();

  const [deleteUser, { loading }] = useMutation(DELETE_MUTATION, {
    optimisticResponse: null,
    update: (cache) => {
      const existingUsers = cache.readQuery({ query: USERS_LIST_QUERY });
      const newUsers = existingUsers.getUsers.filter((u) => u.id !== userId);
      cache.writeQuery({
        query: USERS_LIST_QUERY,
        data: { getUsers: newUsers },
      });
    },
  });

  const handleSubmit = async () => {
    closeModal();
    try {
      await deleteUser({
        variables: { id: userId },
      });
      enqueueSnackbar('Usuario eliminado exitosamente', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Hubo un error al procesar su solicitud', {
        variant: 'error',
      });
      setContextTag('error al eliminar usuario', error.message);
      fireSentryEvent(error);
    }
  };

  return (
    <>
      <Tooltip title="Eliminar usuario">
        <IconButton
          aria-label="Eliminar usuario"
          size="small"
          color="primary"
          onClick={openModal}
          id="delete-user-btn"
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <ResponsiveDialog
        align="center"
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Confirmar eliminación de usuario
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de querer eliminar al usuario y sus datos asociados de manera
            permanente?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="secondary">
            Cancelar
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={loading}
            color="primary"
            variant="contained"
            type="submit"
          >
            Eliminar
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    </>
  );
};

export default DeleteUserDialog;
