import React, { useState } from 'react';
import { Paper, Typography, makeStyles, Grid, Box, Divider, Button } from '@material-ui/core';

import FullPageSpinner from '../../components/ui/full-page-spinner';
import { valueFromASTUntyped } from 'graphql';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 320,
  },
  root: { padding: theme.spacing(2) },
  title: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.h6.fontWeight,
  },
  header: { display: 'flex', justifyContent: 'space-between' },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  infoItem: {
    display: 'flex',
    alignItems: 'center',
  },
  infoItemTitle: {
    flex: 1,
    fontWeight: theme.typography.h6.fontWeight,
    color: theme.palette.text.hint,
  },
  newReciever: {
    height: '22px',
    marginBottom: '5px',
    marginLeft: '8px',
  },
  timeLeft: { fontSize: theme.typography.h6.fontSize },
 
}));


const SuspicionStatsDetail = ({options, groupBy, data}) => {
  
  
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [show, setShow] = useState([]);


  let buttonColor = '#A5ADCF'
  
  let computing = false;

  const handleDropdownChange = (event) => {
    setValue(event.target.value);
    var result = data.filter(obj => {
      return obj.name == event.target.value
    })
    setShow(result)
  };

  if (computing){
    return <FullPageSpinner></FullPageSpinner>
  }
  return (
    <Box elevation={3} className={classes.root}>
      <Grid container spacing={1}>
        
        
        <Grid item xs={12}>
          
          <Box className={classes.infoItem}>
            

            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-autowidth-label">Selecciona una agrupación</InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={value}
                  onChange={handleDropdownChange}
                  autoWidth
                >
                  {options.map((option) => (
                    <MenuItem value={option}>{option}</MenuItem>
                  ))}
                </Select>
          </FormControl>
          </Box>
          
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.infoItem}>
            <Typography variant="subtitle1" className={classes.infoItemTitle}>
               Número de sospechas levantadas
            </Typography>
            <Typography variant="body1"> {show[0] ? show[0].total : 'No disponible'}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.infoItem}>
            <Typography variant="subtitle1" className={classes.infoItemTitle}>
                Número de donantes (sospechas confirmadas) 
            </Typography>
            <Typography variant="body1">{show[0] ? show[0].confirmed : 'No disponible'}</Typography>
          </Box>
          <Divider />
        </Grid>
        <Divider />
        <Grid item xs={12}>
          <Box className={classes.infoItem}>
            <Typography variant="subtitle1" className={classes.infoItemTitle}>
                Tasa donantes/sospechas
            </Typography>
            <Typography variant="body1">{show[0] ? show[0].rate : 'No disponible'}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.infoItem}>
            <Typography variant="subtitle1" className={classes.infoItemTitle}>
                Delta (sospechas - donantes)
            </Typography>
            <Typography variant="body1">{show[0] ? show[0].delta : 'No disponible'}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SuspicionStatsDetail;
