import { Button } from '@material-ui/core';
import React from 'react';
import useModal from '../../hooks/useModal';
import MoreInfoDonorDialog from './more-info-donor-dialog';

// const useStyles = makeStyles((theme) => ({
//   icon: {
//     height: 200,
//     width: 200,
//     fill: theme.palette.primary.main,
//   },
//   text: {
//     marginTop: theme.spacing(2),
//     fontSize: theme.typography.h6.fontSize,
//     fontWeight: theme.typography.h6.fontWeight,
//   },
// }));

const MoreInfoDonorButton = ({ offering }) => {
  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  return (
    <>
      <Button
        label="Más información de oferta"
        size="small"
        color="secondary"
        variant="contained"
        id="more-info-donor"
        onClick={openModal}
      >
        Información del donante
      </Button>
      <MoreInfoDonorDialog open={isModalOpen} onClose={closeModal} offering={offering} />
    </>
  );
};

export default MoreInfoDonorButton;
