import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

const TEAM_MEMBERS_QUERY = gql`
  query getGroupMembersOfAnOrganGroup($groupId: ID!) {
    getGroupMembersOfAnOrganGroup(organGroupId: $groupId) {
      groupId
      userId
      user {
        firstName
        lastName
      }
      boss
      procurerBoss
      active
    }
  }
`;

function useTeamMembers(groupId) {
  const { data, loading, error } = useQuery(TEAM_MEMBERS_QUERY, {
    variables: { groupId },
  });
  const teamMembers = data?.getGroupMembersOfAnOrganGroup;
  return { teamMembers, loading, error };
}

export { TEAM_MEMBERS_QUERY, useTeamMembers };
