import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import CreateAllocationButton from './create-allocation-button';

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex', alignItems: 'center' },
  title: { flex: 1, marginLeft: theme.spacing(1) },
  noAllocationsText: {
    flex: 4,
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
}));

const PendingOrganItem = ({ organ }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        {organ.organType.name}
      </Typography>
      <Typography className={classes.noAllocationsText}>Sin ofertas</Typography>
      <CreateAllocationButton organ={organ} />
    </Box>
  );
};

export default PendingOrganItem;
