import React from 'react';
import { Dialog, useMediaQuery, useTheme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  paperWidthSm: {
    minWidth: '600px',
  },
}));

const ResponsiveDialog = ({ children, ...dialogProps }) => {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog fullScreen={fullScreen} {...dialogProps} classes={classes}>
      {children}
    </Dialog>
  );
};

export default ResponsiveDialog;
