/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';
import { changeDateFormat } from './date-format';

Yup.setLocale({
  mixed: {
    required: 'Este es un campo requerido',
  },
  string: {
    email: 'Correo es inválido',
  },
  date: {
    min: ({ min }) => `La fecha debe ser posterior a ${changeDateFormat(min)}`,
  },
});

export default Yup;
