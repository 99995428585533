import { fireSentryEvent, setContextTag } from '../../services/sentry';
import React from 'react';
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  makeStyles,
} from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { useSnackbar } from 'notistack';

import { OFFERINGS_QUERY } from './use-all-offerings';
import ResponsiveDialog from '../../components/ui/responsive-dialog';
import useModal from '../../hooks/useModal';
import { CONFIRMED_SUSPICIONS_QUERY } from '../organs/use-confirmed-suspicions';

const DISMISS_OFFERING_MUTATION = gql`
  mutation DismissOffering($offeringId: ID!) {
    rejectOffering(id: $offeringId) {
      id
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
    color: theme.palette.error.main,
  },
}));

const DismissOfferingButton = ({ offering, ...buttonProps }) => {
  const classes = useStyles();
  const { isModalOpen, open, close } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [dismissOffering, { loading }] = useMutation(DISMISS_OFFERING_MUTATION, {
    refetchQueries: [
      { query: OFFERINGS_QUERY },
      { query: OFFERINGS_QUERY, variables: { expired: true } },
      { query: CONFIRMED_SUSPICIONS_QUERY },
    ],
  });

  const handleSubmit = async () => {
    try {
      await dismissOffering({ variables: { offeringId: offering.id } });
      enqueueSnackbar('Oferta rechazada', { variant: 'success' });
      close();
    } catch (error) {
      enqueueSnackbar('Hubo un error al rechazada la oferta', { variant: 'error' });
      setContextTag('error al rechazar oferta', error.message);
      fireSentryEvent(error);
    }
  };

  return (
    <>
      <Button
        className={classes.button}
        onClick={open}
        variant="text"
        color="primary"
        data-testid="dismiss-offering-button"
        {...buttonProps}
      >
        Rechazar
      </Button>
      <ResponsiveDialog align="left" open={isModalOpen} onClose={close}>
        <DialogTitle id="confirm-suspicion-dialog">Rechazar oferta</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de querer rechazar el{' '}
            {offering.organ.organType.name.toLowerCase()} de{' '}
            {offering.organ.suspicion.rut}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="secondary">
            Volver
          </Button>
          <Button onClick={handleSubmit} disabled={loading} color="primary" type="submit">
            Rechazar
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    </>
  );
};

export default DismissOfferingButton;
