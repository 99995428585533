import { Grid, Typography, Divider } from '@material-ui/core';
import React from 'react';
import { useCurrentUser } from '../features/authentication/use-current-user';
import { useAllUsers } from '../features/users/use-all-users';
import TeamsListTable from '../features/teams/teams-list-table';
import TeamDetails from './team-details';
import CreateTeamButton from '../features/teams/create-team-dialog';
import Can from '../utils/can';
import { rules } from '../utils/rbac-rules';
import FullPageSpinner from '../components/ui/full-page-spinner';
import { useMyTeams } from '../features/teams/use-my-teams';
import { useAllTeams } from '../features/teams/use-all-teams';

/* Faltaría verificar bien la condición del Can, porque no está entrando si es que no se es el admin */

const TeamsPage = () => {
  const { user } = useCurrentUser();
  const { loading } = useAllUsers();
  const { userGroups, loading: loadingUserGroups } = useMyTeams();
  const { allTeams, loading: loadingGroups } = useAllTeams();

  if (loading || loadingUserGroups || loadingGroups) {
    return <FullPageSpinner />;
  }
  // console.log(userGroups);
  return (
    <>
      <Can
        role={user.role}
        perform={rules.teams.get}
        yes={() => (
          <>
            <Grid container align="left">
              <Grid item>
                <Typography variant="h6" component="div">
                  Equipos
                </Typography>
              </Grid>
              <Grid item>
                <CreateTeamButton />
              </Grid>
            </Grid>
            <Grid container align="left">
              <TeamsListTable teams={allTeams} />
            </Grid>
          </>
        )}
        no={() => (
          <Grid container align="left">
            {userGroups.length === 0 ? 
              <Grid container align="left">
                <Typography variant="h6" component="div">
                  No estás asignado a ningún equipo aún
                </Typography>
              </Grid>
              :
              userGroups.map((group) => (
                <Grid>
                  <TeamDetails groupId={group.groupId} />
                  <br />
                  <Divider />
                </Grid>
            ))}
            {/* <TeamDetails groupId={userGroups[0].groupId}/> */}
          </Grid>
        )}
      />
    </>
  );
};

export default TeamsPage;
