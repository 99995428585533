import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import LoginPage from './pages/login-page';

export const routes = {
  HOME: '/',
};

const AppRoutesUnauthenticated = () => {
  return (
    <Switch>
      <Route exact path="/" component={LoginPage} />
      <Redirect exact from="*" to="/" />
    </Switch>
  );
};

export default AppRoutesUnauthenticated;
