import { createMuiTheme } from '@material-ui/core/styles';
import { colors } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1F223C',
    },
    secondary: {
      main: '#A5ADCF',
    },
    warning: {
      main: '#F2C94C',
    },
    success: {
      main: '#5EAB07',
    },
    text: {
      secondary: colors.grey[900],
      hint: colors.grey[500],
    },
  },
  typography: {
    fontFamily: 'Roboto',
    subheading: {
      fontSize: '14px',
    },
    body2: {
      fontSize: '14px',
    },
    body1: {
      fontSize: '14px',
    },
    caption: {
      fontSize: '14px',
    },
  },
});

export default theme;
