import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { useCurrentUser } from '../features/authentication/use-current-user';
import CreateUserButton from '../features/users/create-user-dialog';
import { useAllUsers } from '../features/users/use-all-users';
import UsersList from '../features/users/users-table';
import Can from '../utils/can';
import { rules } from '../utils/rbac-rules';
import FullPageSpinner from '../components/ui/full-page-spinner';

const UsersListPage = () => {
  const { user } = useCurrentUser();
  const { users, loading } = useAllUsers();

  if (loading) {
    return <FullPageSpinner />;
  }

  return (
    <>
      <Can
        role={user.role}
        perform={rules.users.get}
        yes={() => (
          <>
            <Grid container align="left">
              <Grid item>
                <Typography variant="h6" component="div">
                  Usuarios
                </Typography>
              </Grid>
              <Grid item>
                <CreateUserButton />
              </Grid>
            </Grid>
            <Grid container align="center">
              <UsersList users={users} />
            </Grid>
          </>
        )}
        no={() => <Redirect to="/" />}
      />
    </>
  );
};

export default UsersListPage;
