import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

export const USER_MEDICAL_CENTERS_QUERY = gql`
  query getUser($id: ID!) {
    getUser(id: $id) {
      id
      medicalCenters {
        id
        name
      }
    }
  }
`;

function useUserMedicalCenters(id) {
  const { data, loading, error } = useQuery(USER_MEDICAL_CENTERS_QUERY, {
    variables: { id },
  });
  const userMedicalCenters = data?.getUser.medicalCenters;
  return { userMedicalCenters, loading, error };
}

export default useUserMedicalCenters;
