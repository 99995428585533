import React from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';

const FullPageSpinner = () => {
  return (
    <Backdrop open>
      <CircularProgress color="inherit" data-testid="loading-spinner" />
    </Backdrop>
  );
};

export default FullPageSpinner;
