import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  Stepper,
  Step,
  StepButton,
  Button
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SurgeryConfirmationStep from './Extraction/surgery-confirmation-step'
import SurgeryStartStep from './Extraction/surgery-start-step'
import SurgeryExtractionStep from './Extraction/surgery-extraction-step'
import SurgeryFinalStep from './Extraction/surgery-final-step'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  rowroot: {
    padding: theme.spacing(1),
    display: 'flex',
    flexwrap: 'nowrap',
    flexDirection: 'row',
  },
  rowroot2: {
    padding: theme.spacing(1),
    display: 'flex',
    flexwrap: 'nowrap',
    flexDirection: 'row',
    marginTop: '3px'
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    color: 'green',
  },
  pending: {
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    color: 'gray',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  completo: {
    color: theme.palette.text.hint
  },
  paso: {
    height: '100px',
    marginLeft: '30px'
  },

}));

function getSteps() {
  return ['Confirmación de cirugía', 'Iniciar cirugía', 'Extracción', 'Finalizar extracción'];
}

const SurgeryExtractionTab = ({surgery, user}) => {
  const next_status = ["coordinacion", "aprobada", "enCurso", "enIsquemia", "realizada", "Extraido"];
  const initial_active_step = {'coordinacion': 0, 'agendada': 0, 'aprobada': 1, 'enCurso': 2, 'enIsquemia': 3, 'realizada': 3}
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(initial_active_step[surgery.status]);
  let organsList = []
  if (user.role == 'admin' || user.role == 'coordinator'){
    organsList = surgery.organs;
  } else{
    organsList = surgery.assignedOrgans;
  };
  const currentOrganStatus = organsList[0].status;
  const [completed, setCompleted] = React.useState({
    0: next_status.slice(1, 5).includes(surgery.status),
    1: next_status.slice(2, 5).includes(surgery.status),
    2: next_status.slice(3, 5).includes(surgery.status),
    3: next_status.slice(4, 5).includes(surgery.status) || next_status.slice(5, 6).includes(currentOrganStatus),
  });
  const steps = getSteps();

  const handleNext = () => {
    const newActiveStep =
      activeStep == 3
        ? 0 : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  function handleComplete() {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    return 0;
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <SurgeryConfirmationStep
            surgery={surgery}
            organsList={organsList}
            handleStep={handleComplete}
            completed={completed}
            steps={steps}
            activeStep={activeStep}
          />
        );
      case 1:
        return (
          <SurgeryStartStep
            surgery={surgery}
            organsList={organsList}
            handleStep={handleComplete}
            completed={completed}
            steps={steps}
            activeStep={activeStep}
          />
        );
      case 2:
        return (
          <SurgeryExtractionStep
            surgery={surgery}
            organsList={organsList}
            user={user}
            handleStep={handleComplete}
            completed={completed}
            steps={steps}
            activeStep={activeStep}
          />
        );
      case 3:
        return (
          <SurgeryFinalStep
            surgery={surgery}
            organsList={organsList}
            handleStep={handleComplete}
            completed={completed}
            steps={steps}
            activeStep={activeStep}
            setCompleted={setCompleted}
          />
        );
      default:
    };
  };

  return (
    <>
      <div className={classes.root}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} >
              <StepButton onClick={handleStep(index)} completed={completed[index]} >
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <div>
          <div>
            <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
            {activeStep !== steps.length &&
              (completed[activeStep] ? (
                <Grid className={classes.rowroot2}>
                  <Typography variant="caption" className={classes.completed}>
                    Paso {activeStep + 1} ya completado
                  </Typography>
                  <CheckCircleIcon className={classes.completed}/>
                </Grid>
              ) : 
                <Grid className={classes.rowroot2}>
                  <Typography variant="caption" className={classes.pending}>
                    Paso {activeStep + 1} pendiente
                  </Typography>
                </Grid>
              )
            }
            <Grid className={classes.rowroot}>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                Atras
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                Siguiente
              </Button>
            </Grid>
          </div>
        </div>
      </div>
    </>
    
  );
};

export default SurgeryExtractionTab;