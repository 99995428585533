import { Button } from '@material-ui/core';
import React from 'react';
import { ExitToApp as AddIcon } from '@material-ui/icons';
import useModal from '../../hooks/useModal';
import CreateMedicalRecordDialog from './create-medical-record-dialog';

const CreateMedicalRecordButton = ({ suspicion, ...buttonProps }) => {
  const { isModalOpen, open: openModal, close: closeModal } = useModal();

  return (
    <>
      <Button
        data-testid="add-medical-record-button"
        onClick={openModal}
        variant="contained"
        color="primary"
        size="small"
        startIcon={<AddIcon />}
        {...buttonProps}
      >
        Ingresar
      </Button>
      <CreateMedicalRecordDialog
        suspicion={suspicion}
        open={isModalOpen}
        onClose={closeModal}
      />
    </>
  );
};

export default CreateMedicalRecordButton;
