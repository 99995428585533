import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { useCurrentUser } from '../../features/authentication/use-current-user';
import { fireSentryEvent, setContextTag } from '../../services/sentry';


const useStyles = makeStyles({
  root: {
    display: 'flex',
    minHeight: 'fit-content',
  },
  avatar: {
    width: 45,
    height: 35,
  },
  welcome: {
    marginTop: 8,
  },
});

const Profile = () => {
  const classes = useStyles();
  const { user, error } = useCurrentUser();
  
  if (error) {
    setContextTag('Show current user on navbar', error.message);
    fireSentryEvent(error);
  }

  return (
    <div className={classes.root}>
      <PersonIcon className={classes.avatar} />
      <Box fontSize="14px" className={classes.welcome}>
        Hola&nbsp;
      </Box>
      <Box fontSize="14px" fontWeight="fontWeightBold" className={classes.welcome}>
        {user.firstName}
      </Box>
    </div>
  );
};

export default Profile;
