import React from 'react';
import { ExitToApp as AddIcon } from '@material-ui/icons';

import useModal from '../../hooks/useModal';
import { useMutation } from '@apollo/react-hooks';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { gql } from 'apollo-boost';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import FormDropzoneArea from '../../components/forms/form-dropzone-area';
import FormTextField from '../../components/forms/form-text-field';
import ResponsiveDialog from '../../components/ui/responsive-dialog';
import Yup from '../../utils/validations';
import { SUSPICION_QUERY } from '../suspicions/use-suspicion';
import { fireSentryEvent, setContextTag } from '../../services/sentry';

const SUPPORTED_FORMATS = [
  'image/jpeg',
  'image/png',
  'application/pdf',
  '.xls',
  '.xlsx',
  '.doc',
  '.docx',
];

const confirmBrainDeathSchema = Yup.object({
  comments: Yup.string().optional(),
  suspicionId: Yup.number().required(),
});

export const CREATE_BRAIN_DEATH_MUTATION = gql`
  mutation CreateBrainDeath($brainDeathInput: BrainDeathInput!) {
    createBrainDeath(brainDeathInput: $brainDeathInput) {
      id
      document
      comments
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  extensions: {
    display: 'block',
    textAlign: 'right',
    color: theme.palette.text.disabled,
  },
}));

const CreateBrainDeathDialog = ({ suspicionId, expanded, children, ...buttonProps }) => {
  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  const classes = useStyles();

  const [confirmBrainDeath, { loading }] = useMutation(CREATE_BRAIN_DEATH_MUTATION, {
    refetchQueries: [
      {
        query: SUSPICION_QUERY,
        variables: {
          suspicionId,
        },
      },
    ],
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (values, { resetForm }) => {
    try {
      await confirmBrainDeath({
        variables: { brainDeathInput: values },
      });
      enqueueSnackbar('Confirmación de muerte cerebral creada exitosamente', {
        variant: 'success',
      });
      closeModal();
    } catch (error) {
      enqueueSnackbar('Hubo un error al procesar su solicitud.', { variant: 'error' });
      setContextTag('error al procesar solicitud de muerte cerebral', error.message);
      fireSentryEvent(error);
    }
    // resetForm({});
  };

  return (
    <>
      <Button
        onClick={openModal}
        variant="contained"
        color="primary"
        size="small"
        startIcon={<AddIcon />}
        {...buttonProps}
      >
        {children}
      </Button>
      <Formik
        initialValues={{
          document: null,
          comments: '',
          suspicionId,
        }}
        onSubmit={handleSubmit}
        validationSchema={confirmBrainDeathSchema}
      >
        {({ submitForm }) => (
          <Form>
            <ResponsiveDialog
              open={isModalOpen}
              onClose={closeModal}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title">
                Confirmar muerte cerebral
              </DialogTitle>
              <DialogContent>
                <DialogContentText>Completa el formulario con los datos.</DialogContentText>
                <FormDropzoneArea
                  name="document"
                  label="Documento"
                  filesLimit={1}
                  acceptedFiles={SUPPORTED_FORMATS}
                />
                <Typography className={classes.extensions} variant="caption">
                  Extensiones permitidas: pdf, jpg, png, xls, doc, xlsx, docx
                </Typography>
                <FormTextField
                  name="comments"
                  label="Comentarios"
                  multiline
                  rows={4}
                  fullWidth
                  onFocus={(event) => event.stopPropagation()}
                />
              </DialogContent>
              <DialogActions>
                {loading && <CircularProgress/>}
                <Button onClick={closeModal} color="secondary">
                  Cancelar
                </Button>
                <Button
                  onClick={submitForm}
                  disabled={loading}
                  color="primary"
                  type="submit"
                  variant="contained"
                >
                  Ingresar
                </Button>
              </DialogActions>
            </ResponsiveDialog>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CreateBrainDeathDialog;
