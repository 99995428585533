import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Grid,
  Typography,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ResponsiveDialog from '../../components/ui/responsive-dialog';

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(1),
  },
  disclaimer: {
    paddingLeft: theme.spacing(2),
    fontSize: '13px',
    color: theme.palette.text.secondary,
    fontStyle: 'italic'
  },
}));

const MoreInfoDonorDialog = ({ open, onClose, offering }) => {

  const classes = useStyles();

  return (
    <ResponsiveDialog
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">Detalle del donante</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container>
            <Grid container>
              <Grid item xs={12} align="center">
                <Divider className={classes.detailDivider} />
              </Grid>
            </Grid>
            <Grid item xs={4} align="left">
              <Typography className={classes.detailTitle}>Edad</Typography>
            </Grid>
            <Grid item xs={8} align="left">
              <Typography className={classes.detailData}>
                {offering.organ.suspicion.background.age}
              </Typography>
            </Grid>
            <Grid item xs={4} align="left">
              <Typography className={classes.detailTitle}>Puntuación Glasgow</Typography>
            </Grid>
            <Grid item xs={8} align="left">
              <Typography className={classes.detailData}>
                {offering.organ.suspicion.background.glasgow}
              </Typography>
            </Grid>
            <Grid item xs={4} align="left">
              <Typography className={classes.detailTitle}>Diagnóstico</Typography>
            </Grid>
            <Grid item xs={8} align="left">
              <Typography className={classes.detailData}>
                {offering.organ.suspicion.background.diagnostic}
              </Typography>
            </Grid>
            <Grid item xs={4} align="left">
              <Typography className={classes.detailTitle}>Rut</Typography>
            </Grid>
            <Grid item xs={8} align="left">
              <Typography className={classes.detailData}>
                { offering.organ.suspicion.rut }
              </Typography>
            </Grid>
            <Grid item xs={4} align="left">
              <Typography className={classes.detailTitle}>Centro Médico</Typography>
            </Grid>
            <Grid item xs={8} align="left">
              <Typography className={classes.detailData}>
                { offering.organ.suspicion.medicalCenter.name }
              </Typography>
            </Grid>
            <Grid container>
              <Grid item xs={12} align="center">
                <Divider className={classes.detailDivider} />
              </Grid>
            </Grid>
            <Grid item xs={12} align="left">
              <Typography className={classes.detailTitle}>
                Comentarios adicionales
              </Typography>
            </Grid>
            <Grid item xs={12} align="left" widht="90%">
              <Typography className={classes.detailData}>
                {offering.organ.suspicion.background.comment === ''
                  ? 'Sin comentarios'
                  : offering.organ.suspicion.background.comment}
              </Typography>
              <Grid item xs={12} align="left">
                  <Typography className={classes.disclaimer}>
                    Las imágenes y/o documentos de esta sección se consideran datos sensibles y de carácter confiencial. 
                  </Typography>
                </Grid>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Link
          className={classes.link}
          component={RouterLink}
          to={`/suspicions/${offering.organ.suspicion.id}`}
        >
          <Button
            abel="Más información de oferta"
            size="small"
            color="secondary"
            variant="contained"
            id="more-info-donor-button"
            data-testid="more-info-donor-button"
          >
            Información detallada del paciente
          </Button>
        </Link>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
};

export default MoreInfoDonorDialog;
