import React, { useEffect } from 'react';
import { Paper, Typography, makeStyles, Grid, Box, Divider, Button } from '@material-ui/core';
import { addHours } from 'date-fns';

import FullPageSpinner from '../../components/ui/full-page-spinner';
import { valueFromASTUntyped } from 'graphql';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 320,
  },
  root: { padding: theme.spacing(2) },
  title: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.h6.fontWeight,
  },
  header: { display: 'flex', justifyContent: 'space-between' },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  infoItem: {
    display: 'flex',
    alignItems: 'center',
  },
  infoItemTitle: {
    flex: 1,
    fontWeight: theme.typography.h6.fontWeight,
    color: theme.palette.text.hint,
  },
  newReciever: {
    height: '22px',
    marginBottom: '5px',
    marginLeft: '8px',
  },
  timeLeft: { fontSize: theme.typography.h6.fontSize },
 
}));


const OfferingsProcureStatsDetail = ({options, groupBy}) => {
  
  
  const classes = useStyles();
  const [value, setValue] = React.useState(options[0]);

  let buttonColor = '#A5ADCF'

  let computing = false;

  const handleDropdownChange = (event) => {
    setValue(event.target.value);
  };

  if (computing){
    return <FullPageSpinner></FullPageSpinner>
  }
  return (
    <Box elevation={3} className={classes.root}>
      <Grid container spacing={1}>
        
        
        <Grid item xs={12}>
          
          {groupBy != 'total' ? <Box className={classes.infoItem}>
            

            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-autowidth-label">Selecciona una agrupación</InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={value}
                  onChange={handleDropdownChange}
                  autoWidth
                >
                  {options.map((option) => (
                    <MenuItem value={option}>{option}</MenuItem>
                  ))}
                </Select>
          </FormControl>
          </Box> : <h1></h1>}
        
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1"> Número de:</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.infoItem}>
            <Typography variant="subtitle1" className={classes.infoItemTitle}>
                Órganos ofertados
            </Typography>
            <Typography variant="body1"> 32</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.infoItem}>
            <Typography variant="subtitle1" className={classes.infoItemTitle}>
                Corazones ofertados
            </Typography>
            <Typography variant="body1"> 10</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.infoItem}>
            <Typography variant="subtitle1" className={classes.infoItemTitle}>
                Pulmones ofertados
            </Typography>
            <Typography variant="body1"> 12</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.infoItem}>
            <Typography variant="subtitle1" className={classes.infoItemTitle}>
                Hígados ofertados
            </Typography>
            <Typography variant="body1"> 10</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.infoItem}>
            <Typography variant="subtitle1" className={classes.infoItemTitle}>
                Páncreas ofertados
            </Typography>
            <Typography variant="body1"> 32</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.infoItem}>
            <Typography variant="subtitle1" className={classes.infoItemTitle}>
                Donantes de múltiples órganos 
            </Typography>
            <Typography variant="body1">7</Typography>
          </Box>
          <Divider />
        </Grid>
        <Divider />
        <Grid item xs={12}>
          <Box className={classes.infoItem}>
            <Typography variant="subtitle1" className={classes.infoItemTitle}>
                Donantes de tejido
            </Typography>
            <Typography variant="body1">0</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OfferingsProcureStatsDetail;
