import React from 'react';
import { Grid } from '@material-ui/core';

import { useCurrentUser } from '../authentication/use-current-user';
import OrganItem from './organ-item';

const OrgansPanel = ({ surgery, procureSchedule }) => {
  const { user } = useCurrentUser();

  let organs = [];
  switch (user.role) {
    case 'admin':
    case 'coordinator':
      organs = surgery.organs;
      break;
    case 'procurer':
      organs = surgery.assignedOrgans;
      break;
    default:
      throw new Error('This role is not defined');
  }

  return (
    <Grid container spacing={3}>
      {organs.map((organ) => (
        <Grid key={organ.id} item sm={12} md={6}>
          <OrganItem surgery={surgery} organ={organ} procureSchedule={procureSchedule} />
        </Grid>
      ))}
    </Grid>
  );
};

export default OrgansPanel;
