import React from 'react';
import { Grid, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import DescriptionIcon from '@material-ui/icons/Description';
import { changeDateFormat } from '../../utils/date-format';
import CreateBrainDeathDialog from './create-brain-death-dialog';
import ConfirmBrainDeathDialog from './confirm-brain-death-dialog';
import { useCurrentUser } from '../authentication/use-current-user';
import Can from '../../utils/can';
import { rules } from '../../utils/rbac-rules';
import SuspicionExpansionPanelSummary from '../suspicions/suspicion-expansion-panel-summary';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
  },
  iconLabel: {
    fontSize: 14,
    marginLeft: theme.spacing(-7),
    marginTop: theme.spacing(1),
  },
  disclaimer: {
    paddingLeft: theme.spacing(2),
    fontSize: '13px',
    color: theme.palette.text.secondary,
    fontStyle: 'italic'
  },
  detailTitle: {
    paddingLeft: theme.spacing(2),
    fontSize: '16px',
    color: theme.palette.text.secondary,
  },
  detailData: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  detailDataTime: {
    paddingLeft: theme.spacing(2),
    color: 'gray',
  },
  detailUser: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.text.secondary,
    fontSize: '16px',
  },
  detailNotConfirmed: {
    paddingLeft: theme.spacing(24),
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    fontSize: '16px',
  },
  detailDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  download: {
    color: theme.palette.primary.main,
  },
  disableDownload: {
    color: 'gray',
  },
}));

const BrainDeathDetails = ({ suspicion, isExpanded, onClick }) => {
  const classes = useStyles();
  const { user } = useCurrentUser();

  return (
    <>
      <div className={classes.root}>
        <ExpansionPanel expanded={isExpanded}>
          <SuspicionExpansionPanelSummary
            label="Muerte cerebral"
            onClick={onClick}
            isExpanded={isExpanded}
            suspicion={suspicion}
            stepName="brainDeath"
            testId="details-brain-death-btn"
          >
            <Can
              role={user.role}
              perform={rules.brainDeath.create}
              yes={() =>
                !suspicion.brainDeath && (
                  <CreateBrainDeathDialog
                    suspicionId={suspicion.id}
                    data-testid="add-brain-death-confirmation-button"
                  >
                    Ingresar
                  </CreateBrainDeathDialog>
                )
              }
            />
          </SuspicionExpansionPanelSummary>
          {suspicion.brainDeath ? (
            <ExpansionPanelDetails>
              <Grid container>
                <Grid item xs={12} align="left">
                  <Typography className={classes.detailDataTime}>
                    {changeDateFormat(suspicion.brainDeath.createdAt)}
                  </Typography>
                  <Typography className={classes.detailData}>
                    Ingresados y confirmados por:
                  </Typography>
                  <Typography className={classes.detailUser}>
                    {suspicion.brainDeath.creator.firstName}{' '}
                    {suspicion.brainDeath.creator.lastName}
                  </Typography>
                </Grid>
                <Grid container>
                  <Grid item xs={12} align="center">
                    <Divider className={classes.detailDivider} />
                  </Grid>
                </Grid>
                <Grid container justify="center">
                  <Grid item xs={1} align="center">
                    <Link href={suspicion.brainDeath.document}>
                      <Button disabled={!suspicion.brainDeath.document}>
                        <DescriptionIcon
                          className={
                            suspicion.brainDeath.document
                              ? classes.download
                              : classes.disableDownload
                          }
                        />
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item xs={2} align="center">
                    <Typography className={classes.iconLabel}>Documento</Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} align="center">
                    <Divider className={classes.detailDivider} />
                  </Grid>
                </Grid>
                <Grid item xs={12} align="left">
                  <Typography className={classes.detailTitle}>
                    Comentarios adicionales
                  </Typography>
                </Grid>
                <Grid item xs={12} align="left" widht="90%">
                  <Typography className={classes.detailData}>
                    {suspicion.brainDeath.comments === ''
                      ? 'Sin comentarios'
                      : suspicion.brainDeath.comments}
                  </Typography>
                </Grid>
                <Grid item xs={12} align="left">
                  <Typography className={classes.disclaimer}>
                    Las imágenes y/o documentos de esta sección se consideran datos sensibles y de carácter confiencial. 
                  </Typography>
                </Grid>
                <Grid item xs={12} align="right">
                  <Can
                    role={user.role}
                    perform={rules.brainDeath.confirm}
                    yes={() =>
                      !suspicion.brainDeath.confirmation.status && (
                        <ConfirmBrainDeathDialog
                          brainDeathId={suspicion.brainDeath.id}
                          suspicionId={suspicion.id}
                        />
                      )
                    }
                  />
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          ) : null}
        </ExpansionPanel>
      </div>
    </>
  );
};

export default BrainDeathDetails;
