import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { useCurrentUser } from '../features/authentication/use-current-user';
import CreateMedicalCenterButton from '../features/medical-centers/create-medical-center-dialog';
import { useAllMedicalCenters } from '../features/medical-centers/use-all-medical-centers';
import MedicalCentersTable from '../features/medical-centers/medical-center-table';
import Can from '../utils/can';
import { rules } from '../utils/rbac-rules';
import FullPageSpinner from '../components/ui/full-page-spinner';

const MedicalCentersPage = () => {
  const { user } = useCurrentUser();
  const { allMedicalCenters, loading } = useAllMedicalCenters();

  if (loading) {
    return <FullPageSpinner />;
  }

  return (
    <>
      <Can
        role={user.role}
        perform={rules.hospitals.get}
        yes={() => (
          <>
            <Grid container align="left">
              <Grid item>
                <Typography variant="h6" component="div">
                  Hospitales
                </Typography>
              </Grid>
              <Grid item>
                <CreateMedicalCenterButton />
              </Grid>
            </Grid>
            <Grid container align="center">
              <MedicalCentersTable medicalCenters={allMedicalCenters} />
            </Grid>
          </>
        )}
        no={() => <Redirect to="/" />}
      />
    </>
  );
};

export default MedicalCentersPage;
