export const suspicionStatus = {
  initial: 'Sospecha',
  active: 'Activo',
  confirmed: 'Confirmado',
  allocated: 'Alocación',
  surgery: 'Cirugía',
  transplanted: 'Trasplantado',
};

export const offeringStatus = {
  accepted: 'Aceptada',
  dismissed: 'Rechazada',
  pending: 'Ofrecida',
  expired: 'Expirada',
};

export const organStatus = {
  pending: 'Pendiente',
  beingAllocated: 'Alocando',
  assigned: 'Asignado',
  transplanted: 'Trasplantado',
  rejected: 'Desestimado',
};

export const surgeryStatus = {
  coordination: 'coordinacion',
  scheduled: 'agendada',
  ongoing: 'enCurso',
  onIschemia: 'enIsquemia',
  done: 'realizada',
};

export const FormatSurgeryStatus = (status) => {
  switch (status) {
    case 'coordinacion':
      return 'Coordinación';
    case 'agendada':
      return 'Agendada';
    case 'aprobada':
      return 'Agendada';
    case 'enCurso':
      return 'En curso';
    case 'enIsquemia':
      return 'En isquemia';
    case 'realizada':
      return 'Realizada';
    default:
  }
};
