import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import ActiveOfferingItem from './active-offering-item';
import ExpiredOfferingItem from './expired-offering-item';

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(3) },
  noItems: { color: theme.palette.text.disabled },
}));

const OfferingsPanel = ({ offerings, expired = false }) => {
  const classes = useStyles();

  return (
    <Grid container justify="flex-start" className={classes.root} spacing={2}>
      {offerings.length > 0 ? (
        offerings.map((offering) => (
          <Grid
            key={offering.id}
            xs={12}
            sm={6}
            item
            data-testid={`offering-${offering.id}`}
          >
            {expired ? (
              <ExpiredOfferingItem offering={offering} />
            ) : (
              <ActiveOfferingItem offering={offering} />
            )}
          </Grid>
        ))
      ) : (
        <Grid item xs={12}>
          <Typography className={classes.noItems} variant="body1">
            No hay ofertas en esta categoría
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default OfferingsPanel;
