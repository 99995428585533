import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

export const ALL_SURGERIES_QUERY = gql`
  query AllSurgeries {
    getSurgeries {
      id
      status
      createdAt
      crossClamp
      medicalCenter {
        id
        name
      }
      suspicion {
        id
        rut
      }
      scheduledAt
      updatedAt
    }
  }
`;

export function useAllSurgeries() {
  const { data, loading, error } = useQuery(ALL_SURGERIES_QUERY);

  return { surgeries: data?.getSurgeries, loading, error };
}
