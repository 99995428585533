import React from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import {
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import ResponsiveDialog from '../../components/ui/responsive-dialog';
import useModal from '../../hooks/useModal';
import { SUSPICION_QUERY } from '../suspicions/use-suspicion';
import { fireSentryEvent, setContextTag } from '../../services/sentry';

export const CONFIRM_INCLUSION_CRITERIA_MUTATION = gql`
  mutation ConfirmInclusionCriteriaMutation($id: ID!) {
    confirmInclusionCriteria(id: $id) {
      confirmation {
        id
        status
      }
    }
  }
`;

const ConfirmInclusionCriteriaButton = ({ inclusionCriteria, suspicion }) => {
  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  const [confirmInclusionCriteria, { loading }] = useMutation(
    CONFIRM_INCLUSION_CRITERIA_MUTATION,
    {
      refetchQueries: [
        {
          query: SUSPICION_QUERY,
          variables: {
            suspicionId: suspicion.id,
          },
        },
      ],
    }
  );
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    try {
      await confirmInclusionCriteria({
        variables: { id: inclusionCriteria.id },
      });
      enqueueSnackbar('Criterios de inclusión confirmados exitosamente', {
        variant: 'success',
      });
      closeModal();
    } catch (error) {
      enqueueSnackbar(
        'Ha ocurrido un error, los criterios de inclusión no se han podido confirmar',
        {
          variant: 'error',
        }
      );
      setContextTag('criterios de inclusion no se pudieron confirmar', error.message);
      fireSentryEvent(error);
    }
  };

  return (
    <>
      <Button
        label="Confirmar Criterios de Inclusión"
        color="primary"
        onClick={openModal}
        variant="contained"
        id="confirm-inclusion-criteria-btn"
      >
        Confirmar
      </Button>
      <ResponsiveDialog
        align="center"
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Confirmar criterios de inclusión
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de querer confirmar los criterios de inclusión de esta sospecha?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} disabled={loading} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    </>
  );
};

export default ConfirmInclusionCriteriaButton;
