import React from 'react';
import FullPageSpinner from '../../../components/ui/full-page-spinner';
import { gql } from 'apollo-boost';
import {
  Button,
  DialogActions,
  makeStyles,
  Box,
  Typography,
  Link,
  Grid,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import DescriptionIcon from '@material-ui/icons/Description';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/react-hooks';
import FormCheckboxField from '../../../components/forms/form-checkbox-field';
import Yup from '../../../utils/validations';
import SurgeryComments from './surgery-comments';
import SurgeryCommentField from './surgery-comment-field';
import { SURGERY_QUERY } from '../use-surgery';

const useStyles = makeStyles((theme) => ({
  confirm: {
    gridArea: 'confirm',
    justifySelf: 'right'
  },
  columnroot: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column'
  },
  rowroot: {
    padding: theme.spacing(1),
    display: 'flex',
    flexwrap: 'nowrap',
    flexDirection: 'row',
    width: '100%',
  },
  width: {
    width: '100%',
    maxWidth: '600px',
    marginLeft: '30px',
  },
  history: {
    marginLeft: '30px',
    maxHeight: '380px',
    width: '100%',
    '& > *': {
      height: '100%',
      padding: theme.spacing(1),
      overflow: 'scroll'
    },
  },
  formControl: {
    margin: theme.spacing(2, 0),
    minWidth: 275,
  },
}));

export const APROVE_SURGERY_MUTATION = gql`
  mutation aproveSurgery($surgeryId: ID!) {
    aproveSurgery(surgeryId: $surgeryId) {
      id
      status
      crossClamp
    }
  }
`;


const extractionConfirmationSchema = Yup.object({
  muerteCerebral: Yup.boolean().oneOf([true], 'Muerte cerebral debe estar al día'),
  actaDonacion: Yup.boolean().oneOf([true], 'Acta de donación debe estar al día'),
  fichaClinica: Yup.boolean().oneOf([true], 'Ficha clínica debe estar al día'),
});

const SurgeryConfirmationStep = ({ surgery, organsList, handleStep, completed, steps, activeStep }) => {

  const next_status = ["aprobada", "enCurso", "enIsquemia", "realizada"];
  const [currentOrgan, setOrgan] = React.useState(organsList[0]);
  const suspicion = surgery.suspicion;


  const [aproveSurgery, { loading }] = useMutation(
    APROVE_SURGERY_MUTATION,
    {
      refetchQueries: [
        {
          query: SURGERY_QUERY,
          variables: {
            surgeryId: surgery.id,
          },
        },
      ],
    }
  );

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const handleSubmit = async (values, { resetForm }) => {

    handleStep()

    try {
      await aproveSurgery({
        variables: { surgeryId: surgery.id },
      });
      enqueueSnackbar('Cirugía aprobada exitosamente', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Hubo un error al procesar su solicitud.', { variant: 'error' });
    }
  };


  if (loading) {
    return <FullPageSpinner />;
  }

  return (
    <Grid className={classes.rowroot}>
      <Grid className={classes.width}>
        <Typography  variant="h6">
          Paso 1: Confirmación de cirugía
        </Typography>
        <Typography >Confirmar antecedentes del donante</Typography>
        <Select
          className={classes.formControl}
          value={currentOrgan}
          onChange={(event) => {
            setOrgan(event.target.value);
          }}
        >
          {organsList.map((organ) => (
            <MenuItem key={organ.id} value={organ}>
              {organ.organType.name}
            </MenuItem>
          ))}
        </Select> 
        <Formik
          initialValues={{
            muerteCerebral: false,
            actaDonacion: false,
            fichaClinica: false,
          }}
          onSubmit={handleSubmit}
          validationSchema={extractionConfirmationSchema}
        >
          {({ submitForm }) => (
            <Form>
              <Grid>
                <Box className={classes.columnroot}>
                <Grid container align="left">
                  <FormCheckboxField 
                    label="Muerte cerebral"
                    name="muerteCerebral"
                    disabled={completed[activeStep] || surgery.status !== "agendada"}
                    defaultChecked={next_status.includes(surgery.status)}
                  />
                  <Grid item xs={2} align="center">
                    <Link href={suspicion.brainDeath.document}>
                      <Button disabled={!suspicion.brainDeath.document}>
                        <DescriptionIcon
                          className={
                            suspicion.brainDeath.document
                              ? classes.download
                              : classes.disableDownload
                          }
                        />
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item xs={2} align="center">
                    <Typography className={classes.iconLabel}>Documento</Typography>
                  </Grid>
                  </Grid>

                  <Grid container align="left">
                  <FormCheckboxField 
                    label="Ficha clínica"
                    name="fichaClinica"
                    disabled={completed[activeStep] || surgery.status !== "agendada"}
                    defaultChecked={next_status.includes(surgery.status)}
                  />
                  <Grid item xs={2} align="center">
                    <Link href={suspicion.medicalRecord.clinicalSummary}>
                      <Button disabled={!suspicion.medicalRecord.clinicalSummary}>
                        <DescriptionIcon
                          className={
                            suspicion.medicalRecord.clinicalSummary
                              ? classes.download
                              : classes.disableDownload
                          } 
                        />
                      </Button>
                    </Link>
                  </Grid>
                  
                  <Grid item xs={2} align="center">
                    <Typography className={classes.iconLabelLong}>
                      Resumen Clínico
                    </Typography>
                  </Grid>
                  </Grid>
                  
                  <Grid container justify="left">
                  <FormCheckboxField 
                    label="Acta de donación"
                    name="actaDonacion"
                    disabled={completed[activeStep] || surgery.status !== "agendada"}
                    defaultChecked={next_status.includes(surgery.status)}
                  /> 
                  <Grid item xs={2} align="center">
                    <Link href={suspicion.familyActivation.donationCertificate}>
                      <Button disabled={!suspicion.familyActivation.donationCertificate}>
                        <DescriptionIcon
                          className={
                            suspicion.familyActivation.donationCertificate
                              ? classes.download
                              : classes.disableDownload
                          }
                        />
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item xs={2} align="center">
                    <Typography className={classes.iconLabel}>
                      Acta de donación
                    </Typography>
                  </Grid>
                </Grid>
                </Box>  
              </Grid>
              <DialogActions>
                <Button
                  onClick={submitForm}
                  disabled={completed[activeStep] || surgery.status !== "agendada"}
                  color="primary"
                  type="submit"
                  variant="contained"
                >
                  {next_status.includes(surgery.status) ? 'Confirmada' : 'Confirmar'}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>

        <SurgeryCommentField organ={currentOrgan} type='donorBackground'/>

      </Grid>
      <Grid className={classes.history}>
        {organsList[0] ?
          <SurgeryComments organ={currentOrgan} type='donorBackground'/>
          :
          <Typography variant="h6">
            No hay comentarios previos a la cirugía aún
          </Typography>
        }
      </Grid>
    </Grid>
    
  );
};

export default SurgeryConfirmationStep;
