import { useMutation } from '@apollo/react-hooks';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { gql } from 'apollo-boost';
import { useSnackbar } from 'notistack';
import React from 'react';
import ResponsiveDialog from '../../components/ui/responsive-dialog';
import useModal from '../../hooks/useModal';
import { SUSPICION_QUERY } from '../suspicions/use-suspicion';
import { fireSentryEvent, setContextTag } from '../../services/sentry';


export const CONFIRM_BRAIN_DEATH_MUTATION = gql`
  mutation ConfirmBrainDeath($id: ID!) {
    confirmBrainDeath(id: $id) {
      confirmation{
        id
        status
      }

    }
  }
`;


const ConfirmBrainDeathDialog = ({ brainDeathId, suspicionId }) => {
  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  const [confirmBrainDeath, { loading }] = useMutation(CONFIRM_BRAIN_DEATH_MUTATION, {
    refetchQueries: [
      {
        query: SUSPICION_QUERY,
        variables: {
          suspicionId: suspicionId,
        },
      },
    ],
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    try {
      await confirmBrainDeath({
        variables: { id: brainDeathId },
      });
      enqueueSnackbar('Confirmación de muerte cerebral creada exitosamente', {
        variant: 'success',
      });
      closeModal();
    } catch (error) {
      enqueueSnackbar('Hubo un error, la muerte cerebral no se pudo confirmar.', { variant: 'error' });
      setContextTag('muerte cerebral no se pudo confirmar', error.message);
      fireSentryEvent(error);
    }

  };

  return (
    <>
      <Button
        label="Confirmar muerte cerebral"
        color="primary"
        onClick={openModal}
        variant="contained"
        id="confirm-brain-death-btn"
      >
        Confirmar
      </Button>

      <ResponsiveDialog
        align="center"
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Confirmar muerte cerebral
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de querer confirmar la muerte cerebral de este paciente?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} disabled={loading} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    </>
  );
};

export default ConfirmBrainDeathDialog;
