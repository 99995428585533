import React, { useEffect } from 'react';
import { Paper, Typography, makeStyles, Grid, Box, Divider, Button } from '@material-ui/core';
import { addHours } from 'date-fns';

import FullPageSpinner from '../../components/ui/full-page-spinner';
import { valueFromASTUntyped } from 'graphql';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select'

import OfferingsProcureStatsDetail from './offerings-procure-detail';

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(2) },
  title: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.h6.fontWeight,
  },
  header: { display: 'flex', justifyContent: 'space-between' },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  infoItem: {
    display: 'flex',
    alignItems: 'center',
  },
  infoItemTitle: {
    flex: 1,
    fontWeight: theme.typography.h6.fontWeight,
    color: theme.palette.text.hint,
  },
  newReciever: {
    height: '22px',
    marginBottom: '5px',
    marginLeft: '8px',
  },
  timeLeft: { fontSize: theme.typography.h6.fontSize },
 
}));


const SuspicionTab = ({ medicalCenters }) => {

 
  
  const regions = ['Región de Arica y Parinacota',
    'Región de Tarapacá',
    'Región de Antofagasta',
    'Región de Atacama',
    'Región de Coquimbo',
    'Región de Valparaíso',
    'Región Metropolitana de Santiago',
    'Región del Libertador General Bernardo O’Higgins',
    'Región del Maule',
    'Región del Ñuble',
    'Región del Biobío',
    'Región de La Araucanía',
    'Región de Los Ríos',
    'Región de Los Lagos',
    'Región de Aysén del General Carlos Ibáñez del Campo',
    'Región de Magallanes y la Antártica Chilena']


  const total = [
    'total'
  ]

  const classes = useStyles();
  const [value, setValue] = React.useState('region');
  const [options, setOptions] = React.useState(regions);


  let buttonColor = '#A5ADCF'

  let computing = true;
  let medicalCenterNames = medicalCenters.map(medicalCenter => medicalCenter.name)
  computing = false;

  const handleRadioChange = (event) => {
    setValue(event.target.value);
    
    if (event.target.value == 'medicalCenter'){
      setOptions(medicalCenterNames)
    } else if (event.target.value == 'region'){
      setOptions(regions)
    } else {
      setOptions(total)
    }
  };

  if (computing){
    return <FullPageSpinner></FullPageSpinner>
  }

  return (
    <Paper elevation={3} className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography className={classes.title} color="primary">
              Estadísticas de Sospechas
            </Typography>
          </Box>
          
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
            <Box className={classes.infoItem}>
            <Typography variant="subtitle1" className={classes.infoItemTitle}>
                Agrupar por:
            </Typography>
          </Box>
          <Box className={classes.infoItem}>
            <RadioGroup aria-label="quiz" name="quiz" value={value} onChange={handleRadioChange} row={true}>
                <FormControlLabel value="medicalCenter" control={<Radio />} label="Centro Médico" />
                <FormControlLabel value="region" control={<Radio />} label="Región" />
                <FormControlLabel value="total" control={<Radio />} label="Totales" />
            </RadioGroup>
          </Box>
        </Grid>
        <OfferingsProcureStatsDetail options={options} groupBy={value}></OfferingsProcureStatsDetail>
      </Grid>
    </Paper>
  );
};

export default SuspicionTab;