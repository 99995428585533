import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

export const GET_ORGAN_GROUP = gql`
  query getOrganGroup($groupId: ID!) {
    getOrganGroup(id: $groupId) {
      id
      medicalCenterId
      organType {
        name
      }
      medicalCenter {
        name
      }
    }
  }
`;

export const useOrganGroup = (groupId) => {
  const { data, loading, error } = useQuery(GET_ORGAN_GROUP, {
    variables: { groupId },
  });

  return { organGroup: data?.getOrganGroup, loading, error };
};
