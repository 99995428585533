import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useCurrentUser } from '../features/authentication/use-current-user';
import UserProfile from '../features/users/user-profile';
import FullPageSpinner from '../components/ui/full-page-spinner';

const SettingsPage = () => {
  const { user, loading } = useCurrentUser();

  if (loading) {
    return <FullPageSpinner />;
  }

  return (
    <>
      <Grid container align="left">
        <Grid item>
          <Typography variant="h6" component="div">
            Configuración
          </Typography>
        </Grid>
      </Grid>
      <Grid container align="center">
        <UserProfile currentUser={user} />
      </Grid>
    </>
  );
};

export default SettingsPage;
