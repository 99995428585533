import { Button } from '@material-ui/core';
import React from 'react';
import useModal from '../../hooks/useModal';
import CreateAllocationDialog from './create-allocation-dialog';

const CreateAllocationButton = ({ organ }) => {
  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  return (
    <>
      <Button
        label="Alocar órgano"
        size="small"
        color="secondary"
        variant="contained"
        onClick={openModal}
        id="create-allocation-button"
      >
        Ofertar
      </Button>
      <CreateAllocationDialog organ={organ} open={isModalOpen} onClose={closeModal} />
    </>
  );
};

export default CreateAllocationButton;
