import React, { useState } from 'react';
import { gql } from 'apollo-boost';
import {
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { TimePicker } from '@material-ui/pickers';
import { useMutation } from '@apollo/react-hooks';
import { fireSentryEvent, setContextTag } from '../../../services/sentry';
import ResponsiveDialog from '../../../components/ui/responsive-dialog';
import useModal from '../../../hooks/useModal';
import { SURGERY_QUERY } from '../use-surgery';

export const SET_REPERFUSION_TIME_MUTATION = gql`
  mutation setReperfusionTime($id: ID!, $time: Date!) {
    setReperfusionTime(id: $id, time: $time) {
      id
      reperfusionTime
    }
  }
`;

const ConfirmReperfusionTimeButton = ({ organ, setShowTime, surgery }) => {
  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  let finalSchedule = new Date();
  const [selectedDate, handleDateChange] = useState(finalSchedule);
  // const [setReperfusionTime, { loading }] = useMutation(SET_REPERFUSION_TIME_MUTATION);
  const [setReperfusionTime, { loading }] = useMutation(SET_REPERFUSION_TIME_MUTATION, {
    refetchQueries: [{ query: SURGERY_QUERY, variables: { surgeryId: surgery.id } }],
  });

  const handleSubmit = async () => {
    closeModal();
    try {
      const { data } = await setReperfusionTime({
        variables: { id: organ.id, time: selectedDate },
      });
      enqueueSnackbar('Tiempo de reperfusión confirmado correctamente', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Hubo un error al confirmar el tiempo de reperfusión', {
        variant: 'error',
      });
      setContextTag('error al confirmar tiempo de reperfusión', error.message);
      fireSentryEvent(error);
    }
  };

  return (
    <>
      {/* <Tooltip title="Esta funcionalidad aun no esta lista"> */}
      <Button
        aria-label="Confirmar tiempo de reperfusión"
        size="large"
        variant="contained"
        color="primary"
        onClick={openModal}
        id="confirm-team-schedule"
      >
        Confirmar Tiempo de Reperfusión
      </Button>
      {/* </Tooltip> */}
      <ResponsiveDialog
        align="center"
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          ¿Confirmar Tiempo de Reperfusión?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TimePicker
              label="Tiempo de Reperfusión"
              variant="inline"
              placeholder="08:00 AM"
              mask="__:__ _M"
              value={selectedDate}
              onChange={date => handleDateChange(date)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="secondary">
            Cancelar
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={loading}
            color="primary"
            variant="contained"
            type="submit"
          >
            Confirmar
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    </>
  );
};

export default ConfirmReperfusionTimeButton;
