import React from 'react';
import { useField } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.text.secondary,
    },
  },
}));

const FormTextField = ({ label, name, ...props }) => {
  const [fieldProps, meta] = useField(name);
  const classes = useStyles();

  return (
    <TextField
      className={classes.root}
      label={label}
      id={label}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error}
      {...fieldProps}
      {...props}
    />
  );
};

FormTextField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default FormTextField;
