import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { SUSPICION_QUERY } from './use-suspicion';
import { CONFIRMED_SUSPICIONS_QUERY } from '../organs/use-confirmed-suspicions';
import { ALL_SUSPICIONS_QUERY } from './use-all-suspicions';

export const CONFIRM_SUSPICION_MUTATION = gql`
  mutation ConfirmSuspicion($suspicionId: ID!, $organTypeIds: [ID!]) {
    confirmSuspicion(id: $suspicionId, organTypeIds: $organTypeIds) {
      id
      status
    }
  }
`;

export function useConfirmSuspicion({ suspicionId }) {
  const mutationTuple = useMutation(CONFIRM_SUSPICION_MUTATION, {
    refetchQueries: [
      { query: SUSPICION_QUERY, variables: { suspicionId } },
      { query: CONFIRMED_SUSPICIONS_QUERY },
      { query: ALL_SUSPICIONS_QUERY },
    ],
  });

  return mutationTuple;
}
