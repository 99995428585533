import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

export const SUSPICION_QUERY = gql`
  query SuspicionDetailQuery($suspicionId: ID!) {
    getSuspicion(id: $suspicionId) {
      id
      creator {
        firstName
        lastName
      }
      createdAt
      rut
      sex
      description
      confirmed
      active
      status
      confirmation {
        status
      }
      medicalCenter {
        id
        name
      }
      background {
        id
        createdAt
        age
        glasgow
        diagnostic
        comment
        creator {
          firstName
          lastName
        }
        confirmation {
          id
          status
          updatedAt
          user {
            firstName
            lastName
          }
        }
      }
      inclusionCriteria {
        id
        createdAt
        vih
        vhb
        vhc
        hasSepsis
        hasCancer
        confirmation {
          id
          status
          updatedAt
          user {
            firstName
            lastName
          }
        }
        creator {
          firstName
          lastName
        }
        comments
      }
      brainDeath {
        id
        createdAt
        document
        comments
        confirmation {
          id
          status
          updatedAt
          user {
            firstName
            lastName
          }
        }
        creator {
          firstName
          lastName
        }
      }
      familyActivation {
        id
        createdAt
        donationCertificate
        comments
        confirmation {
          id
          status
          updatedAt
          user {
            firstName
            lastName
          }
        }
        creator {
          firstName
          lastName
        }
      }
      medicalRecord {
        id
        createdAt
        updatedAt
        laboratory
        clinicalSummary
        comments
        confirmation {
          id
          status
          updatedAt
          user {
            firstName
            lastName
          }
        }
        creator {
          firstName
          lastName
        }
      }
      history {
        message
        date
        author {
          firstName
          lastName
        }
      }
      organs {
        id
        organType {
          id
          name
          description
          ischemiaTime
        }
        crossClamp
        ischemia
        status
        originMedicalCenter {
          id
          name
        }
        # Este destinationMedicalCenter no va, esta bien así comentado
        # destinationMedicalCenter {
        #   id
        #   name
        # }
        createdAt
        updatedAt
      }
      enableToConfirm {
        authorization
      }
    }
  }
`;

export const useSuspicion = (suspicionId) => {
  const { data, loading, error } = useQuery(SUSPICION_QUERY, {
    variables: { suspicionId },
  });
  return { suspicion: data?.getSuspicion, loading, error };
};
