import React from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import { Formik, Form } from 'formik';
import {
  Tooltip,
  Button,
  DialogContent,
  DialogTitle,
  DialogActions,
  Container,
  makeStyles,
  Grid,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import { fireSentryEvent, setContextTag } from '../../services/sentry';
import ResponsiveDialog from '../../components/ui/responsive-dialog';
import useModal from '../../hooks/useModal';
import FormSingleAutocomplete from '../../components/forms/form-single-autocomplete';
import FullPageSpinner from '../../components/ui/full-page-spinner';
import { TEAM_MEMBERS_QUERY, useTeamMembers } from './use-team-members';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  formControl: {
    margin: theme.spacing(2, 0),
    minWidth: 275,
  },
  asignBossButton: {
    color: theme.palette.primary,
  },
}));

export const SET_BOSS = gql`
  mutation setGroupMemberAsBoss($setGroupMemberAsBossInput: setGroupMemberAsBossInput!) {
    setGroupMemberAsBoss(setGroupMemberAsBossInput: $setGroupMemberAsBossInput) {
      groupId
      userId
    }
  }
`;

const AssignBossButton = ({ groupId }) => {
  const { teamMembers, loadingUserGroups: loading } = useTeamMembers(groupId);
  // const { user } = useCurrentUser();
  const [setGroupMemberAsBoss] = useMutation(SET_BOSS, {
    refetchQueries: [
      {
        query: TEAM_MEMBERS_QUERY,
        variables: {
          groupId,
        },
      },
    ],
  });

  const candidates = teamMembers;
  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleSubmit = async (values, { resetForm }) => {
    try {
      await setGroupMemberAsBoss({
        variables: {
          setGroupMemberAsBossInput: { organGroupId: groupId, userId: values.candidate },
        },
      });

      enqueueSnackbar('Usuario ha sido asignado como jefe satisfactoriamente', {
        variant: 'success',
      });
      resetForm({});
      closeModal();
    } catch (err) {
      enqueueSnackbar('Ha ocurrido un error, usuario no fue asignado como jefe', {
        variant: 'error',
      });
      setContextTag('error al asignar usuario como jefe', err.message);
      fireSentryEvent(err);
    }
  };
  if (loading) {
    return <FullPageSpinner />;
  }

  return (
    <>
      <Tooltip title="Asignar a un jefe de entre los usuarios">
        <Button
          aria-label="Asignar jefe de equipo"
          onClick={openModal}
          color="primary"
          id="delete-user-btn"
          variant="contained"
          disabled={false}
          className={classes.assignProcurerBossButton}
          starticon={<UnfoldLessIcon />}
        >
          Asignar jefe de equipo
        </Button>
      </Tooltip>
      <Formik
        initialValues={{
          candidate: undefined,
        }}
        onSubmit={handleSubmit}
      >
        {({ submitForm }) => (
          <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
              <Form className={classes.form}>
                <ResponsiveDialog
                  open={isModalOpen}
                  onClose={closeModal}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    Asignar jefe para el equipo
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormSingleAutocomplete
                          name="candidate"
                          label="Elige un usuario"
                          options={
                            candidates.length > 0
                              ? candidates
                              : ['No hay usuarios en este equipo']
                          }
                          getOptionLabel={
                            candidates.length > 0
                              ? (option) =>
                                  `${option.user.firstName} ${option.user.lastName}`
                              : (option) => option
                          }
                          renderOption={
                            candidates.length > 0
                              ? (option) =>
                                  `${option.user.firstName} ${option.user.lastName}`
                              : (option) => option
                          }
                          getValueFromOption={
                            candidates.length > 0
                              ? (option) => option.userId
                              : (option) => option
                          }
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={closeModal} color="secondary">
                      Cancelar
                    </Button>
                    <Button
                      onClick={submitForm}
                      disabled={loading}
                      color="primary"
                      type="submit"
                    >
                      Asignar jefe
                    </Button>
                  </DialogActions>
                </ResponsiveDialog>
              </Form>
            </div>
          </Container>
        )}
      </Formik>
    </>
  );
};

export default AssignBossButton;
