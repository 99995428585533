import React, { useEffect } from 'react';
import { Paper, Typography, makeStyles, Grid, Box, Divider, Button } from '@material-ui/core';
import { addHours } from 'date-fns';

import FullPageSpinner from '../../components/ui/full-page-spinner';
import { valueFromASTUntyped } from 'graphql';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select'

import SuspicionStatsDetail from './suspicion-detail'
import useSuspicionDashboardAll from './use-suspicion-dashboard-all';
import useSuspicionDashboardRegion from './use-suspicion-dashboard-region';
import useSuspicionDashboardMedicalCenter from './use-suspicion-dashboard-medicalcenter';



const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(2) },
  title: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.h6.fontWeight,
  },
  header: { display: 'flex', justifyContent: 'space-between' },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  infoItem: {
    display: 'flex',
    alignItems: 'center',
  },
  infoItemTitle: {
    flex: 1,
    fontWeight: theme.typography.h6.fontWeight,
    color: theme.palette.text.hint,
  },
  newReciever: {
    height: '22px',
    marginBottom: '5px',
    marginLeft: '8px',
  },
  timeLeft: { fontSize: theme.typography.h6.fontSize },
 
}));


const SuspicionTab = ({ medicalCenters }) => {

  const classes = useStyles();


   // Vamos a hacer las 3 querys por simultáneo, y vamos a sacar las regiones y medical centers para entregárselos
  // a cada uno de ellos 

  const regions = []
  
  var medicalCenterNames = []
  var medicalCentersStats = []

  var regionNames = []
  var regionStats = []

  var totalStats = []

  const [value, setValue] = React.useState('');
  const [options, setOptions] = React.useState(regions);
  const [data, setData] = React.useState([]);

  const { dataAll, loading : loadingTotal  } = useSuspicionDashboardAll();
  const { dataRegion, loading : loadingRegion  } = useSuspicionDashboardRegion();
  const { dataMedicalCenters, loading : loadingMedicalCenter } = useSuspicionDashboardMedicalCenter();

  // var dataAll = JSON.parse(dataAllStr)

  let computing = true;

  if (!loadingRegion && !loadingMedicalCenter){

    // parce of the list of Medical Centers 
    
    for (let i = 0; i < dataMedicalCenters.length; i ++){
      medicalCentersStats.push(JSON.parse(dataMedicalCenters[i]))
    }
    medicalCenterNames = medicalCentersStats.map(medicalCenter => medicalCenter.name)

    // parce list of regions 

    for (let i = 0; i < dataRegion.length; i ++){
      regionStats.push(JSON.parse(dataRegion[i]))
    }
    regionNames = regionStats.map(region => region.name)

    // totales
    console.log(dataAll)
    totalStats.push(JSON.parse(dataAll[0]))

    computing = false;
  }
  

  let buttonColor = '#A5ADCF'
  
  const handleRadioChange = (event) => {
    setValue(event.target.value);
    
    if (event.target.value == 'medicalCenter'){
      setOptions(medicalCenterNames)
      setData(medicalCentersStats)
    } else if (event.target.value == 'region'){
      setOptions(regionNames)
      setData(regionStats)
    } else {
      setOptions(['total'])
      setData(totalStats)
    }
  };

  if (computing || loadingRegion || loadingMedicalCenter || loadingTotal){
    return <FullPageSpinner></FullPageSpinner>
  }

  return (
    <Paper elevation={3} className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography className={classes.title} color="primary">
              Estadísticas de Sospechas
            </Typography>
          </Box>
          
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
            <Box className={classes.infoItem}>
            <Typography variant="subtitle1" className={classes.infoItemTitle}>
                Agrupar por:
            </Typography>
          </Box>
          <Box className={classes.infoItem}>
            <RadioGroup aria-label="quiz" name="quiz" value={value} onChange={handleRadioChange} row={true}>
                <FormControlLabel value="medicalCenter" control={<Radio />} label="Centro Médico" />
                <FormControlLabel value="region" control={<Radio />} label="Región" />
                <FormControlLabel value="total" control={<Radio />} label="Totales" />
            </RadioGroup>
          </Box>
        </Grid>
        <SuspicionStatsDetail data={data} options={options} groupBy={value}></SuspicionStatsDetail>
      </Grid>
    </Paper>
  );
};

export default SuspicionTab;