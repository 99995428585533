import React, { useState } from 'react';
import { AppBar, IconButton, makeStyles, Toolbar, Box } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import ResponsiveDrawer from './components/ui/responsive-drawer';
import AppRoutes from './routes';
import HorizontalTypographicLogoComponent from './components/ui/horizontal-logo';
import Profile from './components/ui/profile';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100%)`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  profile: {
    position: 'absolute',
    top: 10,
    right: 15,
  },
}));

const AuthenticatedApp = () => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="abrir menu lateral"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Box>
            <HorizontalTypographicLogoComponent />
          </Box>
          <Box className={classes.profile}>
            <Profile />
          </Box>
        </Toolbar>
      </AppBar>
      <ResponsiveDrawer mobileOpen={mobileOpen} onDrawerToggle={handleDrawerToggle} />

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <AppRoutes />
      </main>
    </div>
  );
};

AuthenticatedApp.propTypes = {};

export default AuthenticatedApp;
