import { fireSentryEvent, setContextTag } from '../../services/sentry';
import React from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import {
  Tooltip,
  Button,
  IconButton,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@material-ui/icons/Delete';
import ResponsiveDialog from '../../components/ui/responsive-dialog';
import useModal from '../../hooks/useModal';
import { TEAM_MEMBERS_QUERY } from './use-team-members';

export const DELETE_GROUP_MEMBER_MUTATION = gql`
  mutation deleteGroupMember($deleteGroupMemberInput: deleteGroupMemberInput!) {
    deleteGroupMember(deleteGroupMemberInput: $deleteGroupMemberInput)
  }
`;

const DeleteGroupMemberDialog = ({ data }) => {
  const { userId } = data;
  const { groupId } = data;
  const { boss } = data;
  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteGroupMember, { loading }] = useMutation(DELETE_GROUP_MEMBER_MUTATION , {
    refetchQueries: [
      {
        query: TEAM_MEMBERS_QUERY,
        variables: {
          groupId,
        },
      },
    ],
  });

  const handleSubmit = async () => {
    closeModal();
    if (boss) {
      enqueueSnackbar('No puedes eliminar al jefe del grupo', {
        variant: 'error',
      });
    } else {
      try {
        await deleteGroupMember({
          variables: {
            deleteGroupMemberInput: { organGroupId: groupId, userId },
          },
        });
        enqueueSnackbar('Usuario eliminado exitosamente', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Hubo un error al procesar su solicitud', {
          variant: 'error',
        });
        setContextTag('error al eliminar usuario del equipo', error.message);
        fireSentryEvent(error);
      }
    }
  };

  return (
    <>
      <Tooltip title="Eliminar usuario">
        <IconButton
          aria-label="Eliminar usuario"
          size="small"
          color="primary"
          onClick={openModal}
          id="delete-user-btn"
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <ResponsiveDialog
        align="center"
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Confirmar eliminación de usuario del grupo {groupId}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de querer eliminar al usuario de este grupo de manera
            permanente?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="secondary">
            Cancelar
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={loading}
            color="primary"
            variant="contained"
            type="submit"
          >
            Eliminar
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    </>
  );
};

export default DeleteGroupMemberDialog;
