import React from 'react';

import { Typography } from '@material-ui/core';
import FullPageSpinner from '../../components/ui/full-page-spinner';
import { useMedicalCenter } from './use-medical-center';

const MedicalCenterName = ({ id }) => {
  const { medicalCenter, loading: loadingMedicalCenter } = useMedicalCenter(id);

  if (loadingMedicalCenter) {
    return <FullPageSpinner />;
  }

  return <Typography>{medicalCenter.name}</Typography>;
};

export default MedicalCenterName;
