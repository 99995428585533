import React from 'react';
import {
  Box,
  makeStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  useTheme,
  Grid,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { organStatus } from '../../constants/status';
import ScheduleSurgeryButton from './schedule-surgery-button';
import StatusIcon from '../../components/ui/status-icon';
import OrgansList from './organs-list';

const useStyles = makeStyles((theme) => ({
  detail: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  rut: { flex: 1 },
  left: {
    flex: 4,
    display: 'flex',
    alignItems: 'center',
  },
  right: {
    flex: 4,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  hospital: { marginLeft: theme.spacing(2), marginRight: 'auto' },
  organ: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  name: { marginLeft: theme.spacing(-0.5) },
  expansionPanelDetails: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(2),
  },
}));

const PatientItem = ({ suspicion, isExpanded, expand }) => {
  const readyForSurgery = suspicion.organs.every(
    ({ status }) => status === organStatus.assigned
  );

  const classes = useStyles();
  const theme = useTheme();

  return (
    <ExpansionPanel expanded={isExpanded} key={suspicion.id}>
      <ExpansionPanelSummary
        onClick={expand}
        expandIcon={
          <ExpandMoreIcon
            label="Ver detalles órgano"
            data-testid="details-organ-button"
          />
        }
        label={suspicion.rut}
      >
        <Box className={classes.detail}>
          <Typography className={classes.rut} variant="body2">
            {suspicion.rut}
          </Typography>
          <Box className={classes.left}>
            <StatusIcon
              color={
                readyForSurgery ? theme.palette.success.main : theme.palette.warning.main
              }
            />
            <Typography className={classes.hospital} variant="body2">
              {suspicion.medicalCenter.name}
            </Typography>
          </Box>
          {readyForSurgery && !suspicion.surgery ? (
            <Box className={classes.right}>
              <ScheduleSurgeryButton suspicion={suspicion} />
            </Box>
          ) : (
            <Box className={classes.right}>
              {suspicion.organs.map(({ id, organType, status }) => (
                <Box key={id} className={classes.organ}>
                  <StatusIcon
                    color={
                      status !== organStatus.assigned
                        ? theme.palette.warning.main
                        : theme.palette.success.main
                    }
                  />
                  <Typography className={classes.name} variant="caption">
                    {organType.name}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.expansionPanelDetails}>
        <Grid container spacing={3}>
          <OrgansList organs={suspicion.organs} />
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default PatientItem;
