import { useMutation } from '@apollo/react-hooks';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { gql } from 'apollo-boost';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React from 'react';
import FormDropzoneArea from '../../components/forms/form-dropzone-area';
import FormTextField from '../../components/forms/form-text-field';
import ResponsiveDialog from '../../components/ui/responsive-dialog';
import Yup from '../../utils/validations';
import { SUSPICION_QUERY } from '../suspicions/use-suspicion';
import { fireSentryEvent, setContextTag } from '../../services/sentry';

const SUPPORTED_FORMATS = [
  'image/jpeg',
  'image/png',
  'application/pdf',
  '.xls',
  '.xlsx',
  '.doc',
  '.docx',
];

const confirmFamilyActivationSchema = Yup.object({
  donationCertificate: Yup.mixed().required('Archivo requerido'),
  comments: Yup.string().optional(),
  suspicionId: Yup.number().required(),
});

export const CREATE_FAMILY_ACTIVATION_MUTATION = gql`
  mutation CreateFamilyActivation($familyActivationInput: FamilyActivationInput!) {
    createFamilyActivation(familyActivationInput: $familyActivationInput) {
      id
      donationCertificate
      comments
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  extensions: {
    display: 'block',
    textAlign: 'right',
    color: theme.palette.text.disabled,
  },
}));

const ConfirmFamilyActivationDialog = ({ suspicionId, open, onClose }) => {
  const classes = useStyles();
  const [confirmFamilyActivation, { loading }] = useMutation(
    CREATE_FAMILY_ACTIVATION_MUTATION,
    {
      refetchQueries: [
        {
          query: SUSPICION_QUERY,
          variables: {
            suspicionId,
          },
        },
      ],
    }
  );

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (values, { resetForm }) => {
    try {
      await confirmFamilyActivation({
        variables: { familyActivationInput: values },
      });
      enqueueSnackbar('Confirmación de activación familiar creada exitosamente', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Hubo un error al procesar su solicitud.', { variant: 'error' });
      setContextTag('error solicitud activacion familiar', error.message);
      fireSentryEvent(error);
    }
    resetForm({});
    onClose();
  };

  return (
    <Formik
      initialValues={{
        donationCertificate: null,
        comments: '',
        suspicionId,
      }}
      onSubmit={handleSubmit}
      validationSchema={confirmFamilyActivationSchema}
    >
      {({ submitForm }) => (
        <Form>
          <ResponsiveDialog
            open={open}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              Confirmar activación familiar
            </DialogTitle>
            <DialogContent>
              <DialogContentText>Completa el formulario con los datos.</DialogContentText>
              <div style={{ display: 'flex' }}>
                <FormDropzoneArea
                  name="donationCertificate"
                  label="Acta de donación"
                  filesLimit={1}
                  acceptedFiles={SUPPORTED_FORMATS}
                />
              </div>
              <Typography className={classes.extensions} variant="caption">
                Extensiones permitidas: pdf, jpg, png, xls, doc, xlsx, docx
              </Typography>
              <FormTextField
                name="comments"
                label="Comentarios"
                multiline
                rows={4}
                fullWidth
                onFocus={(event) => event.stopPropagation()}
              />
            </DialogContent>
            <DialogActions>
              {loading && <CircularProgress color="inherit" data-testid="loading-spinner" />}
              <Button onClick={onClose} color="secondary">
                Cancelar
              </Button>
              <Button
                onClick={submitForm}
                disabled={loading}
                color="primary"
                type="submit"
                variant="contained"
              >
                Ingresar
              </Button>
            </DialogActions>
          </ResponsiveDialog>
        </Form>
      )}
    </Formik>
  );
};

export default ConfirmFamilyActivationDialog;
