import React from 'react';
import {
  Typography,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useCurrentUser } from '../authentication/use-current-user';
import Can from '../../utils/can';
import { rules } from '../../utils/rbac-rules';
import DenySuspicionButton from './deny-suspicion-button';
import CustomStepper from '../../components/ui/custom-stepper';
import { suspicionStatus } from '../../constants/status';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.primary.main,
    fontWeight: 500,
    textTransform: 'uppercase',
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: 'lightgrey',
  },
  subtitle: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  details: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    opacity: '0.6',
  },
  description: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    lineHeight: '20px',
    letterSpacing: '0.25px',
    opacity: '0.6',
  },
}));

const ProfileUpComponent = ({ suspicion }) => {
  const classes = useStyles();
  const { user } = useCurrentUser();

  return (
    <Paper elevation={3} className={classes.paper}>
      <Table>
        <TableHead className={classes.header}>
          <TableRow>
            <StyledTableCell style={{ width: '20%' }}>Paciente</StyledTableCell>
            <StyledTableCell style={{ width: '30%' }}>Levantado por</StyledTableCell>
            <StyledTableCell style={{ width: '40%' }}>Estado</StyledTableCell>
            <StyledTableCell style={{ width: '10%' }}>Acciones</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <StyledTableCell>
              <Typography variant="subtitle2">{suspicion.rut}</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="subtitle2">
                Dr. {suspicion.creator.firstName} {suspicion.creator.lastName}
              </Typography>
              <Typography variant="body2">
                {suspicion.description || 'Sin comentarios adicionales'}
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <CustomStepper
                steps={Object.values(suspicionStatus)}
                activeStep={suspicion.status}
                data-testid="suspicion-stepper"
              />
            </StyledTableCell>
            <StyledTableCell>
              <Can
                role={user.role}
                perform={rules.suspicions.delete}
                yes={() =>
                  [suspicionStatus.active, suspicionStatus.initial].includes(
                    suspicion.status
                  ) ? (
                    <DenySuspicionButton suspicionInfo={suspicion} />
                  ) : (
                    <Typography variant="body2">Sin acciones disponibles</Typography>
                  )
                }
                no={() => (
                  <Typography variant="body2">Sin acciones disponibles</Typography>
                )}
              />
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

export default ProfileUpComponent;
