import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

export const SUSPICIOUS_STATS_ALL = gql`
  query 
      { getSuspicionsDashboard(
      dateStart:"2000-05-31T21:58:00.099Z",
      dateEnd:"3021-05-31T21:58:00.099Z",
      groupBy:2)
    }
`;

export default function useSuspicionDashboardAll(){

  const { data, loading, error } = useQuery(SUSPICIOUS_STATS_ALL, {
  });

  return { dataAll: data?.getSuspicionsDashboard, loading, error };
};
