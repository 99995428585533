import React from 'react';
import { gql } from 'apollo-boost';
import {
  Typography,
  Grid,
  makeStyles,
  Box,
} from '@material-ui/core';
import DatetimeInputDialog from './datetime-input-dialog';
import ExtractionMedia from './extraction-media';


export const SET_CROSS_CLAMP_MUTATION = gql`
  mutation setCrossClamp($surgeryId: ID!, $time: Date!) {
    setCrossClamp(surgeryId: $surgeryId, time: $time) {
      id
      status
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  confirm: {
    gridArea: 'confirm',
    justifySelf: 'right'
  },
  columnroot: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column'
  },
  rowroot: {
    display: 'flex',
    flexwrap: 'nowrap',
    flexDirection: 'row',
    width: '100%',
  },
  width: {
    width: '100%',
    maxWidth: '600px',
    marginLeft: '30px',
  },
}));

const SurgeryExtractionStep = ({ surgery, organsList, user, handleStep, completed, steps, activeStep }) => {
  const classes = useStyles();
  const next_status = [ "enIsquemia", "realizada"];
  const variables = {id: surgery.id};
  const messages = {
    success: 'Cross clamp ingresado exitosamente',
    tooltip: 'Se ingresará la hora actual como hora de cross-clamp de la cirugía',
    time_description: 'cross-clamp',
  }

  return (
    <Grid >
      <Grid>
        <Grid>
          <Typography variant="h6">Paso 3: Extracción</Typography>
          <Typography >Subir video y hora de cross-clamp</Typography>
        </Grid>
        <Grid className={classes.rowroot}>
          <Box className={classes.width}>
            <DatetimeInputDialog
              surgery={surgery}
              next_status={next_status}
              SURGERY_TIME_MUTATION={SET_CROSS_CLAMP_MUTATION}
              variables={variables}
              messages={messages}
              step_status="enCurso"
              display_datetime={surgery.crossClamp}
              handleStep={handleStep}
              completed={completed}
              activeStep={activeStep}
            />
          </Box>
          <Grid className={classes.history}>
            <ExtractionMedia
              surgery={surgery}
              organsList={organsList}
              user={user}
            />
          </Grid> 
        </Grid>

      </Grid>
    </Grid>
    
  );
};

export default SurgeryExtractionStep;
