import {
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon,
  ExpandMore,
  ExpandLess,
} from '@material-ui/icons';
import React from 'react';
import { useSortBy, useFilters, usePagination, useTable } from 'react-table';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  root: {
    flexShrink: 0,
  },
  headers: {
    backgroundColor: 'lightgrey',
  },
  textHeader: {
    marginTop: theme.spacing(-1),
    height: 0,
    width: 100,
  },
  textHeaderNotFilter: {
    marginTop: theme.spacing(-2),
    color: theme.palette.primary.main,
    opacity: 1,
    fontWeight: 500,
    lineHeight: '28px',
    textTransform: 'uppercase',
    width: '100%',
  },
  noSortFilter: {
    width: 100,
    color: theme.palette.primary.main,
    opacity: 1,
    fontFamily: 'Roboto',
    fontWeight: 500,
    lineHeight: '28px',
  },
  headerIcon: {
    paddingTop: theme.spacing(1),
    height: 25,
  },
  rows: {
    backgroundColor: '#ECF0F1',
    color: theme.palette.primary.main,
  },
  hoverableRow: {
    cursor: 'pointer',
    '&:hover > *': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: theme.palette.common.white,
    },
  },
}));

const TablePaginationActions = (props) => {
  const classes = useStyles();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="primera página"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="página anterior"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="página siguiente"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="última página"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
};

const EnhancedTable = ({
  data,
  columns,
  initialState: controlledInitialState = {},
  clickableRow = false,
  onClickRow = () => null,
}) => {
  const styles = useStyles();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { pageIndex, pageSize },
    gotoPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        ...controlledInitialState,
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <TableContainer>
      <Table className={styles.table} {...getTableProps()}>
        <TableHead className={styles.headers}>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                let className = styles.noSortFilter;
                if (column.canFilter) className = styles.textHeader;
                else if (column.canSort) className = styles.textHeaderNotFilter;
                return (
                  <TableCell {...column.getHeaderProps()}>
                    <div className={className}>
                      <span {...column.getSortByToggleProps()}>
                        {column.render('Header')}
                        {!column.canFilter &&
                          column.canSort &&
                          (column.isSortedDesc ? (
                            <ExpandMore className={styles.headerIcon} />
                          ) : (
                            <ExpandLess className={styles.headerIcon} />
                          ))}
                      </span>
                    </div>
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableHead>

        <TableBody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <TableRow
                onClick={() => clickableRow && onClickRow(row)}
                {...row.getRowProps()}
                className={clsx({ [styles.hoverableRow]: clickableRow })}
              >
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      className={styles.rows}
                      {...cell.getCellProps()}
                      align="left"
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'Todas', value: data.length }]}
              count={data.length}
              rowsPerPage={pageSize}
              page={pageIndex}
              SelectProps={{
                inputProps: { 'aria-label': 'Filas por página' },
                native: true,
              }}
              labelRowsPerPage="Filas por página"
              labelDisplayedRows={({ from, to, count }) => {
                return `${from}-${to} de ${count}`;
              }}
              onChangePage={(event, newPage) => {
                gotoPage(newPage);
              }}
              onChangeRowsPerPage={(event) => {
                setPageSize(Number(event.target.value));
              }}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default EnhancedTable;
