import {
  Paper,
  Grid,
  Typography,
  Box,
  Tooltip,
  makeStyles,
} from '@material-ui/core/';
import {
  Person,
} from '@material-ui/icons';
import React, { useMemo } from 'react';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { TextFilter } from '../../components/table/filters';
import EnhancedTable from '../../components/ui/enhanced-table';
import DeleteGroupMemberDialog from './delete-team-member-button';
import AddTeamMemberButton from './add-team-member';
import AssignBossButton from './asign-boss-button';
import UnassignBossButton from './unasign-boss-button';
import { useCurrentUser } from '../authentication/use-current-user';
import { useAllUsers } from '../users/use-all-users';
import FullPageSpinner from '../../components/ui/full-page-spinner';
import ActivationGroupMemberSwitch from './activate-group-member-switch';


const useStyles = makeStyles((theme) => ({
  root: { flexwrap: 'nowrap' },
  title: { padding: theme.spacing(1) },
  rowroot: {
    padding: theme.spacing(1),
    display: 'flex',
    flexwrap: 'nowrap',
    flexDirection: 'row',
  },
  screenwidth: { width: '100%'},
  columnroot: { padding: theme.spacing(1), display: 'flex', flexDirection: 'column' },
  subtitle: { color: theme.palette.text.hint },
  date: { color: theme.palette.text.hint },
  dismissed: { color: theme.palette.error.main },
  accepted: { color: theme.palette.success.main },
  userinfo1: { padding: theme.spacing(2), backgroundColor: '#ECF0F1' },
  userinfo2: { padding: theme.spacing(2), backgroundColor: 'lightgrey' },
  padding1: { padding: theme.spacing(2) },
  margin1: { marginLeft: theme.spacing(3) },
  switch: { color: theme.palette.primary },
  paper: {
    // top and bottom 24px padding + toolbar height + grid spacing + tabHeight
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100%',
    maxWidth: '1000px',
  },
  width:{
    width: '100%',
    maxWidth: '985px',
    marginLeft: '7px'
  }
}));

const TeamsTable = ({ teamMembers, groupId }) => {
  const classes = useStyles();
  const { user, loading: loadingCurrentUser } = useCurrentUser();
  const { users, loading: loadingAllUsers } = useAllUsers();

  const getTrProps = (state, row, instance) => {
    return {
      style: {
        backgroundColor: row.original.user.active ? '' : 'yellow',
        color: row.original.user.active ? '' : 'yellow'
      }
    }
  } 
  const data = useMemo(() => teamMembers, [teamMembers]);
  const columnsBoss = useMemo(
    () => [
      {
        label: 'Nombre',
        accessor: 'user.firstName',
        Filter: TextFilter,
      },
      {
        label: 'Apellido',
        accessor: 'user.lastName',
        Filter: TextFilter,
      },
      {
        id: 'boss',
        label: 'Jefe',
        Header: 'Jefe',
        accessor: (teamMember) => teamMember.boss.toString(),
        disableFilters: true,
        Cell: ({ row, value }) => {
          return <Grid container>{value === 'true' ? <h4>Si</h4> : <h4>No</h4>}</Grid>;
        },
      },
      {
        Header: '¿Miembro activo?',
        id: 'desable',
        accessor: 'id',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ row, value }) => {
          return (
            <Grid container justify="space-evenly">
              <Grid item xs={12} align="center">
                <ActivationGroupMemberSwitch user={row.original} groupId={groupId} />
              </Grid>
            </Grid>
          );
        },
      },
      {
        Header: 'Eliminar miembro',
        id: 'delete',
        accessor: 'id',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ row, value }) => {
          return (
            <Grid container justify="space-evenly">
              <Grid item xs={12} align="center">
                <DeleteGroupMemberDialog data={row.original} />
              </Grid>
            </Grid>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const columnsNoBoss = useMemo(
    () => [
      {
        label: 'Nombre',
        accessor: 'user.firstName',
        Filter: TextFilter,
      },
      {
        label: 'Apellido',
        accessor: 'user.lastName',
        Filter: TextFilter,
      },
      {
        id: 'boss',
        label: 'Jefe',
        accessor: (teamMember) => teamMember.boss.toString(),
        Filter: TextFilter,
        Cell: ({ row, value }) => {
          return <Grid container>{value === 'true' ? <h4>Si</h4> : <h4>No</h4>}</Grid>;
        },
      },
      {
        Header: '¿Miembro activo?',
        id: 'desable',
        accessor: 'active',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ row, value }) => {
          return (
            <Grid container justify="space-evenly">
              <Grid item xs={12} align="center">
                {value === true ? <h4>Si</h4> : <h4>No</h4>}
              </Grid>
            </Grid>
          );
        },
      },
      {
        Header: 'ACCIONES',
        id: 'actions',
        accessor: 'id',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ row, value }) => {
          return (
            <Grid container justify="space-evenly">
              <Grid item xs={3} align="center">
                <Tooltip title="Debes ser el jefe del equipo para poder editar">
                  <RemoveCircleIcon />
                </Tooltip>
              </Grid>
            </Grid>
          );
        },
      },
    ],
    []
  );

  let current_is_boss = false;
  // eslint-disable-next-line no-unused-vars
  let procurerBoss = false;
  // eslint-disable-next-line no-unused-vars
  let procurerBossId = null;
  let boss = false;
  let bossId = null;

  if (loadingAllUsers || loadingCurrentUser) {
    return <FullPageSpinner />;
  }
  if (user.role === 'admin') {
    current_is_boss = true;
  }
  if (teamMembers.length !== 0) {
    teamMembers.forEach(function (item, index) {
      if (user.id === item.userId) {
        if (item.boss) {
          current_is_boss = true;
        }
      }
      if (item.procurerBoss) {
        procurerBoss = true;
        procurerBossId = item.userId;
      }
      if (item.boss) {
        boss = true;
        bossId = item.userId;
      }
    });
  }
  return (
    <Grid className={classes.width}>
      {current_is_boss ? (
        // En el caso de que el usuario actual sea el jefe de equipo o admin
        <Grid
          item
          container
          className={(classes.rowroot, classes.padding1)}
          alignItems="center"
          spacing={2}
          align="left"
        >
          <Box className={classes.rowroot} alignItems="center" >
            <Person />
            <Typography className="margin1" variant="h6">
              Integrantes
            </Typography>
          </Box>
          <Grid item>
            <AddTeamMemberButton
              groupId={groupId}
              users={users}
              user={user}
              current_is_boss={current_is_boss}
            />
          </Grid>

          <Grid item>
            {boss ? (
              <UnassignBossButton groupId={groupId} bossId={bossId} />
            ) : (
              <AssignBossButton groupId={groupId} />
            )}
          </Grid>
        </Grid>
      ) : (
        // En el caso de que NO sea el jefe de equipo
        <Grid>
          <Box className={classes.rowroot} alignItems="center">
            <Person />
            <Typography className="margin1" variant="h6">
              Integrantes
            </Typography>
          </Box>
        </Grid>
      )}
      {/* La tabla de los integrantes la ven todos (si es el jefe se ocupan los columnsBoss para poder eliminar un miembro) */}
        <Paper className={classes.paper} elevation={7}>
          <EnhancedTable
            data={data}
            columns={current_is_boss ? columnsBoss : columnsNoBoss}
            getTableProps={getTrProps}
          />
        </Paper>
    </Grid>
  );
};


export default TeamsTable;
