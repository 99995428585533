import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

export const ALL_ORGAN_TYPES_QUERY = gql`
  query AllOrganTypes {
    getOrganTypes {
      id
      name
      description
      ischemiaTime
      createdAt
      updatedAt
    }
  }
`;

export function useAllOrganTypes() {
  const { data, loading, error } = useQuery(ALL_ORGAN_TYPES_QUERY);

  return { allOrganTypes: data?.getOrganTypes, loading, error };
}
