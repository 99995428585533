import { fireSentryEvent, setContextTag } from '../../services/sentry';
import React from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import {
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import ResponsiveDialog from '../../components/ui/responsive-dialog';
import useModal from '../../hooks/useModal';
import { ALL_SUSPICIONS_QUERY } from './use-all-suspicions';

export const DELETE_SUSPICION_MUTATION = gql`
  mutation DeleteSuspicionMutation($id: ID!) {
    deleteSuspicion(id: $id)
  }
`;

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.getContrastText(theme.palette.error.main),
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

const DenySuspicionButton = ({ suspicionInfo }) => {
  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [deleteSuspicion, { loading }] = useMutation(DELETE_SUSPICION_MUTATION, {
    optimisticResponse: null,
    update: (cache) => {
      const existingSuspicions = cache.readQuery({ query: ALL_SUSPICIONS_QUERY });
      const newSuspicions = existingSuspicions.getSuspicions.filter(
        (suspicion) => suspicion.id !== suspicionInfo.id
      );
      cache.writeQuery({
        query: ALL_SUSPICIONS_QUERY,
        data: { getSuspicions: newSuspicions },
      });
    },
  });

  const handleSubmit = async () => {
    closeModal();
    history.push('/suspicions');

    try {
      await deleteSuspicion({
        variables: { id: suspicionInfo.id },
      });
      enqueueSnackbar('Sospecha negada exitosamente', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Hubo un error al negar la sospecha', {
        variant: 'error',
      });
      setContextTag('error al negar sospecha', error.message);
      fireSentryEvent(error);
    }
  };

  return (
    <>
      <Button
        label="Desestimar"
        size="small"
        variant="contained"
        onClick={openModal}
        id="delete-suspicion-btn"
        startIcon={<CloseIcon />}
        className={classes.button}
      >
        Descartar
      </Button>
      <ResponsiveDialog
        align="center"
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Descartar sospecha del paciente {suspicionInfo.rut}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de querer descartar la sospecha de manera permanente?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="secondary">
            Volver
          </Button>
          <Button onClick={handleSubmit} disabled={loading} color="primary" type="submit">
            Confirmar
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    </>
  );
};

export default DenySuspicionButton;
