import React, { useEffect } from 'react';
import { Paper, Typography, makeStyles, Grid, Box, Divider, Button } from '@material-ui/core';
import { addHours } from 'date-fns';

import { formatCountdown } from '../../utils/date-format';
import DismissOrganButton from './dismiss-organ-button';
import { organStatus } from '../../constants/status';
import useCountdown from '../../hooks/useCountdown';
import ViewRouteButton from './view-route-button';
import ChangeReceiverButton from './change-receiver-button';
import OldReceiverButton from './Information/old-reciever-button';
import FullPageSpinner from '../../components/ui/full-page-spinner';

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(2) },
  title: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.h6.fontWeight,
  },
  header: { display: 'flex', justifyContent: 'space-between' },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  infoItem: {
    display: 'flex',
    alignItems: 'center',
  },
  infoItemTitle: {
    flex: 1,
    fontWeight: theme.typography.h6.fontWeight,
    color: theme.palette.text.hint,
  },
  newReciever: {
    height: '22px',
    marginBottom: '5px',
    marginLeft: '8px',
  },
  timeLeft: { fontSize: theme.typography.h6.fontSize },
  organStatus: {
    fontWeight: theme.typography.h6.fontWeight,
    color: ({ status }) => {
      switch (status) {
        case organStatus.transplanted:
          return theme.palette.success.main;
        case organStatus.rejected:
          return theme.palette.error.main;
        default:
          return theme.palette.text.secondary;
      }
    },
  },
}));

const OrganItem = ({ surgery, organ, procureSchedule }) => {
  let computing = true;
  let schedule = 0;

  for (let i = 0; i < procureSchedule.length; i++) {
    if (procureSchedule[i].id == organ.id){
      schedule = procureSchedule[i];
    }}

  computing = false;

  const classes = useStyles();
  const { timeLeft, refreshDate } = useCountdown({
    targetDate: addHours(new Date(organ.crossClamp), organ.organType.ischemiaTime),
  });

  useEffect(() => {
    if (organ.crossClamp)
      refreshDate(addHours(new Date(organ.crossClamp), organ.organType.ischemiaTime));
  }, [organ.crossClamp, organ.organType.ischemiaTime, refreshDate]);

  const isInProgress =
    organ.status !== organStatus.transplanted && organ.status !== organStatus.rejected;

  let buttonColor = '#A5ADCF'
  if (organ.status === organStatus.transplanted) {
    buttonColor = '#5EAB07'
  } else if (organ.status === organStatus.rejected) {
    buttonColor = '#f44336'
  }

  const newAcceptedOffer = organ.offerings.find(
    (offer) => offer.status === 'accepted' || offer.status === 'Aceptada'
  );

  if (computing){
    return <FullPageSpinner></FullPageSpinner>
  }
  return (
    <Paper elevation={3} className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography className={classes.title} color="primary">
              {organ.organType.name}
            </Typography>
            <Paper
              size="small"
              style={{
                backgroundColor: { buttonColor },
                padding: '9px 9px',
              }}
              variant="contained"
              disabled={false}
            >
              <Typography variant="h6">{organ.status}</Typography>
            </Paper>
          </Box>
          <Box className={classes.header}>
            {organ.crossClamp && isInProgress && (
              <Typography className={classes.timeLeft} color="primary">
                {formatCountdown(timeLeft, 'HH:mm:ss')}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.infoItem}>
            <Typography variant="subtitle1" className={classes.infoItemTitle}>
              
            </Typography>
            <Typography variant="body1">{organ.destinationMedicalCenter.name}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.infoItem}>
            <Typography variant="subtitle1" className={classes.infoItemTitle}>
              Tiempo máximo de isquemia
            </Typography>
            <Typography variant="body1">{organ.organType.ischemiaTime} horas</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.infoItem}>
            <Typography variant="subtitle1" className={classes.infoItemTitle}>
              Receptor{' '}
              {newAcceptedOffer.hasNewReceiver ? (
                <OldReceiverButton
                  className={classes.newReciever}
                  offering={newAcceptedOffer}
                />
              ) : null}
            </Typography>
            {newAcceptedOffer.hasNewReceiver ? (
              <Typography variant="body1">
                {newAcceptedOffer.newReceiverDocumentId}
              </Typography>
            ) : (
              <Typography variant="body1">
                {newAcceptedOffer.recieverDocumentId}
              </Typography>
            )}
          </Box>
          <Divider />
        </Grid>
        <Divider />
        <Grid item xs={12}>
          <Box className={classes.infoItem}>
            <Typography variant="subtitle1" className={classes.infoItemTitle}>
              Hora confirmada de salida
            </Typography>
            {schedule.scheduledAt ? <Typography variant="body1">{new Date(schedule.scheduledAt).toLocaleString()}</Typography> : 'No confirmado'}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.infoItem}>
            <Typography variant="subtitle1" className={classes.infoItemTitle}>
              Hora Extracción
            </Typography>
            {surgery.crossClamp ? <Typography variant="body1">{new Date(surgery.scheduledAt).toLocaleString()}</Typography> : 'No confirmado'}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.infoItem}>
            <Typography variant="subtitle1" className={classes.infoItemTitle}>
              Videos
            </Typography>
            {false ? <Typography variant="body1">{new Date(surgery.scheduledAt).toLocaleString()}</Typography> : 'Ninguno'}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.infoItem}>
            <Typography variant="subtitle1" className={classes.infoItemTitle}>
              Comentarios confirmación
            </Typography>
            {true ? <Typography variant="body1"> Disponibles en pestaña extracción - Paso 1 </Typography> : 'Ninguno'}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.infoItem}>
            <Typography variant="subtitle1" className={classes.infoItemTitle}>
              Comentarios extracción
            </Typography>
            {false ? <Typography variant="body1"> Disponibles en pestaña extracción - Paso 3 </Typography> : 'Ninguno'}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {organ.crossClamp && isInProgress ? (
          <Grid item xs={12} className={classes.buttons}>
            <ViewRouteButton organ={organ} />
            <DismissOrganButton surgery={surgery} organ={organ} />
            <ChangeReceiverButton
              offering={newAcceptedOffer}
              surgery={surgery}
              organ={organ}
            />
          </Grid>
        ) :
          <Grid item xs={12} className={classes.buttons}>
            <ViewRouteButton organ={organ} />
            <DismissOrganButton surgery={surgery} organ={organ} />
            <ChangeReceiverButton
              offering={newAcceptedOffer}
              surgery={surgery}
              organ={organ}
            />
          </Grid>
        }
      </Grid>
    </Paper>
  );
};

export default OrganItem;
