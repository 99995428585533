import { fireSentryEvent, setContextTag } from '../../services/sentry';
import React from 'react';
import Switch from '@material-ui/core/Switch';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import {
  Typography,
  Box,
  makeStyles,
} from '@material-ui/core';
import { useOrganGroup } from '../teams/use-organ-group';
import FullPageSpinner from '../../components/ui/full-page-spinner';

export const NOTIFICATIONS_ON_MUTATION = gql`
  mutation turnNotificationOn($turnNotificationOnInput: turnNotificationOnInput!) {
    turnNotificationOn(turnNotificationOnInput: $turnNotificationOnInput) {
      getsNotified
    }
  }
`;

export const NOTIFICATIONS_OFF_MUTATION = gql`
  mutation turnNotificationOff($turnNotificationOffInput: turnNotificationOffInput!) {
    turnNotificationOff(turnNotificationOffInput: $turnNotificationOffInput) {
      getsNotified
    }
  }
`;

export default function ActivateNotifications({ user, group }) {
  const useStyles = makeStyles((theme) => ({
    root: { flexwrap: 'nowrap' },
    title: { padding: theme.spacing(1) },
    rowroot: {
      padding: theme.spacing(1),
      display: 'flex',
      flexwrap: 'nowrap',
      flexDirection: 'row',
    },
    columnroot: { padding: theme.spacing(1), display: 'flex', flexDirection: 'column' },
    subtitle: { color: theme.palette.text.hint },
    date: { color: theme.palette.text.hint },
    dismissed: { color: theme.palette.error.main },
    accepted: { color: theme.palette.success.main },
    userinfo1: { padding: theme.spacing(2), backgroundColor: '#ECF0F1' },
    userinfo2: { padding: theme.spacing(2), backgroundColor: 'lightgrey' },
    switch: { color: theme.palette.primary },
    paper: {
      // top and bottom 24px padding + toolbar height + grid spacing + tabHeight
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      width: '600px', // `calc(100vh - ${theme.spacing(3 * 2 + 8 + gridSpacing + 4 + 6)}px)`,
      overflowY: 'auto',
      overflowX: 'hidden',
    },
  }));

  const { enqueueSnackbar } = useSnackbar();
  const { organGroup, loading } = useOrganGroup(group.groupId);
  const classes = useStyles();

  const [state, setState] = React.useState({
    // TODO: Aqui tiene que ser el atrubuto que se saca de user, pero el endpoint no esta listo
    activated: group.getsNotified,
  });

  const [activate_notifications] = useMutation(
    NOTIFICATIONS_ON_MUTATION
  );
  const [deactivate_notifications] = useMutation(
    NOTIFICATIONS_OFF_MUTATION
  );

  const handleChange = async (event) => {
    event.persist();

    if (!state.activated) {
      try {
        await activate_notifications({
          variables: {
            turnNotificationOnInput: { organGroupId: group.groupId, userId: user.id },
          },
        });
        setState({ ...state, activated: true });
        console.log('success');

        enqueueSnackbar(
          'Se han activado las notificaciones del usuario para este grupo',
          { variant: 'success' }
        );
      } catch (error) {
        console.log(error);
        enqueueSnackbar(
          'Ha ocurrido un error, no se ha podido activar las notificaciones para el usuario',
          {
            variant: 'error',
          }
        );
        setContextTag('error al activar notificaciones de usuario', error.message);
        fireSentryEvent(error);
      }
    } else {
      try {
        await deactivate_notifications({
          variables: {
            turnNotificationOffInput: { organGroupId: group.groupId, userId: user.id },
          },
        });
        setState({ ...state, activated: false });
        console.log('success');

        enqueueSnackbar(
          'Se han desactivado las notificaciones del usuario para este grupo',
          { variant: 'success' }
        );
      } catch (error) {
        console.log(error);
        enqueueSnackbar(
          'Ha ocurrido un error, no se ha podido desactivar las notificaciones para el usuario',
          {
            variant: 'error',
          }
        );
        setContextTag('error al desactivar las notificaciones para el usuario', error.message);
        fireSentryEvent(error);
      }
    }
  };

  if (loading) {
    return FullPageSpinner;
  }
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      className={classes.userinfo2}
    >
      {state.activated ? (
        <Typography variant="subtitle2">
          {' '}
          Desactivar temporalmente notificaciones del grupo de {
            organGroup.organType.name
          }{' '}
          de {organGroup.medicalCenter.name}:
        </Typography>
      ) : (
        <Typography variant="subtitle2">
          {' '}
          Activar temporalmente notificaciones del grupo de {
            organGroup.organType.name
          } de {organGroup.medicalCenter.name}:
        </Typography>
      )}

      <Switch
        checked={state.activated}
        onChange={handleChange}
        name="activated"
        color="primary"
      />
    </Box>
  );
}
