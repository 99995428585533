import React from 'react';
import useModal from '../../hooks/useModal';
import ScheduleSurgeryDialog from './schedule-surgery-dialog';
import { useCan } from '../../utils/can';
import { rules } from '../../utils/rbac-rules';



const ScheduleSurgeryButton = ({ suspicion }) => {
  const canSchedule = useCan({ perform: rules.surgery.schedule });
  const { isModalOpen, close: closeModal } = useModal();

  if (!canSchedule) return null;
  return (
    <>
      <ScheduleSurgeryDialog
        suspicion={suspicion}
        open={isModalOpen}
        onClose={closeModal}
      />
    </>
  );
};

export default ScheduleSurgeryButton;
