import { fireSentryEvent, setContextTag } from '../../services/sentry';
import { useMutation } from '@apollo/react-hooks';
import {
  Tooltip,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { gql } from 'apollo-boost';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React from 'react';
import FormTextField from '../../components/forms/form-text-field';
import ResponsiveDialog from '../../components/ui/responsive-dialog';
import useModal from '../../hooks/useModal';
import Yup from '../../utils/validations';

const useStyles = makeStyles((theme) => ({
  warning: {
    color: theme.palette.error.light,
  },
  field: {
    marginTop: theme.spacing(1),
  },
}));

export const MODIFY_USER_MUTATION = gql`
  mutation ModifyUserMutation($editUserInput: EditUserInput!) {
    modifyUser(editUserInput: $editUserInput) {
      id
      firstName
      lastName
      email
    }
  }
`;

const ModifyUserDialog = ({ userInfo }) => {
  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  const [modifyUser, { loading }] = useMutation(MODIFY_USER_MUTATION);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleSubmit = async (values, { resetForm }) => {
    // console.log(values)
    try {
      await modifyUser({
        variables: { editUserInput: values },
      });
      enqueueSnackbar('Usuario editado exitosamente', { variant: 'success' });
      resetForm({});
      closeModal();
    } catch (error) {
      enqueueSnackbar('Ha ocurrido un error, usuario no fue editado exitosamente', {
        variant: 'error',
      });
      setContextTag('error al editar usuario', error.message);
      fireSentryEvent(error);
    }
  };

  return (
    <>
      <Tooltip title="Editar usuario">
        <IconButton
          aria-label="Editar usuario"
          color="primary"
          size="small"
          onClick={openModal}
          id="modify-user-btn"
        >
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Formik
        initialValues={{
          id: userInfo.id,
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          email: userInfo.email,
          password: userInfo.password,
        }}
        validationSchema={Yup.object({
          firstName: Yup.string().required(),
          lastName: Yup.string().required(),
          email: Yup.string().email().required(),
          password: Yup.string().matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!.%*#?&_-])[A-Za-z\d.@$!%*#?&_-]{8,}$/,
            'La contraseña debe incluir una minúscula, una mayúscula, un número y un caracter especial'
          ),
        })}
        onSubmit={handleSubmit}
      >
        {({ submitForm }) => (
          <Form align="center">
            <ResponsiveDialog
              open={isModalOpen}
              onClose={closeModal}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title">Editar usuario</DialogTitle>
              <DialogContent>
                <DialogContentText className={classes.warning}>
                  Solo ingresar contraseña en caso de querer cambiarla
                </DialogContentText>
                <FormTextField
                  className={classes.field}
                  label="Nombre"
                  name="firstName"
                  fullWidth
                  required
                />
                <FormTextField
                  className={classes.field}
                  label="Apellido"
                  name="lastName"
                  fullWidth
                  required
                />
                <FormTextField
                  className={classes.field}
                  label="Correo"
                  name="email"
                  fullWidth
                  required
                />
                <FormTextField
                  className={classes.field}
                  label="Cambiar contraseña"
                  name="password"
                  type="password"
                  fullWidth
                />
                <br />
              </DialogContent>
              <DialogActions>
                <Button onClick={closeModal} color="secondary">
                  Cancelar
                </Button>
                <Button
                  onClick={submitForm}
                  disabled={loading}
                  color="primary"
                  type="submit"
                >
                  Editar
                </Button>
              </DialogActions>
            </ResponsiveDialog>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ModifyUserDialog;
