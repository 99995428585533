import React from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import {
  Grid,
  Box,
  Paper,
  Button,
  DialogActions,
  DialogContent,
  Link,
  makeStyles,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import FormDropzoneArea from '../../../components/forms/form-dropzone-area';
import Yup from '../../../utils/validations';
import { SURGERY_QUERY } from '../use-surgery';
import { fireSentryEvent, setContextTag } from '../../../services/sentry';

export const ADD_MEDIA = gql`
  mutation addMedia($organId: ID!, $video: Upload!) {
    addMedia(id: $organId, video: $video) {
      id
    }
  }
`;

const SUPPORTED_FORMATS = [
  'image/jpeg',
  'image/png',
  'video/mp4',
  'video/MOV',
  'video/WMV',
  'video/AVI',
  'video/MKV',
  'video/AVCHD',
  'video/FLV'
];

const useStyles = makeStyles((theme) => ({
  confirm: {
    gridArea: 'confirm',
    justifySelf: 'right',
  },
  columnroot: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minWidth: '500px'
  },
  rowroot: {
    display: 'flex',
    flexwrap: 'nowrap',
    flexDirection: 'row',
    width: '100%',
  },
  width: {
    width: '100%',
    maxWidth: '600px',
  },
  extensions: {
    display: 'block',
    textAlign: 'right',
    color: theme.palette.text.disabled,
  },
  dropZone: {
    padding: '15px',
    width: '100%',
  },
  formControl: {
    margin: theme.spacing(2, 0),
    minWidth: '275px',
  },
  downloadMedia:{
    alignItems: 'left'
  }
}));

const uploadVideoSchema = Yup.object({
  organId: Yup.number().required(),
});

const ExtractionMedia = ({ surgery, organsList, user }) => {
  const [currentOrgan, setOrgan] = React.useState(organsList[0]);
  const organId = currentOrgan.id;
  const classes = useStyles();

  const [uploadVideo, { loading }] = useMutation(ADD_MEDIA, {
    refetchQueries: [
      {
        query: SURGERY_QUERY,
        variables: {
          surgeryId: surgery.id,  
        },
      },
    ],
  });
  
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (values, { resetForm }) => {
    try {
      await uploadVideo({
        variables: { organId: currentOrgan.id, video: values.file1 },
      });
      enqueueSnackbar('Video subido exitosamente', {
        variant: 'success',
      });
      if (user.role == 'admin' || user.role == 'coordinator'){
        setOrgan(surgery.organs);
      } else{
        setOrgan(surgery.assignedOrgans);
      };
    } catch (error) {
      enqueueSnackbar('Hubo un error al procesar su solicitud.', { variant: 'error' });
      setContextTag('error al procesar solicitud de subir video', error.message);
      fireSentryEvent(error);
    }
  };


  return (
    <Paper className={classes.columnroot} elevation={4}>
      <Grid className={classes.width}>
        <Typography variant="h6">Video de cirugía</Typography>
        <Select
          className={classes.formControl}
          value={currentOrgan}
          onChange={(event) => {
            setOrgan(event.target.value);
          }}
        >
          {organsList.map((organ) => (
            <MenuItem key={organ.id} value={organ}>
              {organ.organType.name}
            </MenuItem>
          ))}
        </Select>
        <Grid>
          {currentOrgan.media ? 
            <Grid item className={classes.downloadMedia} >
                <Box className={classes.rowroot} alignItems='center'>
                  <Link href={currentOrgan.media}>  
                    <Button disabled={!currentOrgan.media}>
                      <GetAppIcon/>
                    </Button>
                  </Link>
                  <Typography variant='subtitle' >Descargar video</Typography>
                </Box>
            </Grid>
          :
          <Formik
            initialValues={{
              file1: null,
              organId,
            }}
            onSubmit={handleSubmit}
            validationSchema={uploadVideoSchema}
          >
            {({ submitForm }) => (
              <Form>
                <DialogContent>
                  <Box className={classes.rowroot}>
                    <Grid className={classes.dropZone}>
                      <FormDropzoneArea
                        item
                        name="file1"
                        label="Subir video"
                        filesLimit={1}
                        acceptedFiles={SUPPORTED_FORMATS}
                      />
                    </Grid>

                  </Box>
                  <Typography className={classes.extensions} variant="caption">
                    Extensiones permitidas: mp4, jpeg, png
                  </Typography>
                </DialogContent>
                <DialogActions>
                  {loading && <CircularProgress/>}

                  <Button
                    onClick={submitForm}
                    disabled={loading}
                    color="primary"
                    type="submit"
                    variant="contained"
                  >
                    Ingresar
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>}
        </Grid>
        
      </Grid>
      
    </Paper>
    
  );
};

export default ExtractionMedia;
