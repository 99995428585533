import { fireSentryEvent, setContextTag } from '../../services/sentry';
import React from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import {
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  makeStyles,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import ResponsiveDialog from '../../components/ui/responsive-dialog';
import FormDateTimePicker from '../../components/forms/form-datetime-picker';
import { ALL_SURGERIES_QUERY } from './use-all-surgeries';
import { SURGERY_QUERY } from './use-surgery';

const useStyles = makeStyles((theme) => ({
  content: { margin: theme.spacing(1, 0) },
}));

const SCHEDULE_CROSS_CLAMP_MUTATION = gql`
  mutation setCrossClamp($surgeryId: ID!) {
    setCrossClamp(surgeryId: $surgeryId) {
      id
    }
  }
`;

const ScheduleCrossClampDialog = ({ surgery, open, onClose }) => {
  const [crossClamp, { loading }] = useMutation(SCHEDULE_CROSS_CLAMP_MUTATION, {
    refetchQueries: [
      { query: ALL_SURGERIES_QUERY },
      { query: SURGERY_QUERY, variables: { surgeryId: surgery.id } },
    ],
  });

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (values, { resetForm }) => {
    try {
      await crossClamp({ variables: {surgeryId: surgery.id } });
      enqueueSnackbar('Cross Clamp agendado correctamente', { variant: 'success' });
      onClose();
      resetForm({});
    } catch (error) {
      enqueueSnackbar('Ha ocurrido un error agendando el Cross Clamp', {
        variant: 'error',
      });
      setContextTag('error al agendar cross clamp', error.message);
      fireSentryEvent(error);
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ crossClampDate: new Date() }}
      validationSchema={Yup.object({
        crossClampDate: Yup.date().max(new Date()).required(),
      })}
    >
      {({ submitForm }) => (
        <Form>
          <ResponsiveDialog
            open={open}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
            onClick={(event) => event.stopPropagation()}
          >
            <DialogTitle>
              ¿Deseas ingresar cross-clamp del paciente {surgery.suspicion.rut}?
            </DialogTitle>
            
            <DialogActions>
              <Button onClick={() => onClose()}>Volver</Button>
              <Button
                onClick={() => submitForm()}
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                Ingresar
              </Button>
            </DialogActions>
          </ResponsiveDialog>
        </Form>
      )}
    </Formik>
  );
};

export default ScheduleCrossClampDialog;
