import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Divider from '@material-ui/core/Divider';
import { changeDateFormat } from '../../utils/date-format';
import CreateBackgroundButton from './create-background-form';
import ConfirmBackgroundButton from './confirm-background-dialog';
import { useCurrentUser } from '../authentication/use-current-user';
import Can from '../../utils/can';
import { rules } from '../../utils/rbac-rules';
import SuspicionExpansionPanelSummary from '../suspicions/suspicion-expansion-panel-summary';

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, marginTop: theme.spacing(1) },
  detailTitle: {
    paddingLeft: theme.spacing(2),
    fontSize: '16px',
    color: theme.palette.text.secondary,
  },
  disclaimer: {
    paddingLeft: theme.spacing(2),
    fontSize: '13px',
    color: theme.palette.text.secondary,
    fontStyle: 'italic'
  },
  detailData: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  detailDataTime: {
    paddingLeft: theme.spacing(2),
    color: 'gray',
  },
  detailUser: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.text.secondary,
    fontSize: '16px',
  },
  detailNotConfirmed: {
    paddingLeft: theme.spacing(24),
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    fontSize: '16px',
  },
  detailDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const BackgroundDetails = ({ suspicion, isExpanded, onClick }) => {
  const classes = useStyles();
  const { user } = useCurrentUser();

  return (
    <>
      <div className={classes.root}>
        <ExpansionPanel expanded={isExpanded}>
          <SuspicionExpansionPanelSummary
            label="Antecedentes"
            onClick={onClick}
            isExpanded={isExpanded}
            suspicion={suspicion}
            stepName="background"
            testId="details-background-btn"
          >
            <Can
              role={user.role}
              perform={rules.background.create}
              yes={() =>
                !suspicion.background && (
                  <CreateBackgroundButton suspicionInfo={suspicion} />
                )
              }
            />
          </SuspicionExpansionPanelSummary>
          {suspicion.background ? (
            <ExpansionPanelDetails>
              <Grid container>
                <Grid item xs={6} align="left">
                  <Typography className={classes.detailDataTime}>
                    {changeDateFormat(suspicion.background.createdAt)}
                  </Typography>
                  <Typography className={classes.detailData}>
                    Antecedentes ingresados por:
                  </Typography>
                  <Typography className={classes.detailUser}>
                    {suspicion.background.creator.firstName}{' '}
                    {suspicion.background.creator.lastName}
                  </Typography>
                </Grid>
                <Grid item xs={6} align="left">
                  {suspicion.background.confirmation.status ? (
                    <>
                      <Typography className={classes.detailDataTime}>
                        {changeDateFormat(suspicion.background.confirmation.updatedAt)}
                      </Typography>
                      <Typography className={classes.detailData}>
                        Confirmados por:
                      </Typography>
                      <Typography className={classes.detailUser}>
                        {suspicion.background.confirmation.user.firstName}{' '}
                        {suspicion.background.confirmation.user.lastName}
                      </Typography>
                    </>
                  ) : (
                    <Typography className={classes.detailNotConfirmed}>
                      Por confirmar
                    </Typography>
                  )}
                </Grid>
                <Grid container>
                  <Grid item xs={12} align="center">
                    <Divider className={classes.detailDivider} />
                  </Grid>
                </Grid>
                <Grid item xs={4} align="left">
                  <Typography className={classes.detailTitle}>Edad</Typography>
                </Grid>
                <Grid item xs={8} align="left">
                  <Typography className={classes.detailData}>
                    {suspicion.background.age}
                  </Typography>
                </Grid>
                <Grid item xs={4} align="left">
                  <Typography className={classes.detailTitle}>
                    Puntuación Glasgow
                  </Typography>
                </Grid>
                <Grid item xs={8} align="left">
                  <Typography className={classes.detailData}>
                    {suspicion.background.glasgow}
                  </Typography>
                </Grid>
                <Grid item xs={4} align="left">
                  <Typography className={classes.detailTitle}>Diagnóstico</Typography>
                </Grid>
                <Grid item xs={8} align="left">
                  <Typography className={classes.detailData}>
                    {suspicion.background.diagnostic}
                  </Typography>
                </Grid>
                <Grid container>
                  <Grid item xs={12} align="center">
                    <Divider className={classes.detailDivider} />
                  </Grid>
                </Grid>
                <Grid item xs={12} align="left">
                  <Typography className={classes.detailTitle}>
                    Comentarios adicionales
                  </Typography>
                </Grid>
                <Grid item xs={12} align="left" widht="90%">
                  <Typography className={classes.detailData}>
                    {suspicion.background.comment === ''
                      ? 'Sin comentarios'
                      : suspicion.background.comment}
                  </Typography>
                </Grid>
                <Grid item xs={12} align="right">
                  <Can
                    role={user.role}
                    perform={rules.background.confirm}
                    yes={() =>
                      !suspicion.background.confirmation.status && (
                        <ConfirmBackgroundButton
                          backgroundId={suspicion.background.id}
                          suspicionId={suspicion.id}
                        />
                      )
                    }
                  />
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          ) : null}
        </ExpansionPanel>
      </div>
    </>
  );
};

export default BackgroundDetails;
