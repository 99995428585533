import { useMutation } from '@apollo/react-hooks';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Box,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { gql } from 'apollo-boost';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React from 'react';
import FormDropzoneArea from '../../components/forms/form-dropzone-area';
import FormTextField from '../../components/forms/form-text-field';
import ResponsiveDialog from '../../components/ui/responsive-dialog';
import { useCurrentUser } from '../authentication/use-current-user';
import Yup from '../../utils/validations';
import Can from '../../utils/can';
import { rules } from '../../utils/rbac-rules';
import { SUSPICION_QUERY } from '../suspicions/use-suspicion';
import { fireSentryEvent, setContextTag } from '../../services/sentry';

const SUPPORTED_FORMATS = [
  'image/jpeg',
  'image/png',
  'application/pdf',
  '.xls',
  '.xlsx',
  '.doc',
  '.docx',
];

const medicalRecordSchema = Yup.object({
  laboratory: Yup.mixed().test(
    'required',
    'Al menos un archivo requerido',
    function validateLaboratory(value) {
      const { clinicalSummary } = this.parent;
      if (!clinicalSummary) return !!value;
      return true;
    }
  ),
  clinicalSummary: Yup.mixed().test(
    'required',
    'Al menos un archivo requerido',
    function validateClinicalSummary(value) {
      const { laboratory } = this.parent;
      if (!laboratory) return !!value;
      return true;
    }
  ),
  comments: Yup.string().optional(),
});

const useStyles = makeStyles((theme) => ({
  filesContainer: {
    display: 'flex',
    '& > *:not(:last-child)': { margin: theme.spacing(0, 1, 0, 0) },
  },
  extensions: {
    display: 'block',
    textAlign: 'right',
    color: theme.palette.text.disabled,
  },
}));

export const CREATE_MEDICAL_RECORD_MUTATION = gql`
  mutation CreateMedicalRecord($medicalRecordInput: MedicalRecordInput!) {
    createMedicalRecord(medicalRecordInput: $medicalRecordInput) {
      id
      laboratory
      clinicalSummary
      comments
      confirmation {
        id
        status
      }
    }
  }
`;

export const MODIFY_MEDICAL_RECORD_MUTATION = gql`
  mutation ModifyMedicalRecord($editMedicalRecordInput: EditMedicalRecordInput!) {
    modifyMedicalRecord(editMedicalRecordInput: $editMedicalRecordInput) {
      id
      laboratory
      clinicalSummary
      comments
      confirmation {
        id
        status
      }
    }
  }
`;

const CreateMedicalRecordDialog = ({ suspicion, open, onClose }) => {
  const [createMedicalRecord, { loading }] = useMutation(
    suspicion.medicalRecord
      ? MODIFY_MEDICAL_RECORD_MUTATION
      : CREATE_MEDICAL_RECORD_MUTATION,
    {
      refetchQueries: [
        {
          query: SUSPICION_QUERY,
          variables: {
            suspicionId: suspicion.id,
          },
        },
      ],
    }
  );
  const { user } = useCurrentUser();
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (values) => {
    try {
      if (suspicion.medicalRecord) {
        await createMedicalRecord({
          variables: {
            editMedicalRecordInput: { ...values, id: suspicion.medicalRecord.id },
          },
        });
      } else {
        await createMedicalRecord({ variables: { medicalRecordInput: values } });
      }
      enqueueSnackbar('Resumen clínico / laboratorio ingresado exitosamente', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Hubo un error al ingresar el resumen clínico / laboratorio', {
        variant: 'error',
      });
      setContextTag('resumen clinico / laboratorio no se pudo ingresar', error.message);
      fireSentryEvent(error);
    }
    onClose();
  };

  return (
    <Formik
      initialValues={
        suspicion.medicalRecord
          ? {
              laboratory: suspicion.medicalRecord.laboratory,
              clinicalSummary: suspicion.medicalRecord.clinicalSummary,
              comments: suspicion.medicalRecord.comments,
            }
          : {
              laboratory: null,
              clinicalSummary: null,
              comments: '',
              suspicionId: suspicion.id,
            }
      }
      onSubmit={handleSubmit}
      validationSchema={medicalRecordSchema}
    >
      {({ submitForm }) => (
        <Form>
          <ResponsiveDialog
            open={open}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              Ingresar resumen clínico / laboratorio
            </DialogTitle>
            <DialogContent>
              <DialogContentText>Completa el formulario con los datos</DialogContentText>
              <Box className={classes.filesContainer}>
                <Can
                  role={user.role}
                  perform={rules.laboratory.create}
                  yes={() => (
                    <FormDropzoneArea
                      name="laboratory"
                      label="Laboratorio"
                      filesLimit={1}
                      acceptedFiles={SUPPORTED_FORMATS}
                    />
                  )}
                />
                <FormDropzoneArea
                  name="clinicalSummary"
                  label="Resumen Clínico"
                  filesLimit={1}
                  acceptedFiles={SUPPORTED_FORMATS}
                />
              </Box>
              <Typography className={classes.extensions} variant="caption">
                Extensiones permitidas: pdf, jpg, png, xls, doc, xlsx, docx
              </Typography>
              <FormTextField
                name="comments"
                label="Comentarios (opcionales)"
                multiline
                rows={4}
                fullWidth
                onFocus={(event) => event.stopPropagation()}
              />
            </DialogContent>
            <DialogActions>
              {loading && <CircularProgress color="inherit" data-testid="loading-spinner" />}
              <Button onClick={onClose} color="secondary">
                Cancelar
              </Button>
              <Button
                data-testid="enter-medical-record-button"
                onClick={submitForm}
                disabled={loading}
                color="primary"
                type="submit"
                variant="contained"
              >
                Ingresar
              </Button>
            </DialogActions>
          </ResponsiveDialog>
        </Form>
      )}
    </Formik>
  );
};

export default CreateMedicalRecordDialog;
