import { fireSentryEvent, setContextTag } from '../../services/sentry';
import React from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import {
  Tooltip,
  Button,
  DialogContent,
  DialogTitle,
  DialogActions,
  makeStyles,
  DialogContentText,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import ResponsiveDialog from '../../components/ui/responsive-dialog';
import useModal from '../../hooks/useModal';
import FullPageSpinner from '../../components/ui/full-page-spinner';
import { TEAM_MEMBERS_QUERY } from './use-team-members';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  formControl: {
    margin: theme.spacing(2, 0),
    minWidth: 275,
  },
  unasignBossButton: {
    color: theme.palette.warning,
  },
}));

export const UNSET_BOSS = gql`
  mutation unsetGroupMemberAsBoss(
    $unsetGroupMemberAsBossInput: unsetGroupMemberAsBossInput!
  ) {
    unsetGroupMemberAsBoss(unsetGroupMemberAsBossInput: $unsetGroupMemberAsBossInput) {
      id
      userId
    }
  }
`;

const UnassignBossButton = ({ groupId, bossId }) => {
  const [unsetBoss, { loading: loadingMutation }] = useMutation(UNSET_BOSS, {
    refetchQueries: [
      {
        query: TEAM_MEMBERS_QUERY,
        variables: {
          groupId,
        },
      },
    ],
  });
  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleSubmit = async () => {
    try {
      await unsetBoss({
        variables: {
          unsetGroupMemberAsBossInput: { organGroupId: groupId, userId: bossId },
        },
      });
      console.log('success');

      enqueueSnackbar('El grupo ha quedado sin jefe', { variant: 'success' });
      closeModal();
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Ha ocurrido un error, el jefe no pudo ser desasignado', {
        variant: 'error',
      });
      setContextTag('error al desasignar jefe de grupo', error.message);
      fireSentryEvent(error);
    }
  };

  if (loadingMutation) {
    return <FullPageSpinner />;
  }

  return (
    <>
      <Tooltip title="Desasigna al jefe actual del equipo">
        <Button
          aria-label="Desasigna al jefe"
          color="warning"
          onClick={openModal}
          id="unassign-boss"
          variant="contained"
          disabled={false}
          className={classes.unasignBossButton}
          startIcon={<UnfoldLessIcon />}
        >
          Desasignar jefe de equipo
        </Button>
      </Tooltip>
      <ResponsiveDialog
        align="center"
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Confirmar desasignación del usuario
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de querer desasignar a este usuario de su función como jefe?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="secondary">
            Cancelar
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={loadingMutation}
            color="primary"
            variant="contained"
            type="submit"
          >
            Eliminar
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    </>
  );
};

export default UnassignBossButton;
