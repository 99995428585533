import dateFormat from 'dateformat';
import { format, addMilliseconds } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

const changeDateFormat = (date) => {
  return `${dateFormat(date, 'dd/mm/yyyy HH:MM')}hrs`;
};

const changeDateFormatShort = (date) => {
  return `${dateFormat(date, 'dd/mm HH:MM')}`;
};

const formatCountdown = (milisecondsRemaining, customFormat = 'mm:ss') => {
  return format(
    zonedTimeToUtc(
      addMilliseconds(new Date('2020-01-01T00:00:00'), milisecondsRemaining),
      'America/Santiago'
    ),
    customFormat
  );
};

export { changeDateFormat, changeDateFormatShort, formatCountdown };
