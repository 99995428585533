import React from 'react';
import {
  Paper,
  Grid,
  Typography,
  Box,
  Divider,
  makeStyles,
} from '@material-ui/core';
import {
  LocalHospital as LocalHospitalIcon,
} from '@material-ui/icons';
import { useOrganGroup } from './use-organ-group';
import { useAllOrganTypes } from '../organs/useAllOrganTypes';
import { useCurrentUser } from '../authentication/use-current-user';
import FullPageSpinner from '../../components/ui/full-page-spinner';
import DeleteOrganGroupDialog from './delete-team-button';
import { rules } from '../../utils/rbac-rules';
import Can from '../../utils/can';

// const gridSpacing = 4;
const useStyles = makeStyles((theme) => ({
  root: { flexwrap: 'nowrap' },
  title: { padding: theme.spacing(1) },
  rowroot: {
    padding: theme.spacing(1),
    display: 'flex',
    flexwrap: 'nowrap',
    flexDirection: 'row',
    width: '100%',
    maxWidth: '1000px'

  },
  columnroot: { padding: theme.spacing(1), display: 'flex', flexDirection: 'column' },
  subtitle: { color: theme.palette.text.hint },
  date: { color: theme.palette.text.hint },
  dismissed: { color: theme.palette.error.main },
  accepted: { color: theme.palette.success.main },
  userinfo1: { padding: theme.spacing(2), backgroundColor: '#ECF0F1' },
  userinfo2: { padding: theme.spacing(2), backgroundColor: 'lightgrey' },
  switch: { color: theme.palette.primary },
  paper: {
    // top and bottom 24px padding + toolbar height + grid spacing + tabHeight
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  width: {
    width: '100%',
    maxWidth: '1000px'
  }
}));

const TeamsInfo = ({ groupId }) => {
  const classes = useStyles();
  const { organGroup, loading: loadingOrganGroup } = useOrganGroup(groupId);
  const { user, loading: loadingCurrentUser } = useCurrentUser();
  const { loading: loadingOrganNames } = useAllOrganTypes();

  if (loadingOrganGroup || loadingOrganNames || loadingCurrentUser) {
    return <FullPageSpinner />;
  }

  return (
    <Grid className={classes.width}>
      <Box container className={classes.rowroot} alignItems="center" display="flex">
        <LocalHospitalIcon />
        <Typography variant="h6" component="div">
          Equipo de {organGroup.organType.name} de {organGroup.medicalCenter.name}
        </Typography>
      </Box>
      <Grid className={classes.rowroot} spacing={2}>
        <Grid xs={12} sm={12} item>
          <Paper className={classes.paper} elevation={7} display="flex">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              className={classes.title}
            >
              <Typography variant="h6">Información general</Typography>
              <Can
                role={user.role}
                perform={rules.teams.get}
                yes={() => (<DeleteOrganGroupDialog organGroupId={groupId} />)}
              />
            </Box>
            <Divider />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              className={classes.userinfo1}
            >
              <Typography variant="subtitle2"> Centro médico donde opera:</Typography>
              <Typography variant="caption"> {organGroup.medicalCenter.name}</Typography>
            </Box>
            <Divider />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              className={classes.userinfo1}
            >
              <Typography variant="subtitle2"> Órgano asociado</Typography>
              <Typography variant="caption">{organGroup.organType.name}</Typography>
            </Box>
            <Divider />
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TeamsInfo;
