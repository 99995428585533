import { fireSentryEvent, setContextTag } from '../../services/sentry';
import React from 'react';
import Switch from '@material-ui/core/Switch';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

export const ACTIVATE_GROUP_MEMBER_MUTATION = gql`
  mutation activateGroupMember($activateGroupMemberInput: activateGroupMemberInput!) {
    activateGroupMember(activateGroupMemberInput: $activateGroupMemberInput) {
      active
    }
  }
`;

export const DEACTIVATE_GROUP_MEMBER_MUTATION = gql`
  mutation deactivateGroupMember(
    $deactivateGroupMemberInput: deactivateGroupMemberInput!
  ) {
    deactivateGroupMember(deactivateGroupMemberInput: $deactivateGroupMemberInput) {
      active
    }
  }
`;

export default function ActivationGroupMemberSwitch({ user, groupId }) {
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = React.useState({
    activated: user.active,
  });

  const [activate_group_member] = useMutation(
    ACTIVATE_GROUP_MEMBER_MUTATION
  );
  const [deactivate_group_member] = useMutation(
    DEACTIVATE_GROUP_MEMBER_MUTATION
  );

  const handleChange = async (event) => {
    event.persist();

    if (!state.activated) {
      try {
        await activate_group_member({
          variables: {
            activateGroupMemberInput: { organGroupId: groupId, userId: user.userId },
          },
        });
        setState({ ...state, activated: true });
        console.log('success');

        enqueueSnackbar('Se ha activado el usuario en el grupo', { variant: 'success' });
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Ha ocurrido un error, no se ha podido activar el usuario', {
          variant: 'error',
        });
        setContextTag('error al activar usuario', error.message);
        fireSentryEvent(error);
      }
    } else {
      try {
        await deactivate_group_member({
          variables: {
            deactivateGroupMemberInput: { organGroupId: groupId, userId: user.userId },
          },
        });
        setState({ ...state, activated: false });
        console.log('success');

        enqueueSnackbar('Se ha desactivado el usuario en el grupo', {
          variant: 'success',
        });
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Ha ocurrido un error, no se ha podido desactivar el usuario', {
          variant: 'error',
        });
        setContextTag('error al desactivar usuario', error.message);
        fireSentryEvent(error);
      }
    }
  };

  return (
    <Switch
      checked={state.activated}
      onChange={handleChange}
      name="activated"
      color="primary"
    />
  );
}
