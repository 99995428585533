import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { useSnackbar } from 'notistack';
import { Formik, Form } from 'formik';
import { Box, Button, makeStyles } from '@material-ui/core/';
import { fireSentryEvent, setContextTag } from '../../services/sentry';
import FormSingleAutocomplete from '../../components/forms/form-single-autocomplete';
import { USER_MEDICAL_CENTERS_QUERY } from './use-user-medical-centers';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  formControl: {
    margin: theme.spacing(2, 0),
    minWidth: 275,
  },
  selectFieldBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  selectField: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: '300px',
  },
  button: {
    height: '27px',
  },
}));

export const DELETE_MUTATION = gql`
  mutation DeleteUserMutation($id: ID!) {
    deleteUser(id: $id)
  }
`;

export const ADD_USER_TO_MEDICAL_CENTER_MUTATION = gql`
  mutation addUserToMedicalCenter(
    $addUserToMedicalCenterInput: addUserToMedicalCenterInput!
  ) {
    addUserToMedicalCenter(addUserToMedicalCenterInput: $addUserToMedicalCenterInput) {
      id
      medicalCenter {
        id
        name
      }
    }
  }
`;

const AddUserCenter = ({ userMedicalCenters, userId, allMedicalCenters }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const userMedicalCentersIds = userMedicalCenters.map((center) => {
    return center.id;
  });
  const candidates = allMedicalCenters.filter((center) => {
    return !userMedicalCentersIds.includes(center.id);
  });
  const loading = false;
  const [addMedicalCenter] = useMutation(ADD_USER_TO_MEDICAL_CENTER_MUTATION, {
    refetchQueries: [{ query: USER_MEDICAL_CENTERS_QUERY, variables: { id: userId } }],
  });

  // useEffect(() => {
  //   setCandidates(
  //     allMedicalCenters.filter((center) => userMedicalCentersIds.includes(center.id))
  //   );
  // }, [allMedicalCenters, userMedicalCenters, userMedicalCentersIds]);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      await addMedicalCenter({
        variables: {
          addUserToMedicalCenterInput: {
            userId,
            medicalCenterId: values.candidate,
          },
        },
      });
      resetForm({});
      enqueueSnackbar('Centro médico agregado satisfactoriamente', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Ha ocurrido un error, el centro no fue agregado al usuario', {
        variant: 'error',
      });
      setContextTag('error al agregar centro al usuario', error.message);
      fireSentryEvent(error);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          candidate: undefined,
        }}
        onSubmit={handleSubmit}
      >
        {({ submitForm }) => (
          <Box className={classes.selectFieldBox}>
            <Box className={classes.selectField} >
              <Form className={classes.form}>
                <FormSingleAutocomplete
                  name="candidate"
                  label="Agregar un Centro Médico"
                  options={
                    candidates.length > 0
                      ? candidates
                      : ['No hay centros médicos asignados a este usuario']
                  }
                  getOptionLabel={
                    candidates.length > 0
                      ? (option) => `${option.name}`
                      : (option) => option
                  }
                  renderOption={
                    candidates.length > 0
                      ? (option) => `${option.name}`
                      : (option) => option
                  }
                  getValueFromOption={
                    candidates.length > 0 ? (option) => option.id : (option) => option
                  }
                />
              </Form>
            </Box>
            <Button
              className={classes.button}
              onClick={submitForm}
              disabled={loading}
              color="primary"
              type="submit"
            >
              Agregar
            </Button>
          </Box>
        )}
      </Formik>
    </>
  );
};

export default AddUserCenter;
