import React from 'react';
import { Grid, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import DescriptionIcon from '@material-ui/icons/Description';
import { changeDateFormat } from '../../utils/date-format';
import ConfirmFamilyActivationButton from './confirm-family-activation-button';
import { useCurrentUser } from '../authentication/use-current-user';
import Can from '../../utils/can';
import { rules } from '../../utils/rbac-rules';
import SuspicionExpansionPanelSummary from '../suspicions/suspicion-expansion-panel-summary';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
  },
  expanded: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  disclaimer: {
    paddingLeft: theme.spacing(2),
    fontSize: '13px',
    color: theme.palette.text.secondary,
    fontStyle: 'italic'
  },
  notExpanded: {
    backgroundColor: theme.palette.secondary.main,
  },
  buttonExpanded: {
    color: 'white',
  },
  iconLabel: {
    fontSize: 14,
    marginLeft: theme.spacing(-7),
    marginTop: theme.spacing(1),
  },
  detailTitle: {
    paddingLeft: theme.spacing(2),
    fontSize: '16px',
    color: theme.palette.text.secondary,
  },
  detailData: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  detailDataTime: {
    paddingLeft: theme.spacing(2),
    color: 'gray',
  },
  detailUser: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.text.secondary,
    fontSize: '16px',
  },
  detailNotConfirmed: {
    paddingLeft: theme.spacing(24),
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    fontSize: '16px',
  },
  heading: {
    fontSize: '16px',
  },
  detailDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  download: {
    color: theme.palette.primary.main,
  },
  disableDownload: {
    color: 'gray',
  },
}));

const FamilyActivationDetails = ({ suspicion, isExpanded, onClick }) => {
  const classes = useStyles();
  const { user } = useCurrentUser();

  return (
    <>
      <div className={classes.root}>
        <ExpansionPanel expanded={isExpanded}>
          <SuspicionExpansionPanelSummary
            label="Activación familiar"
            onClick={onClick}
            isExpanded={isExpanded}
            suspicion={suspicion}
            stepName="familyActivation"
            testId="details-family-activation-btn"
          >
            <Can
              role={user.role}
              perform={rules.familyActivation.create}
              yes={() =>
                !suspicion.familyActivation && (
                  <ConfirmFamilyActivationButton
                    suspicionId={suspicion.id}
                    data-testid="add-family-activation-confirmation-button"
                  >
                    Ingresar
                  </ConfirmFamilyActivationButton>
                )
              }
            />
          </SuspicionExpansionPanelSummary>
          {suspicion.familyActivation ? (
            <ExpansionPanelDetails>
              <Grid container>
                <Grid item xs={12} align="left">
                  <Typography className={classes.detailDataTime}>
                    {changeDateFormat(suspicion.familyActivation.createdAt)}
                  </Typography>
                  <Typography className={classes.detailData}>
                    Ingresados y confirmados por:
                  </Typography>
                  <Typography className={classes.detailUser}>
                    {suspicion.familyActivation.creator.firstName}{' '}
                    {suspicion.familyActivation.creator.lastName}
                  </Typography>
                </Grid>
                <Grid container>
                  <Grid item xs={12} align="center">
                    <Divider className={classes.detailDivider} />
                  </Grid>
                </Grid>
                <Grid container justify="center">
                  <Grid item xs={2} align="center">
                    <Link href={suspicion.familyActivation.donationCertificate}>
                      <Button disabled={!suspicion.familyActivation.donationCertificate}>
                        <DescriptionIcon
                          className={
                            suspicion.familyActivation.donationCertificate
                              ? classes.download
                              : classes.disableDownload
                          }
                        />
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item xs={2} align="center">
                    <Typography className={classes.iconLabel}>
                      Acta de donación
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} align="center">
                    <Divider className={classes.detailDivider} />
                  </Grid>
                </Grid>
                <Grid item xs={12} align="left">
                  <Typography className={classes.detailTitle}>
                    Comentarios adicionales
                  </Typography>
                </Grid>
                <Grid item xs={12} align="left" widht="90%">
                  <Typography className={classes.detailData}>
                    {suspicion.familyActivation.comments === ''
                      ? 'Sin comentarios'
                      : suspicion.familyActivation.comments}
                  </Typography>
                </Grid>
                <Grid item xs={12} align="left">
                  <Typography className={classes.disclaimer}>
                    Las imágenes y/o documentos de esta sección se consideran datos sensibles y de carácter confiencial. 
                  </Typography>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          ) : null}
        </ExpansionPanel>
      </div>
    </>
  );
};

export default FamilyActivationDetails;
