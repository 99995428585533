import React from 'react';


import FullPageSpinner from '../../components/ui/full-page-spinner';
import TeamsTable from './teams-table';
import { useTeamMembers } from './use-team-members';

// const useStyles = makeStyles((theme) => ({
//   paper: {
//     marginTop: theme.spacing(0),
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//   },
//   form: {
//     width: '100%',
//     marginTop: theme.spacing(0),
//   },
//   submit: {
//     margin: theme.spacing(1, 0, 2),
//   },
//   formControl: {
//     margin: theme.spacing(2, 0),
//     minWidth: 275,
//   },
// }));

const TablePreparation = ({ groupId }) => {
  const { teamMembers, loading: loadingUserGroups } = useTeamMembers(groupId);

  if (loadingUserGroups) {
    return <FullPageSpinner />;
  }

  return <TeamsTable teamMembers={teamMembers} groupId={groupId} />;
};

export default TablePreparation;
