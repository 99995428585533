import React, { useState } from 'react';
import { makeStyles, Typography, Grid, Paper } from '@material-ui/core';

import FullPageSpinner from '../../components/ui/full-page-spinner';
import { useConfirmedSuspicions } from './use-confirmed-suspicions';
import PatientItem from './patient-item';

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
  },
}));

const PatientsList = () => {
  const { suspicions, loading } = useConfirmedSuspicions();
  const [expanded, setExpanded] = useState(null);
  const classes = useStyles();

  if (loading) {
    return <FullPageSpinner />;
  }

  return (
    <div className={classes.root} elevation={2}>
      {suspicions.length > 0 ? 
      <Paper>
        {
          suspicions.map((suspicion) => (
            <PatientItem
              key={suspicion.id}
              suspicion={suspicion}
              isExpanded={expanded === suspicion.id}
              expand={() => setExpanded(expanded === suspicion.id ? null : suspicion.id)}
            />
          ))
        }
      </Paper>
      : 
      <Grid container justify="flex-start" spacing={2}>
        <Typography component="div">No hay sospechas confirmadas aún</Typography>
      </Grid>}
    </div>
  );
};

export default PatientsList;
