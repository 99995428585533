import React from 'react';
import { Grid, Typography, Box, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import DescriptionIcon from '@material-ui/icons/Description';
import { changeDateFormat } from '../../utils/date-format';
import CreateMedicalRecordButton from './create-medical-record-button';
import ModifyMedicalRecordButton from './modify-medical-record-button';
import ConfirmMedicalRecordButton from './confirm-medical-record-button';
import { useCurrentUser } from '../authentication/use-current-user';
import Can from '../../utils/can';
import { rules } from '../../utils/rbac-rules';
import SuspicionExpansionPanelSummary from '../suspicions/suspicion-expansion-panel-summary';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
  },
  iconLabel: {
    fontSize: 14,
    marginLeft: theme.spacing(-6),
    marginTop: theme.spacing(1),
  },
  disclaimer: {
    paddingLeft: theme.spacing(2),
    fontSize: '13px',
    color: theme.palette.text.secondary,
    fontStyle: 'italic'
  },
  iconLabelLong: {
    fontSize: 14,
    marginLeft: theme.spacing(-3),
    marginTop: theme.spacing(1),
  },
  detailTitle: {
    paddingLeft: theme.spacing(2),
    fontSize: '16px',
    color: theme.palette.text.secondary,
  },
  detailData: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  detailDataTime: {
    paddingLeft: theme.spacing(2),
    color: 'gray',
  },
  detailUser: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.text.secondary,
    fontSize: '16px',
  },
  detailNotConfirmed: {
    paddingLeft: theme.spacing(24),
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    fontSize: '16px',
  },
  detailDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  download: {
    color: theme.palette.primary.main,
  },
  disableDownload: {
    color: 'gray',
  },
  heading: {
    fontSize: '16px',
  },
}));

const MedicalRecordDetails = ({ suspicion, isExpanded, onClick }) => {
  const classes = useStyles();
  const { user } = useCurrentUser();

  return (
    <>
      <div className={classes.root}>
        <ExpansionPanel expanded={isExpanded}>
          <SuspicionExpansionPanelSummary
            label="Resumen Clínico / Laboratorio"
            onClick={onClick}
            isExpanded={isExpanded}
            suspicion={suspicion}
            stepName="medicalRecord"
            testId="details-medical-record-button"
          >
            {!suspicion.medicalRecord && (
              <Can
                role={user.role}
                perform={rules.medicalRecord.create}
                yes={() =>
                  suspicion.medicalRecord ? null : (
                    <CreateMedicalRecordButton suspicion={suspicion} />
                  )
                }
              />
            )}
          </SuspicionExpansionPanelSummary>
          {suspicion.medicalRecord ? (
            <ExpansionPanelDetails>
              <Grid container>
                <Grid item xs={6} align="left">
                  <Typography className={classes.detailDataTime}>
                    Última actualización:{' '}
                    {changeDateFormat(suspicion.medicalRecord.updatedAt)}
                  </Typography>
                  <Typography className={classes.detailData}>
                    Resumen clínico / laboratorio añadido por:{' '}
                  </Typography>
                  <Typography className={classes.detailUser}>
                    {suspicion.medicalRecord.creator.firstName}{' '}
                    {suspicion.medicalRecord.creator.lastName}
                  </Typography>
                </Grid>
                <Grid item xs={6} align="left">
                  {suspicion.medicalRecord.confirmation.status ? (
                    <>
                      <Typography className={classes.detailDataTime}>
                        {changeDateFormat(suspicion.medicalRecord.confirmation.updatedAt)}
                      </Typography>
                      <Typography className={classes.detailData}>
                        Confirmadas por
                      </Typography>
                      <Typography className={classes.detailUser}>
                        {suspicion.medicalRecord.confirmation.user.firstName}{' '}
                        {suspicion.medicalRecord.confirmation.user.lastName}
                      </Typography>
                    </>
                  ) : (
                    <Typography className={classes.detailNotConfirmed}>
                      Por confirmar
                    </Typography>
                  )}
                </Grid>
                <Grid container>
                  <Grid item xs={12} align="center">
                    <Divider className={classes.detailDivider} />
                  </Grid>
                </Grid>
                <Grid container justify="center">
                  <Grid item xs={1} align="center">
                    <Link href={suspicion.medicalRecord.laboratory}>
                      <Button disabled={!suspicion.medicalRecord.laboratory}>
                        <DescriptionIcon
                          className={
                            suspicion.medicalRecord.laboratory
                              ? classes.download
                              : classes.disableDownload
                          }
                        />
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item xs={2} align="center">
                    <Typography className={classes.iconLabel}>Laboratorio</Typography>
                  </Grid>
                  <Grid item xs={1} align="center">
                    <Link href={suspicion.medicalRecord.clinicalSummary}>
                      <Button disabled={!suspicion.medicalRecord.clinicalSummary}>
                        <DescriptionIcon
                          className={
                            suspicion.medicalRecord.clinicalSummary
                              ? classes.download
                              : classes.disableDownload
                          }
                        />
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item xs={2} align="center">
                    <Typography className={classes.iconLabelLong}>
                      Resumen Clínico
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} align="center">
                    <Divider className={classes.detailDivider} />
                  </Grid>
                </Grid>
                <Grid item xs={12} align="left">
                  <Typography className={classes.detailTitle}>
                    Comentarios adicionales
                  </Typography>
                </Grid>
                <Grid item xs={12} align="left" widht="90%">
                  <Typography className={classes.detailData}>
                    {suspicion.medicalRecord.comments === ''
                      ? 'Sin comentarios'
                      : suspicion.medicalRecord.comments}
                  </Typography>
                </Grid>
                <Grid item xs={12} align="left">
                  <Typography className={classes.disclaimer}>
                    Las imágenes y/o documentos de esta sección se consideran datos sensibles y de carácter confiencial. 
                  </Typography>
                </Grid>
                <Grid container justify="flex-end">
                  <Box>
                    <Can
                      role={user.role}
                      perform={rules.medicalRecord.modify}
                      yes={() =>
                        suspicion.medicalRecord && !suspicion.medicalRecord.confirmation.status? (
                          <ModifyMedicalRecordButton suspicion={suspicion} />
                        ) : null
                      }
                    />
                  </Box>
                  <Box>
                    <Can
                      role={user.role}
                      perform={rules.medicalRecord.confirm}
                      yes={() =>
                        suspicion.medicalRecord &&
                        !suspicion.medicalRecord.confirmation.status && (
                          <ConfirmMedicalRecordButton
                            medicalRecord={suspicion.medicalRecord}
                            suspicion={suspicion}
                          >
                            Confirmar
                          </ConfirmMedicalRecordButton>
                        )
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          ) : null}
        </ExpansionPanel>
      </div>
    </>
  );
};

export default MedicalRecordDetails;
