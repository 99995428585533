import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

export const SURGERY_QUERY = gql`
  query SurgeryDetailQuery($surgeryId: ID!) {
    getSurgery(id: $surgeryId) {
      id
      status
      createdAt
      startedAt
      crossClamp
      finishedAt
      chat {
        id
      }
      medicalCenter {
        id
        name
      }
      suspicion {
        id
        rut
        brainDeath {
          document
        }
        medicalRecord {
          clinicalSummary
        }
        familyActivation {
          donationCertificate
        }
      }
      organs {
        id
        status
        crossClamp
        reperfusionTime
        media
        offerings {
          id
          status
          recieverDocumentId
          newReceiverDocumentId
          hasNewReceiver
          newReceiverPost {
            id
            comment
          }
        }
        destinationMedicalCenter {
          id
          name
        }
        organType {
          id
          name
          ischemiaTime
        }
      }
      assignedOrgans {
        id
        status
        media
        crossClamp
        reperfusionTime
        offerings {
          id
          status
          recieverDocumentId
          newReceiverDocumentId
          hasNewReceiver
          newReceiverPost {
            id
            comment
          }
        }
        destinationMedicalCenter {
          id
          name
        }
        organType {
          id
          name
          ischemiaTime
        }
      }
      scheduledAt
      updatedAt
    }
  }
`;

export const useSurgery = (surgeryId) => {
  const { data, loading, error } = useQuery(SURGERY_QUERY, {
    variables: { surgeryId },
  });
  return { surgery: data?.getSurgery, loading, error };
};
