import { fireSentryEvent, setContextTag } from '../../../services/sentry';
import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import { TimePicker } from "@material-ui/pickers";


const useStyles = makeStyles({
  root: {
    width: 350,
  },
  sliderBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  input: {
    width: 72,
    marginInline: '5px',
  },
});

export const SET_RANGE_PROCURE_SCHEDULE = gql`
  mutation setRangeProcureSchedule(
    $setRangeProcureScheduleInput: setRangeProcureScheduleInput!
  ) {
    setRangeProcureSchedule(
      setRangeProcureScheduleInput: $setRangeProcureScheduleInput
    ) {
      id
      surgeryId
      startAt
      endAt
    }
  }
`;


export default function CoordinationSlider({schedule, surgery}) {

  const getDateFromFloat = (date) => {
    var rhours = date.getHours();
    var minutes = date.getMinutes();
    var rminutes = minutes * 0.01;
    return rhours+rminutes;

  }


  let initInterval = schedule.startAt;
  let endInterval = schedule.endAt;
  let init = true;
  let defaultSchedule = true;


  if (init) {
    if (!initInterval) {
      initInterval = new Date(schedule.surgery.createdAt)

    } else {
      initInterval = new Date(schedule.startAt)
      defaultSchedule = false;
    }

    if (!endInterval) {
      endInterval = new Date(schedule.surgery.createdAt)
      endInterval.setHours(endInterval.getHours() + 1)
    } else {
      endInterval = new Date(schedule.endAt)
      defaultSchedule = false;
    }
    init = false
  }

  

  const { enqueueSnackbar } = useSnackbar();
  
  const [selectedDateInit, handleDateChangeInit] = useState(initInterval);
  const [selectedDateFinal, handleDateChangeFinal] = useState(endInterval);
  const [value, setValue] = React.useState([getDateFromFloat(initInterval),getDateFromFloat(endInterval)]);
  
  const classes = useStyles();
  

  const [setRangeProcureSchedule] = useMutation(SET_RANGE_PROCURE_SCHEDULE);
  

  const handleChange =  (event, newValue) => {
    setValue(value);
  };


  const handleMassiveChange1 = async () => {
    let date = selectedDateInit
    let setRangeProcureScheduleInput = {
      id: schedule.id,
      startAt: date, 
      endAt: selectedDateFinal
    }

    try {
      await setRangeProcureSchedule({
        variables: { setRangeProcureScheduleInput: setRangeProcureScheduleInput },
      });
      var rhours = date.getHours();
      var minutes = date.getMinutes();
      var rminutes = minutes * 0.01;
      setValue([rhours+rminutes, value[1]]);
      enqueueSnackbar('Se ha seteado el primer valor del intervalo correctamente', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Ha ocurrido un error, no se ha seteado el primer valor del intervalo', {
        variant: 'error',
      });
      setContextTag('primer valor del intervalo no se pudo setear', error.message);
      fireSentryEvent(error);
    }
    
    
  }
  const handleMassiveChange2 = async () => {
    let date = selectedDateFinal
    let setRangeProcureScheduleInput = {
      id: schedule.id,
      startAt: selectedDateInit, 
      endAt: date 
    }

    try {
      await setRangeProcureSchedule({
        variables: { setRangeProcureScheduleInput: setRangeProcureScheduleInput },
      });
      var rhours = date.getHours();
      var minutes = date.getMinutes();
      var rminutes = minutes * 0.01;
      setValue([value[0], rhours+rminutes]);
      enqueueSnackbar('Se ha seteado el segundo valor del intervalo correctamente', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Ha ocurrido un error, no se ha seteado el segundo valor del intervalo', {
        variant: 'error',
      });
      setContextTag('segundo valor del intervalo no se pudo setear', error.message);
      fireSentryEvent(error);
    }
  }

  return (
    <div className={classes.root}>
      <Typography id="range-slider" gutterBottom>
        {defaultSchedule ? 'Este grupo no ha marcado preferencias aún' : 'Horario Disponible'}
      </Typography>
      
    
      <div className={classes.sliderBox}>
      <TimePicker
      label="Inicio"
      
      variant="inline"
      placeholder="08:00 AM"
      mask="__:__ _M"
      value={selectedDateInit}
      
      onChange={date => handleDateChangeInit(date)}
      onClose={handleMassiveChange1}
    />
        <Slider
          value={value}
          onChange={handleChange}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          min={0}
          max={24}
          step={1/60}
        />
        <TimePicker
      label="Término"
      variant="inline"
      placeholder="08:00 AM"
      mask="__:__ _M"
      value={selectedDateFinal}
      onChange={date => handleDateChangeFinal(date)}
      onClose={handleMassiveChange2}
    />
      </div>
    </div>
    
  );
}

//      disabled={true}

//min={getDateFromFloat(selectedDateInit)}
//max={getDateFromFloat(selectedDateInit) + 24}
