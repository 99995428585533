import React from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import {
  DialogTitle,
  DialogContent,
  Button,
  Tooltip,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import useModal from '../../hooks/useModal';
import { fireSentryEvent, setContextTag } from '../../services/sentry';

import ResponsiveDialog from '../../components/ui/responsive-dialog';
import { CONFIRMED_SUSPICIONS_QUERY } from './use-confirmed-suspicions';
import { ALL_SURGERIES_QUERY } from '../surgeries/use-all-surgeries';



const SCHEDULE_SURGERY_MUTATION = gql`
  mutation addSurgeryBySuspicionId($suspicionId: ID!) {
    addSurgeryBySuspicionId(suspicionId: $suspicionId) {
      id
    }
  }
`;

const ADD_PROCURE_SCHEDULE = gql`
  mutation addProcureScheduleBySurgeryId($surgeryId: ID!) {
    addProcureScheduleBySurgeryId(surgeryId: $surgeryId){
      id
      organGroupId
      surgery {
        medicalCenter {
          name
        }
      }
    }
  }
`;

const ScheduleSurgeryDialog = ({ suspicion, open, onClose }) => {

  const { isModalOpen, open: openModal, close: closeModal } = useModal();

  const [scheduleSurgery, { loading }] = useMutation(SCHEDULE_SURGERY_MUTATION, {
    refetchQueries: [
      { query: CONFIRMED_SUSPICIONS_QUERY },
      { query: ALL_SURGERIES_QUERY },
    ],
  });

  const [addProcureSchedule] = useMutation(ADD_PROCURE_SCHEDULE);

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    try {
      console.log('entramos')
      let values_surgery = await scheduleSurgery({ variables: { suspicionId: suspicion.id } });
      let surgeryId = values_surgery.data.addSurgeryBySuspicionId.id
      await addProcureSchedule({ variables: { surgeryId: surgeryId } });
      enqueueSnackbar('Procura creada correctamente', { variant: 'success' });
      onClose();
    } catch (error) {
      enqueueSnackbar('Ha ocurrido un error agendando la cirugía', { variant: 'error' });
      setContextTag('error al agendar cirugia', error.message);
      fireSentryEvent(error);
    }
  };

  return (
    
    <>
    <Tooltip title="Al comenzar le proceso de procura se podrá coordinar la cirugía">
      <Button
        variant="contained"
        size="small"
        color="primary"
        data-testid="schedule-surgery-button"
        onClick={openModal}
        id="delete-user-btn"
      >
        Comenzar proceso de procura
      </Button>
    </Tooltip>
    <ResponsiveDialog
      align="center"
      open={isModalOpen}
      onClose={closeModal}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        Confirmar el inicio del proceso de procura
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          ¿Estás seguro de querer iniciar la procura de este transplante?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="secondary">
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={loading}
          color="primary"
          variant="contained"
          type="submit"
        >
          Aceptar
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  </>
);
};


export default ScheduleSurgeryDialog;
