import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';
import * as Sentry from '@sentry/browser';
import { AUTHENTICATION_TOKEN_KEY } from '../constants/index';
import * as authActions from '../features/authentication/auth-slice';
import store from './store';

const uri = process.env.REACT_APP_API_URL;

let wsUri = '';
if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
  console.log(process.env.REACT_APP_ENVIRONMENT);
  wsUri = 'wss://api-staging.trasplan.com/graphql';
} else if (process.env.REACT_APP_ENVIRONMENT === 'development') {
  wsUri = 'wss://api-staging.trasplan.com/graphql';
  console.log(process.env.REACT_APP_ENVIRONMENT);
} else if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  wsUri = 'wss://api.trasplan.com/graphql';
  console.log(process.env.REACT_APP_ENVIRONMENT);
}

const uploadLink = createUploadLink({ uri });

const contextLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTHENTICATION_TOKEN_KEY);
  if (token && token !== 'undefined') {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  }

  return {
    headers,
  };
});

const errorLink = onError(({ graphQLErrors }) => {
  graphQLErrors.forEach((error) => {
    // setContextTag('GraphQlError->', error.message);
    // fireSentryEvent(error);
    Sentry.captureException(`message: ${error.message}, path: ${error.path}`);
  });
  const tokenExpired = graphQLErrors
    .map((e) => e.extensions)
    .some((extension) => extension.code === 'jwt expired');

  if (tokenExpired) {
    store.dispatch(authActions.tokenExpired());
  }
});

const wsLink = new WebSocketLink({
  uri: wsUri,
  options: {
    reconnect: true,
    connectionParams: () => {
      const token = localStorage.getItem(AUTHENTICATION_TOKEN_KEY);
      return {
        // authToken: token ? `Bearer ${token}` : '',
        authorization: token || '',
      };
    },
  },
});

// const wsLink = new WebSocketLink({
//   uri: wsUri,
//   options: {
//     reconnect: true,
//     connectionParams: () => ({
//       authorization: localStorage.getItem(AUTHENTICATION_TOKEN_KEY),
//     }),
//   },
// });

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    );
  },
  wsLink,
  // ApolloLink.from([wsLink, errorLink]),
  ApolloLink.from([contextLink, errorLink, uploadLink])
);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export default client;
