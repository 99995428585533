import React, { useMemo } from 'react';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';

import { organStatus } from '../../constants/status';
import InProgressOrganItem from './in-progress-organ-item';
import PendingOrganItem from './pending-organ-item';
import FullPageSpinner from '../../components/ui/full-page-spinner';


const useStyles = makeStyles((theme) => ({
  paper: { padding: theme.spacing(1) },
  sectionTitle: { color: theme.palette.text.secondary },
  caption: { color: theme.palette.text.hint },
}));

const OrgansList = ({ organs }) => {
  const classes = useStyles();

  const pendingOrgans = useMemo(
    () => organs.filter(({ status }) => status === organStatus.pending),
    [organs]
  );

  const inProgressOrgans = useMemo(
    () => organs.filter(({ status }) => status !== organStatus.pending),
    [organs]
  );
  
  if (!pendingOrgans || !inProgressOrgans){
    return <FullPageSpinner />;
  }
  return (
    <>
      <Grid item sm={12} md={6} container spacing={2} alignContent="flex-start">
        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            Órganos pendientes
          </Typography>
        </Grid>
        {pendingOrgans.length > 0 ? (
          pendingOrgans.map((organ) => (
            <Grid item xs={12} key={organ.id}>
              <Paper square elevation={3} className={classes.paper}>
                <PendingOrganItem organ={organ} />
              </Paper>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="caption" className={classes.caption}>
              No hay órganos pendientes
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item sm={12} md={6} container spacing={2} alignContent="flex-start">
        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            Órganos en progreso
          </Typography>
        </Grid>
        {inProgressOrgans.length > 0 ? (
          inProgressOrgans.map((organ) => (
            <Grid item xs={12} key={organ.id}>
              <InProgressOrganItem organ={organ} />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="caption" className={classes.caption}>
              No hay órganos en progreso
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default OrgansList;
