import { fireSentryEvent, setContextTag } from '../../services/sentry';
import {
  Button,
  makeStyles,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import React from 'react';
import { gql } from 'apollo-boost';
import { useSnackbar } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';
import { Formik, Form } from 'formik';

import FormTextField from '../../components/forms/form-text-field';
import { useCan } from '../../utils/can';
import { rules } from '../../utils/rbac-rules';
import ResponsiveDialog from '../../components/ui/responsive-dialog';
import useModal from '../../hooks/useModal';
import { SURGERY_QUERY } from './use-surgery';
import { ALL_SURGERIES_QUERY } from './use-all-surgeries';
import { organStatus } from '../../constants/status';

const useStyles = makeStyles((theme) => ({
  dismissOrganButton: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    backgroundColor: theme.palette.error.main,
    color: theme.palette.getContrastText(theme.palette.error.main),
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.getContrastText(theme.palette.error.dark),
    },
  },
}));

const DISMISS_ORGAN_MUTATION = gql`
  mutation discardOrgan($id: ID!, $comment: String!) {
    discardOrgan(id: $id, comment: $comment) {
      id
    }
  }
`;

const DismissOrganButton = ({ surgery, organ }) => {
  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  const canRejectOrgan = useCan({ perform: rules.surgery.reject });
  const [dismissOrgan, { loading }] = useMutation(DISMISS_ORGAN_MUTATION, {
    refetchQueries: [
      { query: SURGERY_QUERY, variables: { surgeryId: surgery.id } },
      { query: ALL_SURGERIES_QUERY },
    ],});

  

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleSubmit = async (values, { resetForm }) => {
    closeModal();

    try {
      await dismissOrgan({ variables: { id: organ.id,  comment: values.comment} });
      resetForm();
      enqueueSnackbar('Órgano desestimado exitosamente', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Hubo un error al desestimar el órgano', {
        variant: 'error',
      });
      setContextTag('error al desestimar el organo', error.message);
      fireSentryEvent(error);
    }
  };

  if (!canRejectOrgan || organ?.status === organStatus.rejected) return null;

  return (
    <>
      <Button
        className={classes.dismissOrganButton}
        onClick={() => openModal()}
        variant="contained"
        size="small"
        data-testid="reject-organ-button"
        startIcon={<CloseIcon />}
      >
        Desestimar
      </Button>
      <Formik
        initialValues={{
          description: ''
        }}
        onSubmit={handleSubmit}
      >
        {({ submitForm }) => (
          <Form>
            <ResponsiveDialog
              align="center"
              open={isModalOpen}
              onClose={closeModal}
              aria-labelledby="reject-organ-dialog"
            >
              <DialogTitle id="reject-organ-dialog">
                Desestimar órgano {organ.organType.name.toLowerCase()} del paciente Rut: {surgery.suspicion.rut}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  ¿Estás seguro de querer desestimar este órgano? Esta acción no se puede deshacer
                </DialogContentText>

                <FormTextField
                  fullWidth
                  label="Comentario"
                  name="comment"
                  className={classes.margin}
                  multiline
                  rows={4}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={closeModal} color="secondary">
                  Volver
                </Button>
                <Button
                  onClick={submitForm}
                  disabled={loading}
                  color="primary"
                  type="submit"
                >
                  Desestimar órgano
                </Button>
              </DialogActions>
            </ResponsiveDialog>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default DismissOrganButton;
