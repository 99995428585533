import React from 'react';
import { gql } from 'apollo-boost';
import {
  Typography,
  Grid,
  makeStyles,
  Box,
  MenuItem,
  Select,
} from '@material-ui/core';
import DatetimeInputDialog from './datetime-input-dialog';
import SurgeryComments from './surgery-comments';
import SurgeryCommentField from './surgery-comment-field';


export const EXTRACT_ORGAN_MUTATION = gql`
  mutation setExtractedTime($organId: ID!, $time: Date!) {
    setExtractedTime(id: $organId, time: $time) {
      id
      status
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  confirm: {
    gridArea: 'confirm',
    justifySelf: 'right'
  },
  columnroot: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column'
  },
  rowroot: {
    padding: theme.spacing(1),
    display: 'flex',
    flexwrap: 'nowrap',
    flexDirection: 'row',
    width: '100%',
  },
  width: {
    width: '100%',
    maxWidth: '600px',
    marginLeft: '30px',
  },
  history: {
    marginLeft: '30px',
    maxHeight: '380px',
    width: '100%',
    '& > *': {
      height: '100%',
      padding: theme.spacing(1),
      overflow: 'scroll'
    },
  },
  formControl: {
    margin: theme.spacing(2, 0),
    minWidth: 275,
  },
}));

const SurgeryFinalStep = ({ surgery, organsList, handleStep, completed, steps, activeStep, setCompleted }) => {
  const classes = useStyles();
  const [currentOrgan, setOrgan] = React.useState(organsList[0]);
  const next_status = ["realizada"];
  const variables = {id: currentOrgan.id};
  const messages = {
    success: 'Fin de extracción ingresado exitosamente',
    tooltip: 'Se ingresará la hora actual como hora de extracción',
    time_description: 'finalización de extracción',
  }

  return (
    <Grid >
      <Grid>
        <Grid>
          <Typography variant="h6">Paso 4: Finalizar extracción</Typography>
          <Typography >Subir hora de término</Typography>
        </Grid>
        <Grid className={classes.rowroot}>
          <Box className={classes.width} > 
            <Select
              className={classes.formControl}
              value={currentOrgan}
              onChange={(event) => {
                setOrgan(event.target.value);
              }}
            >
              {organsList.map((organ) => (
                <MenuItem key={organ.id} value={organ}>
                  {organ.organType.name}
                </MenuItem>
              ))}
            </Select>      
            <DatetimeInputDialog
              surgery={surgery}
              next_status={next_status}
              SURGERY_TIME_MUTATION={EXTRACT_ORGAN_MUTATION}
              variables={variables}
              messages={messages}
              step_status="enIsquemia"
              display_datetime={currentOrgan.extractedAt}
              handleStep={handleStep}
              completed={completed}
              activeStep={activeStep}
              currentOrganStatus={currentOrgan.status}
              currentOrgan={currentOrgan}
              setCompleted={setCompleted}
              setOrgan={setOrgan}
            />
            <SurgeryCommentField
              organ={currentOrgan}
              type='extractedOrgan'

              />
          </Box>
          <Grid className={classes.history}>
            <SurgeryComments
              organ={currentOrgan}  
              type='extractedOrgan'
              />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    
  );
};

export default SurgeryFinalStep;
