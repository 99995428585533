import { fireSentryEvent, setContextTag } from '../../services/sentry';
import React from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import { Formik, Form } from 'formik';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {
  Tooltip,
  Button,
  DialogContent,
  DialogTitle,
  DialogActions,
  Container,
  makeStyles,
  Grid,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import ResponsiveDialog from '../../components/ui/responsive-dialog';
import useModal from '../../hooks/useModal';
import FormSingleAutocomplete from '../../components/forms/form-single-autocomplete';
import FullPageSpinner from '../../components/ui/full-page-spinner';
import { TEAM_MEMBERS_QUERY, useTeamMembers } from './use-team-members';
import { useOrganGroup } from './use-organ-group';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  formControl: {
    margin: theme.spacing(2, 0),
    minWidth: 275,
  },
}));

export const ADD_GROUP_MEMBER_MUTATION = gql`
  mutation addGroupMember($addGroupMemberInput: addGroupMemberInput!) {
    addGroupMember(addGroupMemberInput: $addGroupMemberInput) {
      groupId
      userId
    }
  }
`;

const AddTeamMember = ({ groupId, users, user, current_is_boss }) => {
  const { loadingUserGroups: loading } = useTeamMembers(groupId);
  const { organGroup, loadingGroup: loading1 } = useOrganGroup(groupId);


  const [addGroupMember] = useMutation(
    ADD_GROUP_MEMBER_MUTATION, {
      refetchQueries: [
        {
          query: TEAM_MEMBERS_QUERY,
          variables: {
            groupId,
          },
        },
      ],
    });
  
  const candidates = [];
  if (organGroup){
    for (let i = 0; i < users.length; i++) {
      let medical = users[i].medicalCenters;
      for (let k = 0; k < medical.length; k++) {
        if (medical[k].id === organGroup.medicalCenterId) {
            candidates.push(users[i]);
        }
      }
    }
  }

  const { isModalOpen, open: openModal, close: closeModal } = useModal();

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleSubmit = async (values, { resetForm }) => {
    try {
      await addGroupMember({
        variables: {
          addGroupMemberInput: { organGroupId: groupId, userId: values.candidate },
        },
      });
      console.log('success');

      enqueueSnackbar('Usuario ha agregado satisfactoriamente', { variant: 'success' });
      resetForm({});
      closeModal();
      //window.location.reload();
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Ha ocurrido un error, usuario no fue agregado al equipo', {
        variant: 'error',
      });
      setContextTag('error al agregar usuario al equipo', error.message);
      fireSentryEvent(error);
    }
  };

  if (loading || loading1) {
    return <FullPageSpinner />;
  }
  return (
    <Grid>
      {current_is_boss ? (
        <>
          <Tooltip title="Agregar usuario al equipo">
            <Button
              aria-label="Agregar usuario al equipo"
              color="primary"
              onClick={openModal}
              id="add-to-group"
            >
              <AddCircleIcon />
            </Button>
          </Tooltip>
          <Formik
            initialValues={{
              candidate: undefined,
            }}
            onSubmit={handleSubmit}
          >
            {({ submitForm }) => (
              <Container component="main" maxWidth="xs">
                <div className={classes.paper}>
                  <Form className={classes.form}>
                    <ResponsiveDialog
                      open={isModalOpen}
                      onClose={closeModal}
                      aria-labelledby="responsive-dialog-title"
                    >
                      <DialogTitle id="responsive-dialog-title">
                        Agregar usuario al equipo
                      </DialogTitle>
                      <DialogContent>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <FormSingleAutocomplete
                              name="candidate"
                              label="Elige un usuario"
                              options={candidates.length > 0 ? candidates : ['No hay usuarios asignados a este centro médico']}
                              getOptionLabel={candidates.length > 0 ? (option) =>
                                `${option.firstName} ${option.lastName}`
                                :
                                (option) => option
                              }
                              renderOption={candidates.length > 0 ? (option) =>
                                `${option.firstName} ${option.lastName}`
                                :
                                (option) => option
                              }
                              getValueFromOption={candidates.length > 0 ? (option) => option.id : (option) => option}
                            />
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={closeModal} color="secondary">
                          Cancelar
                        </Button>
                        <Button
                          onClick={submitForm}
                          disabled={loading}
                          color="primary"
                          type="submit"
                        >
                          Registrar
                        </Button>
                      </DialogActions>
                    </ResponsiveDialog>
                  </Form>
                </div>
              </Container>
            )}
          </Formik>
        </>
      ) : null}
    </Grid>
  );
};

export default AddTeamMember;
