import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logoIcon from '../../assets/horizontal-typographic-logo.png';

const useStyles = makeStyles(() => ({
  icon: {
    height: 40,
    width: 170,
  },
}));

const HorizontalTypographicLogoComponent = () => {
  const classes = useStyles();
  return <img src={logoIcon} alt="Horizontal Trasplan Logo" className={classes.icon} />;
};

export default HorizontalTypographicLogoComponent;
