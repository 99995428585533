import React, { useState } from 'react';
import { Paper, Grid, Typography, Box, Divider, makeStyles } from '@material-ui/core';
import FixedCoordinationSlider from './Coordination/FixedCoordinationSlider';
import CoordinationSlider from './Coordination/CoordinationSlider';
import ConfirmTeamSchedule from './Coordination/confirm-team-schedule-button';
import IdentifierOrganGroup from './Coordination/Identifier-organ-group';
import SurgeryChat from './Coordination/SurgeryChat';

import ConfirmSurgerySchedule from './Coordination/confirm-surgery-schedule-button';
import FullPageSpinner from '../../components/ui/full-page-spinner';
import Can from '../../utils/can';
import { rules } from '../../utils/rbac-rules';

import { useCurrentUser } from '../../features/authentication/use-current-user';

const useStyles = makeStyles((theme) => ({
  title: {
    padding: theme.spacing(1),
  },
  rowroot: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  paper: {
    width: '600px',
  },
  details: {
    padding: theme.spacing(2),
  },
  userinfo2: {
    padding: theme.spacing(2),
  },
  coordination: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  coordinationPaper: {
    paddingInline: '3px',
    paddingBlock: '3px',
  },
  chatBox: {
    marginLeft: '15px',
  },
}));

// Array.prototype.sortBy = function (p) {
//   return this.slice(0).sort(function (a, b) {
//     return a[p] > b[p] ? 1 : a[p] < b[p] ? -1 : 0;
//   });
// };

const CentralCoordinationInput = ({ text, hour }) => {
  const classes = useStyles();
  return (
    <Box className={classes.coordination}>
      <Typography variant="subtitle1">{text}</Typography>
      <Paper className={classes.coordinationPaper}>
        <Typography variant="h6">{hour}</Typography>
      </Paper>
    </Box>
  );
};

const SurgeryCoordinationTab = ({ userGroups, surgery, procureSchedule, chatId }) => {
  const classes = useStyles();
  const { user } = useCurrentUser();
  const [showSchedule, setShowSchedule] = useState(null);

  //! procureSchedule = procureSchedule.sortBy('id');

  const dateFormat = require('dateformat');

  let computation = true;

  if (computation) {
    for (let i = 0; i < procureSchedule.length; i++) {
      if (user.role === 'admin' || user.role === 'coordinator') {
        procureSchedule[i].myProcure = true
      }
      else if (userGroups.length > 0){
        for (let k = 0; k < userGroups.length; k++) {
          if (procureSchedule[i].organGroupId === userGroups[k].groupId) {
            procureSchedule[i].myProcure = true
            break
          }
        }
      }
    }
    computation = false;
  }

  if (computation) return <FullPageSpinner />;

  return (
    <Grid className={classes.rowroot} spacing={2}>
      <Paper className={classes.paper} elevation={12}>
        <Box display="flex" alignItems="center" className={classes.title}>
          <Typography variant="h6"> Coordinación de Horarios de Equipo</Typography>
        </Box>

        <Divider />

        {procureSchedule.map((schedule) => (
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              className={classes.details}
            >
              <IdentifierOrganGroup surgery={surgery} schedule={schedule} />
              <Typography variant="subtitle2">
                Estado: {schedule.scheduledAt ? 'Agendada' : 'Pendiente'}
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              className={classes.details}
            >
              {schedule.myProcure ? (
                schedule.scheduledAt ? (
                  <FixedCoordinationSlider surgery={surgery} schedule={schedule} />
                ) : (
                  <CoordinationSlider surgery={surgery} schedule={schedule} />
                )
              ) : (
                <FixedCoordinationSlider surgery={surgery} schedule={schedule} />
              )}
              <Can
                role={user.role}
                perform={rules.surgery.schedule}
                yes={() =>
                  schedule.scheduledAt ? (
                    <CentralCoordinationInput
                      text="Horario confirmado de salida"
                      hour={dateFormat(schedule.scheduledAt, 'h:MM TT')}
                    />
                  ) : (
                    <ConfirmTeamSchedule surgery={surgery} schedule={schedule} />
                  )
                }
                no={() =>
                  schedule.scheduledAt ? (
                    <CentralCoordinationInput
                      text="Horario confirmado de salida"
                      hour={dateFormat(schedule.scheduledAt, 'h:MM TT')}
                    />
                  ) : (
                    <CentralCoordinationInput
                      text="Horario confirmado de salida"
                      hour="No Asignado"
                    />
                  )
                }
              />
            </Box>

            <Divider />
          </>
        ))}
        <Divider />
        <Divider />
        <Divider />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          className={classes.details}
        >
          <Box>
            <Can
              role={user.role}
              perform={rules.surgery.schedule}
              yes={() =>
                surgery.scheduledAt || showSchedule ? (
                  <CentralCoordinationInput
                    text="Hora de cirugía confirmada"
                    hour={dateFormat(
                      surgery.scheduledAt ? surgery.scheduledAt : showSchedule,
                      'h:MM TT'
                    )}
                  />
                ) : (
                  <ConfirmSurgerySchedule
                    surgery={surgery}
                    setShowSchedule={setShowSchedule}
                    text="Horario confirmado de salida"
                    hour="No Asignado"
                  />
                )
              }
              no={() =>
                surgery.scheduledAt ? (
                  <CentralCoordinationInput
                    text="Hora de cirugía confirmada"
                    hour={dateFormat(surgery.scheduledAt, 'h:MM TT')}
                  />
                ) : (
                  <CentralCoordinationInput text="Hora de Cirugía" hour="No Asignada" />
                )
              }
            />
          </Box>
        </Box>
      </Paper>
      <Box className={classes.chatBox}>
        <SurgeryChat chatId={chatId} />
      </Box>
    </Grid>
  );
};
export default SurgeryCoordinationTab;

// <Box flexGrow={1}><Button startIcon={<CheckCircleIcon />} variant="contained" color="primary">
//            Actualizar mi Horario
//          </Button></Box>
