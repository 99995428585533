import { useEffect, useState } from 'react';
import { useCurrentUser } from '../features/authentication/use-current-user';
import { rulesUserRoles } from './rbac-rules';

const check = (rules, role, action) => {
  const permissions = rules[role];
  if (!permissions) {
    return false;
  }

  return permissions.includes(action);
};

const Can = ({ role, perform, yes, no }) =>
  check(rulesUserRoles, role, perform) ? yes() : no();

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export function useCan({ perform }) {
  const { user } = useCurrentUser();
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    setIsAllowed(check(rulesUserRoles, user.role, perform));
  }, [perform, user.role]);

  return isAllowed;
}

export default Can;
