import React from 'react';
import { gql } from 'apollo-boost';
import {
  Typography,
  Grid,
  makeStyles,
  Box,
} from '@material-ui/core';
import DatetimeInputDialog from './datetime-input-dialog';

export const START_SURGERY_MUTATION = gql`
  mutation startSurgery($surgeryId: ID!, $time: Date!) {
    startSurgery(surgeryId: $surgeryId, time: $time) {
      id
      status
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  width: {
    width: '100%',
    maxWidth: '600px',
    marginLeft: '30px',
  },
}));

const SurgeryStartStep = ({ surgery, organsList, handleStep, completed, steps, activeStep }) => {
  const classes = useStyles();
  const next_status = [ "enCurso", "enIsquemia", "realizada"];
  const variables = {id: surgery.id};
  const messages = {
    success: 'Cirugía iniciada exitosamente',
    tooltip: 'Se ingresará la hora actual como hora de inicio de la cirugía',
    time_description: 'inicio de cirugía',
  };

  return (
    <Grid >
      <Grid>
        <Grid>
          <Typography variant="h6">Paso 2: Iniciar cirugía</Typography>
          <Typography >Subir hora de inicio</Typography>
        </Grid>
        <Box className={classes.width}>
          <DatetimeInputDialog
            surgery={surgery}
            next_status={next_status}
            SURGERY_TIME_MUTATION={START_SURGERY_MUTATION}
            variables={variables}
            messages={messages}
            step_status="aprobada"
            display_datetime={surgery.startedAt}
            handleStep={handleStep}
            completed={completed}
            activeStep={activeStep}
          />
        </Box>
      </Grid>
    </Grid>
    
  );
};

export default SurgeryStartStep;
