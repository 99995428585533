import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import FullPageSpinner from '../components/ui/full-page-spinner';
import { useCurrentUser } from '../features/authentication/use-current-user';
import BackgroundDetails from '../features/background/background-details';
import BrainDeathDetails from '../features/brain-death/brain-death-details';
import FamilyActivationDetails from '../features/family-activation/family-activation-details';
import InclusionCriteriaDetails from '../features/inclusion-criteria/inclusion-criteria-details';
import MedicalRecordDetails from '../features/medical-record/medical-record-details';
import ConfirmSuspicionButton from '../features/suspicions/confirm-suspicion-button';
import ProfileUpComponent from '../features/suspicions/profile-up';
import SuspicionHistory from '../features/suspicions/suspicion-history';
import { useSuspicion } from '../features/suspicions/use-suspicion';
import Can from '../utils/can';
import { rules } from '../utils/rbac-rules';

const useStyles = makeStyles((theme) => ({
  main: {
    // top and bottom 24px padding + toolbar height
    height: `calc(100vh - ${theme.spacing(3 * 2 + 8)}px)`,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: 'min-content min-content 1fr min-content',
    gridGap: theme.spacing(3),
    gridTemplateAreas: `
      "title title title"
      "suspicion suspicion suspicion"
      "history panels panels"
      "history confirm confirm"
    `,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'min-content min-content min-content 500px min-content',
      gridTemplateAreas: `
        "title"
        "suspicion"
        "panels"
        "history"
        "confirm"
      `,
    },
  },
  suspicion: { gridArea: 'suspicion' },
  panels: { gridArea: 'panels' },
  history: {
    gridArea: 'history',
    '& > *': { height: '100%', padding: theme.spacing(1), overflow: 'auto' },
  },
  title: {
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  confirm: { gridArea: 'confirm', justifySelf: 'right' },
}));

const SuspicionDetailsPage = () => {
  const { id: suspicionId } = useParams();
  const classes = useStyles();
  const { suspicion, loading } = useSuspicion(suspicionId);
  const { user } = useCurrentUser();
  const [expanded, setExpanded] = useState(null);

  if (loading) {
    return <FullPageSpinner />;
  }

  return (
    <Can
      role={user.role}
      perform={rules.suspicions.get}
      no={() => <Redirect to="/suspicions/" />}
      yes={() => (
        <Box className={classes.main}>
          <Box>
            <Typography className={classes.title}>SOSPECHA</Typography>
          </Box>
          <Box className={classes.suspicion}>
            <ProfileUpComponent suspicion={suspicion} />
          </Box>
          <Box className={classes.panels}>
            <BackgroundDetails
              suspicion={suspicion}
              isExpanded={expanded === 'background'}
              onClick={() => setExpanded(expanded === 'background' ? null : 'background')}
            />
            <InclusionCriteriaDetails
              suspicion={suspicion}
              isExpanded={expanded === 'inclusionCriteria'}
              onClick={() =>
                setExpanded(expanded === 'inclusionCriteria' ? null : 'inclusionCriteria')
              }
            />
            <MedicalRecordDetails
              suspicion={suspicion}
              isExpanded={expanded === 'medicalRecords'}
              onClick={() =>
                setExpanded(expanded === 'medicalRecords' ? null : 'medicalRecords')
              }
            />
            <BrainDeathDetails
              suspicion={suspicion}
              isExpanded={expanded === 'brainDeath'}
              onClick={() => setExpanded(expanded === 'brainDeath' ? null : 'brainDeath')}
            />
            <FamilyActivationDetails
              suspicion={suspicion}
              isExpanded={expanded === 'familyActivation'}
              onClick={() =>
                setExpanded(expanded === 'familyActivation' ? null : 'familyActivation')
              }
            />
          </Box>
          <Box className={classes.history}>
            <SuspicionHistory suspicion={suspicion} />
          </Box>
          <Box className={classes.confirm}>
            {suspicion.status !== 'Confirmado' && (
              <Can
                role={user.role}
                perform={rules.suspicions.confirm}
                yes={() =>
                  suspicion.enableToConfirm.authorization && (
                    <ConfirmSuspicionButton suspicion={suspicion}>
                      Confirmar Sospecha
                    </ConfirmSuspicionButton>
                  )
                }
              />
            )}
          </Box>
        </Box>
      )}
    />
  );
};

export default SuspicionDetailsPage;
