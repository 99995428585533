import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import HomePage from './pages/home-page';
import UsersListPage from './pages/users-list-page';
import SuspicionsPage from './pages/suspicions-page';
import SuspicionDetailsPage from './pages/suspicion-details-page';
import OrgansPage from './pages/organs-page';
import SurgeriesPage from './pages/surgeries-page';
import SurgeryDetailsPage from './pages/procure-page';
import OfferingsPage from './pages/offerings-page';
import MedicalCentersPage from './pages/medical-centers-page';
import SettingsPage from './pages/settings-page';
import TeamsPage from './pages/teams-page';
import TeamDetails from './pages/team-details';
import Dashboard from './pages/dashboard';

export const routes = {
  HOME: '/',
  USERS: '/users-list',
  PATIENTS: '/patients',
  ORGANS: '/organs',
  HOSPITALS: '/hospitals',
  SUSPICIONS: '/suspicions',
  SUSPICIONS_DETAILS: '/suspicions/:id',
  SURGERIES_DETAILS: '/surgeries/:id',
  OFFERS: '/offers',
  RECEPTORS: '/receptors',
  SURGERIES: '/surgeries',
  SETTINGS: '/settings',
  TEAMS: '/teams',
  TEAMS_DETAILS: '/teams/:id',
  DASHBOARD: '/dashboard'
};

const AppRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route exact path={routes.USERS} component={UsersListPage} />
      <Route exact path={routes.SUSPICIONS} component={SuspicionsPage} />
      <Route exact path={routes.SUSPICIONS_DETAILS} component={SuspicionDetailsPage} />
      <Route exact path={routes.SURGERIES_DETAILS} component={SurgeryDetailsPage} />
      <Route exact path={routes.ORGANS} component={OrgansPage} />
      <Route exact path={routes.SURGERIES} component={SurgeriesPage} />
      <Route exact path={routes.OFFERS} component={OfferingsPage} />
      <Route exact path={routes.HOSPITALS} component={MedicalCentersPage} />
      <Route exact path={routes.SETTINGS} component={SettingsPage} />
      <Route exact path={routes.TEAMS} component={TeamsPage} />
      <Route exact path={routes.TEAMS_DETAILS} component={TeamDetails} />
      <Route exact path={routes.DASHBOARD} component={Dashboard} />
      <Redirect to="/" />
    </Switch>
  );
};

export default AppRoutes;
