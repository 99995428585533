import { fireSentryEvent, setContextTag } from '../../services/sentry';
import React from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import { Formik, Form } from 'formik';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {
  Tooltip,
  MenuItem,
  Button,
  DialogContent,
  DialogTitle,
  DialogActions,
  Container,
  makeStyles,
  Grid,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import Yup from '../../utils/validations';
import ResponsiveDialog from '../../components/ui/responsive-dialog';
import useModal from '../../hooks/useModal';
import FormTextField from '../../components/forms/form-text-field';
import FormSelectField from '../../components/forms/form-select-field';
import userRoles from '../../utils/user-roles';
import { USERS_LIST_QUERY } from './use-all-users';
import { useAllMedicalCenters } from '../medical-centers/use-all-medical-centers';
import FormSingleAutocomplete from '../../components/forms/form-single-autocomplete';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  formControl: {
    margin: theme.spacing(2, 0),
    minWidth: 275,
  },
}));

export const CREATE_USER_MUTATION = gql`
  mutation CreateUserMutation($userInput: UserInput!, $medicalCenterId: ID) {
    createUser(userInput: $userInput, medicalCenterId: $medicalCenterId) {
      id
      firstName
      lastName
      email
      role
    }
  }
`;

const createUserSchema = Yup.object({
  userInput: Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().email().required(),
    password: Yup.string().matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!.%*#?&_-])[A-Za-z\d.@$!%*#?&_-]{8,}$/,
      'La contraseña debe incluir una minúscula, una mayúscula, un número y un caracter especial'
    ),
    role: Yup.string().required(),
  }),
});

const CreateUserButton = () => {
  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  const [createUser, { loading }] = useMutation(CREATE_USER_MUTATION, {
    update: (cache, { data: { createUser: createdUser } }) => {
      const { getUsers: existingUsers } = cache.readQuery({
        query: USERS_LIST_QUERY,
      });
      cache.writeQuery({
        query: USERS_LIST_QUERY,
        data: {
          getUsers: [...existingUsers, createdUser],
        },
      });
    },
  });
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { allMedicalCenters } = useAllMedicalCenters();

  const handleSubmit = async (values, { resetForm }) => {
    try {
      await createUser({
        variables: values,
      });
      enqueueSnackbar('Usuario creado exitosamente', { variant: 'success' });
      resetForm({});
      closeModal();
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Ha ocurrido un error, usuario no fue creado exitosamente', {
        variant: 'error',
      });
      setContextTag('error al crear usuario', error.message);
      fireSentryEvent(error);
    }
  };

  return (
    <>
      <Tooltip title="Crear usuario">
        <Button
          aria-label="Crear usuario"
          color="primary"
          onClick={openModal}
          id="create-user-btn"
        >
          <AddCircleIcon />
        </Button>
      </Tooltip>
      <Formik
        initialValues={{
          userInput: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            role: userRoles[0].value,
          },
          medicalCenterId: undefined,
        }}
        validationSchema={createUserSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, errors, touched }) => (
          <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
              <Form className={classes.form}>
                <ResponsiveDialog
                  open={isModalOpen}
                  onClose={closeModal}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    Crear nuevo usuario
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FormTextField
                          label="Nombre"
                          name="userInput.firstName"
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormTextField
                          label="Apellido"
                          name="userInput.lastName"
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormTextField
                          label="Correo"
                          name="userInput.email"
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormTextField
                          label="Contraseña"
                          name="userInput.password"
                          type="password"
                          required
                          fullWidth
                        />
                        {errors.password && touched.password ? (
                          <div>{errors.password}</div>
                        ) : null}
                      </Grid>
                      <Grid item xs={6}>
                        <FormSelectField
                          className={classes.formControl}
                          name="userInput.role"
                          label="Rol"
                          required
                        >
                          {userRoles.map((role) => (
                            <MenuItem key={role.value} value={role.value}>
                              {role.label}
                            </MenuItem>
                          ))}
                        </FormSelectField>
                      </Grid>

                      <Grid item xs={6}>
                        <FormSingleAutocomplete
                          name="medicalCenterId"
                          label="Elige un centro médico"
                          options={allMedicalCenters || []}
                          getOptionLabel={(option) => option.name}
                          renderOption={(option) => option.name}
                          getValueFromOption={(option) => option.id}
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={closeModal} color="secondary">
                      Cancelar
                    </Button>
                    <Button
                      onClick={submitForm}
                      disabled={loading}
                      color="primary"
                      type="submit"
                    >
                      Registrar
                    </Button>
                  </DialogActions>
                </ResponsiveDialog>
              </Form>
            </div>
          </Container>
        )}
      </Formik>
    </>
  );
};

export default CreateUserButton;
