import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import useModal from '../../hooks/useModal';
import ScheduleCrossClampDialog from './schedule-cross-clamp-dialog';
import { useCan } from '../../utils/can';
import { rules } from '../../utils/rbac-rules';

const useStyles = makeStyles((theme) => ({
  scheduleCrossClampButton: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}));

const ScheduleCrossClampButton = ({ surgery }) => {
  const canCrossClamp = useCan({ perform: rules.surgery.crossClamp });
  const classes = useStyles();
  const { isModalOpen, open: openModal, close: closeModal } = useModal();

  if (!canCrossClamp) return null;
  return (
    <>
      <Button
        className={classes.scheduleCrossClampButton}
        onClick={(event) => {
          event.stopPropagation();
          openModal();
        }}
        variant="contained"
        color="primary"
        size="small"
        data-testid="schedule-cross-clamp-button"
      >
        Informar cross-clamp
      </Button>
      <ScheduleCrossClampDialog
        surgery={surgery}
        open={isModalOpen}
        onClose={closeModal}
      />
    </>
  );
};

export default ScheduleCrossClampButton;
