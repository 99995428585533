import React from 'react';
import { Fab, makeStyles, Typography, Grid, Box } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/ErrorOutlineRounded';
import useModal from '../../hooks/useModal';
import CreateSuspicionDialog from './create-suspicion-dialog';

const useStyles = makeStyles((theme) => ({
  icon: {
    height: 200,
    width: 200,
    fill: theme.palette.primary.main,
  },
  text: {
    marginTop: theme.spacing(2),
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.h6.fontWeight,
  },
}));

const CreateSuspicionButton = () => {
  const styles = useStyles();
  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  return (
    <div>
      <Box display="flex" justifyContent="center">
        <Fab
          aria-label="sospecha"
          color="secondary"
          onClick={openModal}
          className={styles.icon}
        >
          <ErrorIcon className={styles.icon} />
        </Fab>
      </Box>
      <Grid container justify="center">
        <Typography
          variant="overline"
          color="primary"
          onClick={openModal}
          className={styles.text}
        >
          Levantar sospecha
        </Typography>
      </Grid>
      <CreateSuspicionDialog open={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default CreateSuspicionButton;
