import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

export const ALL_MEDICAL_CENTERS_QUERY = gql`
  query AllMedicalCenters {
    allMedicalCenters: getMedicalCenters {
      id
      name
    }
  }
`;

export function useAllMedicalCenters() {
  const { data, loading, error } = useQuery(ALL_MEDICAL_CENTERS_QUERY);

  return { allMedicalCenters: data?.allMedicalCenters, loading, error };
}
