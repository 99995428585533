import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

export const OFFERINGS_QUERY = gql`
  query OfferingsQuery($expired: Boolean) {
    getOfferings(expired: $expired) {
      id
      priority
      status
      accepted
      rejected
      expired
      createdAt
      recieverDocumentId
      # TODO: Aqui necesitamos el medicalCenter name, actualmente esta el medicalCenterId dentro de organGroup
      medicalCenter {
        name
      }
      organ {
        id
        # Con el medical center arriba debería ser suficiente.
        # para hacer el filtro de ofertas necesitamos el organGroup nomas
        # asi que este destination medicalCenter se puede borrar nomas
        # destinationMedicalCenter {
        #  id
        # }
        suspicion {
          id
          rut
          sex
          medicalCenter {
            id
            name
          }
          background {
            id
            createdAt
            age
            glasgow
            diagnostic
            comment
          }
        }
        organType {
          id
          name
        }
      }
    }
  }
`;

export const useAllOfferings = ({ expired = false } = {}) => {
  const { data, loading, error } = useQuery(OFFERINGS_QUERY, {
    variables: expired ? { expired } : undefined,
  });

  return { offerings: data?.getOfferings, loading, error };
};
