import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { useField } from 'formik';
import PropTypes from 'prop-types';

const FormCheckboxField = ({ label, name, ...props }) => {
  const [fieldProps] = useField(name);
  return (
    <FormControlLabel
      control={<Checkbox id={label} {...fieldProps} {...props} />}
      label={label}
    />
  );
};

FormCheckboxField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default FormCheckboxField;
