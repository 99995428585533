import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useSelector } from 'react-redux';
import { useCurrentUser } from './features/authentication/use-current-user';
import AuthenticatedApp from './authenticated-app';
import UnauthenticatedApp from './unauthenticated-app';
import FullPageSpinner from './components/ui/full-page-spinner';
import { selectIsAuthenticated } from './features/authentication/auth-slice';
import { initSentry } from './services/sentry';

initSentry();

function App() {
  const { loading } = useCurrentUser();
  const isAuthenticated = useSelector(selectIsAuthenticated);



  if (loading) {
    return <FullPageSpinner />;
  }

  return (
    <>
      <CssBaseline />
      {isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </>
  );
}

export default App;
