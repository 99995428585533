import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

export function initSentry() {
  Sentry.init({
    dsn: 'https://a65d44a28f1443c598cf71d07aad2213@o723912.ingest.sentry.io/5782606',
    integrations: [new Integrations.BrowserTracing()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    debug: process.env.REACT_APP_ENVIRONMENT !== 'production',
    environment: process.env.REACT_APP_ENVIRONMENT,
  });
}

// Gets an array of tags and sets them to sentry
// arr: [{key: string, value: string}]
export function setSentryTags(arr) {
  arr.forEach((obj) => {
    Sentry.setTag(obj.key, obj.value);
  });
}

// Gets an error and raises it to Sentry
export function fireSentryEvent(exception) {
  Sentry.captureException(exception);
}

// Sets context tags for better understanding of errors
export function setContextTag(route, message) {
  Sentry.setTag('route', route);
  Sentry.setTag('message', message);
}

export default initSentry;
